import React from "react";
import { CircularProgress, styled } from "@mui/material";

const ModalLoading = () => (
    <Absolute>
        <CircularProgress />
    </Absolute>
);

export default ModalLoading;

const Absolute = styled("div")`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: 999;
  `;