import React, { FC, Fragment, useState } from "react";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";

import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  FeedbackQuestions,
  useAddBadgeFeedbackQuestionAdminMutation,
} from "generated/graphql";
import { useParams } from "react-router-dom";

const AddBadgeFeedback = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  return (
    <Container>
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        Add Feedback Questions
      </Button>
      <CustomDialog onClose={handleCloseModal} open={open}>
        <AddBadgeFeedbackForm handleCloseModal={handleCloseModal} />
      </CustomDialog>
    </Container>
  );
};

export default AddBadgeFeedback;

interface AddBadgeFeedbackForm {
  handleCloseModal: () => void;
}

export interface ValuesOfAddBadgeFeedbackQuestion {
  questionText: string;
  badgeId: string;
}

const AddBadgeFeedbackForm: FC<AddBadgeFeedbackForm> = ({
  handleCloseModal,
}) => {
  const [addFeedbackQuestion] = useAddBadgeFeedbackQuestionAdminMutation();
  const { badgeId } = useParams();

  const initialValues: ValuesOfAddBadgeFeedbackQuestion = {
    questionText: "",
    badgeId: badgeId as string,
  };

  const onSubmit = async (
    values: ValuesOfAddBadgeFeedbackQuestion,
    formikHelpers: FormikHelpers<ValuesOfAddBadgeFeedbackQuestion>
  ) => {
    if (values.questionText) {
      const { ...rest } = values;

      const variable: any = { ...rest };
      try {
        await addFeedbackQuestion({
          variables: { ...variable },

          update: (cache, { data }) => {
            cache.modify({
              fields: {
                getBadgeFeedbackQuestionAdmin: (previous) => {
                  return {
                    ...previous,
                    FeedbackQuestions: [
                      data?.addBadgeFeedbackQuestionAdmin,
                      ...(previous as FeedbackQuestions[]),
                    ],
                    total: (previous.total as number) + 1,
                  };
                },
              },
            });
            formikHelpers.setSubmitting(false);
            toast.success("Successfully Added!");
            handleCloseModal();
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    }
  };

  const AddValidationSchema = Yup.object().shape({
    questionText: Yup.string().required("Question is required"),
  });

  return (
    <Fragment>
      <DialogTitle className="modalTitle">
        <h3>Add Feedback Question</h3>
        <IconButton onClick={handleCloseModal}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={AddValidationSchema}
      >
        {({
          values,
          handleBlur,
          handleChange,
          touched,
          isSubmitting,
          errors,
          submitForm,
        }) => (
          <>
            <DialogContent>
              <Fragment>
                {isSubmitting && <Loading />}
                <TextField
                  label="Question Text"
                  fullWidth
                  variant="outlined"
                  value={values.questionText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="questionText"
                  aria-readonlydertfh
                  error={Boolean(touched?.questionText && errors?.questionText)}
                  helperText={
                    touched?.questionText ? errors?.questionText : null
                  }
                />
              </Fragment>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={submitForm}>
                Submit
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Fragment>
  );
};

const Loading = () => (
  <Absolute>
    <CircularProgress />
  </Absolute>
);
const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  .MuiButton-root {
    text-transform: capitalize;
  }
`;

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 480px;
  }
  .MuiTextField-root {
    margin-top: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 12px 8px;
    .MuiButton-root {
      max-width: 200px;
      width: 100%;
    }
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
`;
