import { Button } from "@mui/material";
import React, { useState } from "react";
import AddInterstitialModal from "./AddInterstitialModal";

const AddInterstitial = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpenAddInterstialsModal = () => setOpen(true);
  const handleCloseAddInterstialsModal = () => setOpen(false);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleOpenAddInterstialsModal}
      >
        Add Interstitial
      </Button>
      <AddInterstitialModal
        open={open}
        onClose={handleCloseAddInterstialsModal}
        handleClose={handleCloseAddInterstialsModal}
      />
    </>
  );
};

export default AddInterstitial;
