import React, { FC, Fragment } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Table } from "../../components/table";
import { useGetReportedByListQuery } from "generated/graphql";
import { Box, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { meaningColors } from "../../constants";
import { withAdminRoleRequired } from "config/auth";
import imgRobot from "../../assets/images/robot.png";
import { keyRoleMap } from "config/side-menu-items";

const ReportHistory: FC = () => {
  const { reportId: siteReportId } = useParams<{ reportId: string }>();
  const { data, loading } = useGetReportedByListQuery({
    variables: { siteReportId },
  });

  const returnReports = data?.getReportedByList?.map((report, key) => (
    <TableTR key={key}>
      <Table.TD>
        <DateTime datetime={report?.reportTime} />
      </Table.TD>
      <Table.TD style={{ color: meaningColors(report?.reportType || "") }}>
        {report?.reportType}
      </Table.TD>
      <Table.TD style={{ textAlign: "left" }}>
        {!report?.reportedByBot && <UpdatedBy user={report?.reporter} />}
      </Table.TD>
      <Table.TD style={{ textAlign: "left" }}>{report?.remark}</Table.TD>
      <Table.TD>{report?.reportedByBot && <img src={imgRobot} />}</Table.TD>
    </TableTR>
  ));

  const isEmpty = !data?.getReportedByList?.length;
  return (
    <Fragment>
      <div
      // style = {{overflowY:"scroll"}}
      >
        <Table>
          <TableHead>
            <Table.TR>
              <Table.TH>Date Time</Table.TH>
              <Table.TH>Type</Table.TH>
              <Table.TH style={{ textAlign: "left" }}>Reported By</Table.TH>
              <Table.TH style={{ textAlign: "left" }}>Remark</Table.TH>
              <Table.TH>Bot</Table.TH>
            </Table.TR>
          </TableHead>
          <Table.Body>{returnReports}</Table.Body>
        </Table>
      </div>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <CircularProgress />
        </Box>
      ) : (
        isEmpty && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={2}
          >
            Report history not found
          </Box>
        )
      )}
    </Fragment>
  );
};

export default withAdminRoleRequired(ReportHistory, {
  roles: keyRoleMap["Reports"],
});

const DateTime: FC<{ datetime: string }> = ({ datetime }) => {
  const date = dayjs(datetime).format("DD-MMM-YYYY");
  const time = dayjs(datetime).format("HH:mm:ss");
  return (
    <DateTimeContainer>
      <time>{date}</time>
      <time>{time}</time>
    </DateTimeContainer>
  );
};

const UpdatedBy: FC<{
  user: any;
}> = ({ user }) => {
  return <span>{`${user?.firstName} ${user?.lastName}`}</span>;
};

const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: column;

  time:nth-child(2) {
    font-size: 12px;
  }
`;

const TableTR = styled.tr`
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #323c47;

  td {
    padding: 8px 0;
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #334d6e;
    mix-blend-mode: normal;
  }

  &:last-child {
    td {
      border-color: transparent;
    }
  }
`;

const TableHead = styled(Table.Head)`
  th {
    border-bottom: 1px solid #c7c7c7;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #334d6e;
    mix-blend-mode: normal;
  }
`;
