import { Paper, styled } from "@mui/material";
import dayjs from "dayjs";
import { DateRange, useGetNpsInIntervalQuery } from "generated/graphql";
import React, { FC, useMemo } from "react";
import ReactApexChart from "react-apexcharts";

const dateFormat = (date: Date | string | undefined | null) =>
  date ? dayjs(date).format("YYYY-MM-DD") : "";

const sevenDayPrevious = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
const toDay = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);

const NPSScoreChart: FC = () => {
  const { data } = useGetNpsInIntervalQuery({
    variables: {
      startDate: dateFormat(sevenDayPrevious),
      endDate: dateFormat(toDay),
      filter: DateRange.Daily,
    },
  });

  const [series, options] = useMemo(() => {
    console.log("Daily", data?.getNpsInInterval?.daily);
    const series: ApexAxisChartSeries = [
      {
        name: "Nps Score",
        data: data?.getNpsInInterval?.daily?.map((d) => Number(d?.total)) || [],
        // data: [20, 40, -50, 20, 50, 10, -30],
      },
    ];
    const options: ApexCharts.ApexOptions = {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#77B6EA", "#545454"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "NPS Score (last 7 days)",
        align: "left",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: data?.getNpsInInterval?.daily?.map((d) =>
          dayjs(d?.start_date).format("dddd")
        ),
        // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        title: {
          text: `${dateFormat(sevenDayPrevious)} - ${dateFormat(toDay)}`,
        },
      },
      yaxis: {
        title: {
          text: "NPS Score",
        },
        min: -100,
        max: 100,
        decimalsInFloat: 0,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: "red",
          },
        ],
      },
      //   fill: {
      //     type: "gradient",
      //     gradient: {
      //       shade: "dark",
      //       gradientToColors: ["#FDD835"],
      //       shadeIntensity: 1,
      //       type: "horizontal",
      //       opacityFrom: 1,
      //       opacityTo: 1,
      //       stops: [0, 100, 100, 100],
      //     },
      //   },
    };
    return [series, options];
  }, [data?.getNpsInInterval?.daily]);

  return (
    <Container>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </Container>
  );
};

export default NPSScoreChart;

const Container = styled(Paper)({
  "@media (max-width:500px)": {
    maxWidth: 289,
    width: "100%",
  },
  padding: "20px 10px",
  flex: 1,
});
