import {
  TableContainer,
  Table,
  TableBody,
  Stack,
  CircularProgress,
  TableCell,
  TableHead,
  TableRow,
  Pagination
} from "@mui/material";
import NoDataFound from "components/NoDataFound";
import {
  FeatureClubCategory,
  useGetClubCategoryListQuery,
} from "generated/graphql";
import { isEmpty } from "lodash-es";
import React, { Fragment, useState } from "react";
import ClubCategoryListRow from "./component/ClubCategoryListRow";
import styled from "@emotion/styled";
import { Maybe } from "yup";
import UpdateClubCategories from "./UpdateClubCategories";
import { useSearchParams } from "react-router-dom";

const LIMIT = 10;
const ClubCategoryList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, loading } = useGetClubCategoryListQuery();
  const [updateClubCategory, setUpdateClubCategory] = useState<
    Maybe<FeatureClubCategory> | undefined
  >();
  const handleCloseModal = () => setUpdateClubCategory(undefined);
  const handleUpdateClubCategory =
    (category: Maybe<FeatureClubCategory> | undefined) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setUpdateClubCategory(category);
    };

  const page = (Number(searchParams.get("page")) || 1) as number;
  const onPageChange = (_: any, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };
  const pages = Math.ceil(Number(data?.getClubCategoryList?.length) / LIMIT);
  return (
    <>
      <TableContainer sx={{ padding: 2 }}>
        <CustomTable>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2} sx={{ minWidth: "200px" }}>
                Category Name
              </TableCell>
              <TableCell rowSpan={2} sx={{ minWidth: "200px" }} align="center">
                Cover
              </TableCell>
              <TableCell rowSpan={2} sx={{ minWidth: "200px" }} align="center">
                Icon
              </TableCell>
              <TableCell rowSpan={2} sx={{ minWidth: "200px" }}>
                Headline
              </TableCell>
              <TableCell rowSpan={2} sx={{ minWidth: "200px" }}>
                Description
              </TableCell>
              <TableCell rowSpan={2} sx={{ minWidth: "200px" }} align="right">
                Priority
              </TableCell>
              {/* <TableCell rowSpan={2} sx={{ minWidth: "200px" }}>
                Username
              </TableCell> */}
              <TableCell rowSpan={2} sx={{ minWidth: "200px" }} align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.getClubCategoryList?.map((category) => (
              <ClubCategoryListRow
                category={category}
                key={category?.clubCategoryId}
                handleUpdateClubCategory={handleUpdateClubCategory}
                // handleRemovePopup={handleRemovePopup}
              />
            ))}
          </TableBody>
          <UpdateClubCategories
            open={!!updateClubCategory}
            category={updateClubCategory}
            handleCloseModal={handleCloseModal}
          />
        </CustomTable>
      </TableContainer>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            {isEmpty(data?.getClubCategoryList) && <NoDataFound />}
            {pages > 1 && (
              <Pagination
                count={pages}
                disabled={loading}
                page={page}
                onChange={onPageChange}
              />
            )}
          </Stack>
        </Fragment>
      )}
    </>
  );
};

export default ClubCategoryList;
export const CustomTable = styled(Table)`
  .MuiTableCell-root {
    padding: 4px 8px;
  }
  th.MuiTableCell-root {
    padding: 4px 8px;
    white-space: nowrap;
    font-weight: bold;
  }
  .inactive,
  .active {
    font-size: 12px;
    background: red;
    color: #fff;
    padding: 2px 6px;
  }
  .active {
    background-color: green;
  }
`;
