import {
  DialogContent,
  Stack,
  Button,
  TextField,
  DialogActions,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import React, { FC } from "react";
import {
  FeatureClubCategory,
  FeatureClubCategoryInputArgs,
  InputMaybe,
  useAddClubCategoryV2Mutation,
} from "generated/graphql";
import toast from "react-hot-toast";
import UploadCreative from "components/UploadCreative";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { uploadFiles } from "api/rest";
export interface ValuesOfAddCategories {
  clubCategoryName: string;
  cover: File | undefined;
  icon: File | undefined;
  headline: string;
  description: string;
  priority: number;
}

const AddClubCategoryModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const [addClubCategoryV2] = useAddClubCategoryV2Mutation();

  const InitialValues: ValuesOfAddCategories = {
    clubCategoryName: "",
    cover: undefined,
    icon: undefined,
    headline: "",
    description: "",
    priority: 0,
  };

  const CreateValidationSchema = Yup.object().shape({
    clubCategoryName: Yup.string().required("Name required").max(50, 'Maximum 50 characters allowed'),

    headline: Yup.string().required("Headline required").max(100, 'Maximum 100 characters allowed'),
    description: Yup.string().required("Description required").max(200, 'Maximum 200 characters allowed'),
    priority: Yup.number().required("Priority required"),
  });

  const onSubmit = async (
    values: ValuesOfAddCategories,
    formikHelpers: FormikHelpers<ValuesOfAddCategories>
  ) => {
    if (values?.clubCategoryName) {
      const { icon, cover, ...rest } = values;
      const input: InputMaybe<FeatureClubCategoryInputArgs> | undefined = {
        clubCategoryName: rest.clubCategoryName,
        description: rest.description,
        headline: rest.headline,
        priority: rest.priority,
        cover: "",
        icon: "",
      };
      try {
        if (icon) {
          const { data } = await uploadFiles([icon]);
          input.icon = data?.[0]?.HighRes;
        }
        if (cover) {
          const { data } = await uploadFiles([cover]);
          input.cover = data?.[0]?.HighRes;
        }
        await addClubCategoryV2({
          variables: { data: input },
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                getClubCategoryList: (previous) => {
                  return {
                    ...previous,
                    FeatureClubCategory: [
                      data?.addClubCategoryV2,
                      ...(previous as FeatureClubCategory[]),
                    ],
                    total: (previous.total as number) + 1,
                  };
                },
              },
            });
            formikHelpers.setSubmitting(false);
            toast.success("Successfully Added!");
            onClose();
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <DialogContent>
      <Formik
        initialValues={InitialValues}
        onSubmit={onSubmit}
        validationSchema={CreateValidationSchema}
        enableReinitialize
      >
        {({
          values,
          submitForm,
          handleChange,
          handleBlur,
          touched,
          setFieldValue,
          errors,
          isSubmitting,
        }) => (
          <Stack paddingY={1}>
            {isSubmitting && <Loading />}
            <TextField
              fullWidth
              label="Category Name"
              name="clubCategoryName"
              value={values.clubCategoryName}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              error={Boolean(
                touched?.clubCategoryName && errors?.clubCategoryName
              )}
              helperText={
                touched.clubCategoryName ? errors?.clubCategoryName : null
              }
            />
            <FormControl fullWidth>
              <UploadCreative
                error={Boolean(errors?.cover)}
                title="Upload cover"
                image={values?.cover}
                setImage={(image) => setFieldValue("cover", image)}
              />
              <FormHelperText error={Boolean(errors?.cover)}>
                {errors?.cover}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
              <UploadCreative
                error={Boolean(errors?.icon)}
                title="Upload icon"
                image={values?.icon}
                setImage={(image) => setFieldValue("icon", image)}
              />
              <FormHelperText error={Boolean(errors?.icon)}>
                {errors?.icon}
              </FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              label="Headline"
              name="headline"
              value={values?.headline}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              error={Boolean(touched?.headline && errors?.headline)}
              helperText={touched.headline ? errors?.headline : null}
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={values?.description}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              error={Boolean(touched?.description && errors?.description)}
              helperText={touched.description ? errors?.description : null}
            />
            <TextField
              fullWidth
              label="Priority"
              type="number"
              name="priority"
              value={values?.priority}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              error={Boolean(touched?.priority && errors?.priority)}
              helperText={touched.priority ? errors?.priority : null}
            />
            <DialogActions>
              <Button variant="contained" color="primary" onClick={submitForm}>
                Submit
              </Button>
            </DialogActions>
          </Stack>
        )}
      </Formik>
    </DialogContent>
  );
};

export default AddClubCategoryModal;
const Loading = () => (
  <Absolute>
    <CircularProgress />
  </Absolute>
);

const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
`;
