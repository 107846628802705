import { TableRow, TableCell } from "@mui/material";
import { DeleteIconButton, EditIconButton } from "components/buttons";
import dayjs from "dayjs";
import { FeedbackQuestions, Maybe } from "generated/graphql";
import React, { FC } from "react";
interface BadgeFeedbackRowProps {
  question: Maybe<FeedbackQuestions> | undefined;

  handleDeleteFeedbackQuestion: (
    badgeId: string
  ) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleUpdateFeedbackQuestion: (
    feature: Maybe<FeedbackQuestions> | undefined
  ) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const dateTimeFormat = (
  date?: string | number | Date | dayjs.Dayjs | null | undefined
) => {
  if (!date) return null;
  return dayjs(date).format("DD-MMM-YYYY\nhh:mm:ss a");
};
const BadgeFeedbackRow: FC<BadgeFeedbackRowProps> = ({
  question,
  handleDeleteFeedbackQuestion,
  handleUpdateFeedbackQuestion,
}) => {
  return (
    <>
      {" "}
      <TableRow key={question?.badgeId}>
        <TableCell align="left">
          <strong>{question?.questionText}</strong>
        </TableCell>
        <TableCell align="left">{question?.Badge?.badgeName}</TableCell>
        <TableCell align="left">
          {dateTimeFormat(question?.createdAt)}
        </TableCell>

        <TableCell align="center" sx={{ width: 30 }}>
          <DeleteIconButton
            onClick={handleDeleteFeedbackQuestion(question as any)}
          />
        </TableCell>
        <TableCell align="center" sx={{ width: 30 }}>
          <EditIconButton onClick={handleUpdateFeedbackQuestion(question)} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default BadgeFeedbackRow;
