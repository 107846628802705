import React from "react";
import NpsSummary from "./NpsSummary";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";

const NPS = () => {
  return <NpsSummary />;
};

export default withAdminRoleRequired(NPS, {
  roles: keyRoleMap["NPS"],
});
