import React, { FC } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { Club, FeatureWithPermissionDataOutput, Maybe } from "generated/graphql";

interface ClubFeatureProps {
    club: Maybe<Club> | undefined;
    feature: Maybe<FeatureWithPermissionDataOutput> | undefined;
}

const ClubFeature: FC<ClubFeatureProps> = ({ club }) => {
    return (
        <Grid item xs={4}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" padding={[0.5, 1]} sx={{ background: "#fafafa", borderRadius: 0.75 }}>
                <Typography>{club?.clubName}</Typography>
            </Stack>
        </Grid>
    )
}

export default ClubFeature;


