import React, { FC, useState } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { SortType } from "generated/graphql";

export let sortorder: any;

interface colNames {
  col: string;
  handle: () => void;
}

const Sort: FC<colNames> = () => {
  const [sortIcon, setSortIcon] = useState(true);
  function handleSort() {
    if (sortIcon) {
      setSortIcon(false);
    } else {
      setSortIcon(true);
    }
  }

  function sortManager() {
    handleSort();
  }

  const [searchParams] = useSearchParams();
  const sortType = searchParams.get("sortType");

  return (
    <Tooltip title="Sort" onClick={sortManager}>
      <IconButton>
        {sortType === SortType.Asc ? <ArrowDropUp /> : <ArrowDropDown />}
      </IconButton>
    </Tooltip>
  );
};

export default Sort;
