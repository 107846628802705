import React from "react";
import ClubCategoryList from "./ClubCategoryList";
import AddClubCategory from "./AddClubCategory";
import { Stack } from "@mui/material";
import { withAdminRoleRequired } from "config/auth";

import { keyRoleMap } from "config/side-menu-items";

const ClubCategories = () => {
  return (
    <>
      <Stack>
        <AddClubCategory />
      </Stack>
      <ClubCategoryList />
    </>
  );
};

export default withAdminRoleRequired(ClubCategories, {
  roles: keyRoleMap["Clubs"],
});
