import React from "react";
import AllInterstitials from "./AllInterstitials";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";

const Interstitials = () => {
  return (
    <>
      <AllInterstitials />
    </>
  );
};

export default withAdminRoleRequired(Interstitials, {
  roles: keyRoleMap["Smart Feed"],
});
