import React, { Suspense, lazy } from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import MainLayout from "layouts/main";
import Jobs from "pages/jobs";
import AuthCallback from "pages/AuthCallback";

const Activities = lazy(() => import("pages/activities"));
const UserEngagementSummary = lazy(() => import("pages/mis/user-engagement"));
const PostSummary = lazy(() => import("pages/mis/PostSummary"));
const CommentSummary = lazy(() => import("pages/mis/CommentSummary"));
const ReplySummary = lazy(() => import("pages/mis/ReplySummary"));
const UserSessionSummary = lazy(() => import("pages/mis/UserSessionSummary"));
const PollSummary = lazy(() => import("pages/mis/poll"));
const PowerUserNoOfPowerUserBadgesReceivedByUser = lazy(
  () => import("pages/mis/power-user/NoOfPowerUserBadgesReceivedByUser")
);
const PowerUsersFeedbacks = lazy(
  () => import("pages/mis/power-user/PowerUsersFeedbacks")
);
const PowerUserMISStreakMissedUsers = lazy(
  () => import("pages/mis/power-user/StreakMissedUsers")
);
const PowerUserWeeklyChart = lazy(
  () => import("pages/mis/power-user/WeeklyChart")
);
const SearchUsers = lazy(() => import("pages/users"));
const BlueTickUsers = lazy(() => import("pages/users/BlueTickUsers"));
const GreenTickUsers = lazy(() => import("pages/users/GreenTickUsers"));

const Reports = lazy(() => import("pages/reports"));
const ReportDetails = lazy(() => import("pages/reports/ReportDetails"));
const AllReports = lazy(() => import("pages/reports/AllReports"));
const ReportActionHistory = lazy(
  () => import("pages/reports/ReportActionHistory")
);
const Promotion = lazy(() => import("pages/promotion"));
const AllClubs = lazy(() => import("pages/clubs/AllClubs"));
const ExternalResource = lazy(() => import("pages/externalResource"));
const JobList = lazy(() => import("pages/jobs/ScheduleJobs"));
const ProductTour = lazy(() => import("pages/productTour"));
const BadgeSystem = lazy(() => import("pages/badgeSystem"));
const ABTesting = lazy(() => import("pages/abtesting"));
const BadgePopups = lazy(() => import("pages/badgePopups"));
const PendingScheduledJobs = lazy(
  () => import("pages/jobs/PendingScheduledJobs")
);
const JobHistory = lazy(() => import("pages/jobs/JobHistory"));
const PriorityProfiles = lazy(() => import("pages/PriorityProfiles"));
const NoPageFound = lazy(() => import("pages/NoPageFound"));

import ViewReport from "pages/reports/ViewReport";
import ActionHistory from "pages/reports/ActionHistory";
import ReportHistory from "pages/reports/ReportHistory";
import DailySignupSummary from "pages/mis/DailySignupSummary";
import CreatorsSummary from "pages/mis/CreatorsSummary";
import PushNotifications from "pages/pushNotifications";
import Home from "pages/home";
import NPS from "pages/nps";
import PlatformFeatures from "pages/feature/platform";
import ClubFeatures from "pages/feature/club";
import Events from "pages/events";
import OnboardingHashtagCategorization from "pages/onboarding/hashtag-categorization";
import OnboardingTickUsersCategorization from "pages/onboarding/tick-users-categorization";
import Interstitials from "pages/interstitials";
import ClubCategories from "pages/clubs/ClubCategories";
import WhatsNew from "pages/whats-new";
import BadgeFeedback from "pages/badgeSystem/BadgeFeedback";
import { useGetRoles } from "config/auth";
import LogoutOIDC from "pages/LogoutOIDC";
import WeeklyProgress from "pages/mis/WeeklyProgress";

const Routes = () => {
  const roles = useGetRoles();

  console.log("Roles", roles);
  return (
    <ReactRoutes>
      <Route element={<MainLayout />}>
        {
          <Route
            path="/"
            element={
              <Suspense fallback={<></>}>
                <Home />
              </Suspense>
            }
          />
        }
        <Route
          path="activities"
          element={
            <Suspense fallback={<></>}>
              <Activities />
            </Suspense>
          }
        />

        <Route path="mis">
          <Route
            path="session-summary"
            element={
              <Suspense fallback={<></>}>
                <UserSessionSummary />
              </Suspense>
            }
          />
          <Route
            path="user-engagement-summary"
            element={
              <Suspense fallback={<></>}>
                <UserEngagementSummary />
              </Suspense>
            }
          />
          <Route
            path="daily-signup-summary"
            element={
              <Suspense fallback={<></>}>
                <DailySignupSummary />
              </Suspense>
            }
          />
          <Route
            path="post-summary"
            element={
              <Suspense fallback={<></>}>
                <PostSummary />
              </Suspense>
            }
          />
          <Route
            path="comment-summary"
            element={
              <Suspense fallback={<></>}>
                <CommentSummary clubs={[]} />
              </Suspense>
            }
          />
          <Route
            path="reply-summary"
            element={
              <Suspense fallback={<></>}>
                <ReplySummary />
              </Suspense>
            }
          />

          <Route
            path="poll-summary"
            element={
              <Suspense fallback={<></>}>
                <PollSummary />
              </Suspense>
            }
          />
          <Route
            path="creators-summary"
            element={
              <Suspense fallback={<></>}>
                <CreatorsSummary />
              </Suspense>
            }
          />
          <Route path="power-user/*">
            <Route
              path="badges"
              element={
                <Suspense fallback={<></>}>
                  <PowerUserNoOfPowerUserBadgesReceivedByUser />
                </Suspense>
              }
            />
            <Route
              path="feedbacks"
              element={
                <Suspense fallback={<></>}>
                  <PowerUsersFeedbacks />
                </Suspense>
              }
            />
            <Route
              path="weekly-chart"
              element={
                <Suspense fallback={<></>}>
                  <PowerUserWeeklyChart />
                </Suspense>
              }
            />
            <Route
              path="weekly-progress"
              element={
                <Suspense fallback={<></>}>
                  <WeeklyProgress />
                </Suspense>
              }
            />
            <Route
              path="streak-missed-users"
              element={
                <Suspense fallback={<></>}>
                  <PowerUserMISStreakMissedUsers />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route path="users">
          <Route
            path="search-users"
            element={
              <Suspense fallback={<></>}>
                <SearchUsers />
              </Suspense>
            }
          />
          <Route
            path="blue-tick-users"
            element={
              <Suspense fallback={<></>}>
                <BlueTickUsers />
              </Suspense>
            }
          />
          <Route
            path="green-tick-users"
            element={
              <Suspense fallback={<></>}>
                <GreenTickUsers />
              </Suspense>
            }
          />
        </Route>
        <Route path="reports">
          <Route
            path="action-history"
            element={
              <Suspense fallback={<></>}>
                <ReportActionHistory />
              </Suspense>
            }
          />
          <Route
            path="all"
            element={
              <Suspense fallback={<></>}>
                <AllReports />
              </Suspense>
            }
          />
          <Route
            path="summaries"
            element={
              <Suspense fallback={<></>}>
                <Reports />
              </Suspense>
            }
          />
          <Route
            path=":reportId"
            element={
              <Suspense fallback={<></>}>
                <ReportDetails />
              </Suspense>
            }
          >
            <Route index element={<ViewReport />} />
            <Route path="report-history" element={<ReportHistory />} />
            <Route path="action-history" element={<ActionHistory />} />
          </Route>
        </Route>

        <Route path="clubs">
          <Route
            index
            element={
              <Suspense fallback={<></>}>
                <AllClubs />
              </Suspense>
            }
          />
          <Route
            path="categories"
            element={
              <Suspense fallback={<></>}>
                <ClubCategories />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="promotion"
          element={
            <Suspense fallback={<></>}>
              <Promotion />
            </Suspense>
          }
        />

        <Route
          path="external-resource"
          element={
            <Suspense fallback={<></>}>
              <ExternalResource />
            </Suspense>
          }
        />

        <Route path="jobs" element={<Jobs />}>
          <Route
            path="schedule-jobs"
            element={
              <Suspense fallback={<></>}>
                <JobList />
              </Suspense>
            }
          />
          <Route
            path="pending-schedule-jobs"
            element={
              <Suspense fallback={<></>}>
                <PendingScheduledJobs />
              </Suspense>
            }
          />
          <Route
            path="job-history"
            element={
              <Suspense fallback={<></>}>
                <JobHistory />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="push-notifications"
          element={
            <Suspense fallback={<></>}>
              <PushNotifications />
            </Suspense>
          }
        />
        <Route
          path="nps"
          element={
            <Suspense fallback={<></>}>
              <NPS />
            </Suspense>
          }
        />
        <Route path="features">
          <Route
            path="platform"
            element={
              <Suspense fallback={<></>}>
                <PlatformFeatures />
              </Suspense>
            }
          />
          <Route
            path="club"
            element={
              <Suspense fallback={<></>}>
                <ClubFeatures />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="events"
          element={
            <Suspense fallback={<></>}>
              <Events />
            </Suspense>
          }
        />
        <Route path="onboarding">
          <Route
            path="hashtag-categorization"
            element={
              <Suspense fallback={<></>}>
                <OnboardingHashtagCategorization />
              </Suspense>
            }
          />
          <Route
            path="tick-users-categorization"
            element={
              <Suspense fallback={<></>}>
                <OnboardingTickUsersCategorization />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="interstitials"
          element={
            <Suspense fallback={<></>}>
              <Interstitials />
            </Suspense>
          }
        />
        <Route
          path="product-tour"
          element={
            <Suspense fallback={<></>}>
              <ProductTour />
            </Suspense>
          }
        />
        <Route path="badge-system">
          <Route
            index
            element={
              <Suspense fallback={<></>}>
                <BadgeSystem />
              </Suspense>
            }
          />
          <Route
            path={":badgeId/badge-feedback-questions"}
            element={
              <Suspense fallback={<></>}>
                <BadgeFeedback />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="ab-testing"
          element={
            <Suspense fallback={<></>}>
              <ABTesting />
            </Suspense>
          }
        />
        <Route
          path="badge-popups"
          element={
            <Suspense fallback={<></>}>
              <BadgePopups />
            </Suspense>
          }
        />
        <Route
          path="priority-profiles"
          element={
            <Suspense fallback={<></>}>
              <PriorityProfiles />
            </Suspense>
          }
        />
        <Route
          path="whats-new"
          element={
            <Suspense fallback={<></>}>
              <WhatsNew />
            </Suspense>
          }
        />
      </Route>
      <Route path="locallogout" element={<LogoutOIDC />} />
      <Route path="auth">
        <Route path="callback" element={<AuthCallback />} />
      </Route>
      <Route
        path="*"
        element={
          <Suspense>
            <NoPageFound />
          </Suspense>
        }
      />
    </ReactRoutes>
  );
};

export default Routes;
