import React, { FC } from "react";
import { Formik, FormikHelpers, Form } from "formik";
import { Box, Button } from "@mui/material";
import styled from "@emotion/styled";
import CustomDatePicker from "components/CustomDatePicker";
import dayjs, { Dayjs } from "dayjs";

export interface Values {
  startDate: Dayjs;
  endDate: Dayjs;
}

export type OnSubmit = (
  values: Values,
  formikHelpers: FormikHelpers<Values>
) => void | Promise<any>;

interface DateFilterProps {
  initialValues: Values;
  onSubmit: OnSubmit;
}

const WeeklyProgressFilter: FC<DateFilterProps> = ({
  initialValues,
  onSubmit,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <ResponsiveForm>
          <Box
            sx={{
              display: "flex",
              width: "600px",
              "@media (max-width:425px)": {
                flexDirection: "column",
                width: "auto",
              },
            }}
          >
            <CustomDatePicker
              label="Start Date"
              value={values.startDate}
              onChange={(date) => setFieldValue("startDate", date)}
              maxDate={values.endDate || dayjs()}
            />
            <CustomDatePicker
              label="End Date"
              value={values.endDate}
              onChange={(date) => setFieldValue("endDate", date)}
              maxDate={dayjs()}
              minDate={values.startDate}
            />
            <Box>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  ml: "5px",
                  "@media (max-width:425px)": { width: "100%", ml: "0px" },
                }}
              >
                Search
              </Button>
            </Box>
          </Box>
        </ResponsiveForm>
      )}
    </Formik>
  );
};

export default WeeklyProgressFilter;

const ResponsiveForm = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  .MuiFormControl-root {
    margin-bottom: 8px;
  }
  legend {
    font-size: 0.75rem;
  }
  @media (min-width: 425px) {
    flex-direction: row;
    padding: 16px;
    .MuiFormControl-root {
      margin-bottom: 0;
      width: 100%;
      max-width: 172px;
      margin-right: 8px;
    }
    .MuiButtonBase-root {
      max-width: 150px;
      width: 100%;
    }
  }
`;
