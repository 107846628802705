import React from "react";
import WeeklyProgressFilter, {
  OnSubmit,
} from "./components/WeeklyProgressFilter";
import dayjs, { Dayjs } from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useGetPowerUserDataWeeklyQuery } from "generated/graphql";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const dateFormat = (date: Dayjs | string) => dayjs(date).format("YYYY-MM-DD");

const toDay = dayjs();
const sevenDayPrevious = dayjs(toDay).subtract(7, "days");

export const WeeklyProgress = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    startDate: dateFormat(sevenDayPrevious),
    endDate: dateFormat(toDay),
  });

  const startDate = searchParams.get("startDate")
    ? dayjs(searchParams.get("startDate"))
    : sevenDayPrevious;
  const endDate = searchParams.get("endDate")
    ? dayjs(searchParams.get("endDate"))
    : sevenDayPrevious;

  const { data } = useGetPowerUserDataWeeklyQuery({
    variables: {
      startDate: dateFormat(startDate),
      endDate: dateFormat(endDate),
    },
  });

  const onSubmit: OnSubmit = (values) => {
    if (values.startDate) {
      searchParams.set("startDate", dateFormat(values.startDate));
    }
    if (values.endDate) {
      searchParams.set("endDate", dateFormat(values.endDate));
    }
    setSearchParams(searchParams);
  };
  return (
    <div>
      <WeeklyProgressFilter
        initialValues={{
          startDate,
          endDate,
        }}
        onSubmit={onSubmit}
      />
      <TableContainer sx={{ marginX: 1.5, width: `calc(100% - 24px)` }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={10}
                sx={{
                  backgroundColor: "#28a745",
                  color: "#fff",
                  whiteSpace: "nowrap",
                }}
              >
                Week Completed
              </TableCell>
              <TableCell sx={{ border: "none" }}></TableCell>
              <TableCell
                align="center"
                colSpan={10}
                sx={{
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  whiteSpace: "nowrap",
                }}
              >
                Week Missed
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#28a745",
                  color: "#fff",
                  whiteSpace: "nowrap",
                }}
              >
                Week Name
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Total</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 1</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 2</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 3</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 4</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 5</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 6</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 7</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 8</TableCell>
              <TableCell sx={{ border: "none" }}></TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  whiteSpace: "nowrap",
                }}
              >
                Week Name
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Total</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 1</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 2</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 3</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 4</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 5</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 6</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 7</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Week 8</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.getPowerUserDataWeekly?.map((item) => (
              <TableRow key={item?.weekName}>
                <TableCell
                  sx={{
                    backgroundColor: "#28a745",
                    color: "#fff",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item?.weekName}
                </TableCell>
                <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                  {item?.weekCompleted?.total}
                </TableCell>
                {item?.weekCompleted?.weeklyCount?.map((count, key) => (
                  <TableCell
                    key={key}
                    align="right"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {count}
                  </TableCell>
                ))}
                <TableCell sx={{ border: "none" }}></TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#dc3545",
                    color: "#fff",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item?.weekName}
                </TableCell>
                <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                  {item?.weekMissed?.total}
                </TableCell>
                {item?.weekMissed?.weeklyCount?.map((count, key) => (
                  <TableCell
                    key={key}
                    align="right"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {count}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
