import React, { Fragment, FC, ReactNode } from "react";
import reactStringReplace from "./reactStringReplace";

const BreakLine: FC<{ match: string }> = ({ match }) => (
  <Fragment>
    <br />
    {match}
  </Fragment>
);

const Mention: FC<{ children: ReactNode }> = ({ children }) => (
  <a style={{ color: "#1da1f3" }}>{children}</a>
);

export const textPost = (text: string): Array<ReactNode> => {
  let replacedText;
  // Match URLs
  replacedText = reactStringReplace(
    [text],
    /↵/gi,
    (match: string) => {
      return <BreakLine match={match} />;
    }
  );

  replacedText = reactStringReplace(
    replacedText,
    /(https?:\/\/\S+)/gi,
    (match: string) => <Mention>{match}</Mention>
  );

  // Match users
  replacedText = reactStringReplace(
    replacedText,
    /\[(@[^:]+[^\]]+)\]/gi,
    (match: string) => {
      const [display] = match.split(":");
      return <Mention>{display}</Mention>;
    }
  );

  // Match stocktag
  replacedText = reactStringReplace(
    replacedText,
    /\[(\$[^:]+[^\]]+)\]/gi,
    (match: string) => {
      const [display] = match.split(":");
      return <Mention>{display}</Mention>;
    }
  );

  // Match hashtags
  replacedText = reactStringReplace(
    replacedText,
    /\[(#[^:]+[^\]]+)\]/gi,
    (match: string) => {
      const [display] = match.split(":");
      return <Mention>{display}</Mention>;
    }
  );

  return replacedText as Array<ReactNode>;
};
