import {
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { FC, Fragment } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { useGetReportActionHistoryQuery } from "generated/graphql";
import { meanings, meaningColors } from "../../constants";
import { isEmpty } from "lodash-es";
import NoDataFound from "components/NoDataFound";
import { withAdminRoleRequired } from "config/auth";
import imgRobot from "../../assets/images/robot.png";
import { keyRoleMap } from "config/side-menu-items";

const LIMIT = 10;

const ActionHistory: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = (Number(searchParams.get("page")) || 1) as number;
  const { reportId: siteReportId } = useParams<{ reportId: string }>();
  const { data, loading } = useGetReportActionHistoryQuery({
    variables: { siteReportId: `${siteReportId}`, limit: LIMIT, page: page },
  });

  const onPageChange = (_: any, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const returnRenders = data?.getReportActionHistory?.actions?.map((report) => (
    <TableRow key={report?.ReportId}>
      <TableCell>
        <DateTime datetime={report?.createdAt} />
      </TableCell>
      <TableCell>
        {" "}
        {report?.actionByBot ? (
          <img src={imgRobot} />
        ) : (
          `${report?.Admin?.firstName} ${report?.Admin?.lastName}`
        )}
      </TableCell>
      <TableCell style={{ textAlign: "left" }}>{report?.remark}</TableCell>
      <TableCell
        align="center"
        style={{ color: meaningColors(report?.HiddenStatus) }}
      >
        {meanings(report?.HiddenStatus)}
      </TableCell>
      <TableCell
        align="center"
        style={{ color: meaningColors(report?.SuspectedStatus) }}
      >
        {meanings(report?.SuspectedStatus)}
      </TableCell>
      <TableCell
        align="center"
        style={{ color: meaningColors(report?.SuspendedStatus) }}
      >
        {meanings(report?.SuspendedStatus)}
      </TableCell>
      <TableCell align="center">{report?.ReportStatus}</TableCell>
    </TableRow>
  ));

  const pages = Math.ceil(Number(data?.getReportActionHistory?.total) / LIMIT);
  return (
    <Container>
      <Typography variant="caption" sx={{ marginBottom: 2 }}>
        Total results: {data?.getReportActionHistory?.total || 0}
      </Typography>
      <TableContainer>
        <Table sx={{ minWidth: 768 }}>
          <TableHead>
            <TableRow>
              <TableCell>Action taken at</TableCell>
              <TableCell style={{ textAlign: "left" }}>
                Action taken by
              </TableCell>
              <TableCell style={{ textAlign: "left" }}>Remark</TableCell>
              <TableCell align="center">Visible Status</TableCell>
              <TableCell align="center">Suspected Status</TableCell>
              <TableCell align="center">User Status</TableCell>
              <TableCell align="center">Report Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{returnRenders}</TableBody>
        </Table>
      </TableContainer>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          {isEmpty(data?.getReportActionHistory?.actions) && <NoDataFound />}
          {pages > 1 && (
            <Pagination
              count={pages}
              disabled={loading}
              page={page}
              onChange={onPageChange}
            />
          )}
        </Fragment>
      )}
    </Container>
  );
};
export default withAdminRoleRequired(ActionHistory, {
  roles: keyRoleMap["Reports"],
})

const DateTime: FC<{ datetime: string }> = ({ datetime }) => {
  const date = dayjs(datetime).format("DD-MMM-YYYY");
  const time = dayjs(datetime).format("HH:mm:ss");
  return (
    <DateTimeContainer>
      <time>{date}</time>
      <time>{time}</time>
    </DateTimeContainer>
  );
};

const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: column;

  time:nth-child(2) {
    font-size: 12px;
  }
`;

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;
