import {
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { Maybe, PushNotificationFilter, PushNotificationLog, useGetPushNotificationLogAdminQuery } from "generated/graphql";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash-es";
import NoDataFound from "components/NoDataFound";
import dayjs from "dayjs";

const LIMIT = 10;
const PushedNotifications = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = (Number(searchParams.get("page")) || 1) as number;
  const { data, loading } = useGetPushNotificationLogAdminQuery({
    variables: {
      page: page,
      limit: LIMIT,
      filter: (searchParams.get('filter') || PushNotificationFilter.All) as PushNotificationFilter
    },
  });

  //   console.log(data);
  const pages = Math.ceil(
    Number(data?.getPushNotificationLogAdmin?.total) / LIMIT
  );


  const onPageChange = (_: any, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };
  return (
    <Fragment>
      <Container>
        <TableContainer>
          <Table sx={{ minWidth: 425 }}>
            <TableHead>
              <TableRow>
                <TableCell>Message</TableCell>
                <TableCell>Body</TableCell>
                <TableCell>Analytics Label</TableCell>
                <TableCell align="center">Image</TableCell>
                <TableCell>Redirect URL</TableCell>
                <TableCell>Scheduled Time</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Audience</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell align="center">Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getPushNotificationLogAdmin?.pushNotificationLog?.map(
                (log) => (
                  <TableRow key={log?.pushLogId}>
                    <TableCell>{log?.message}</TableCell>
                    <TableCell>{log?.body}</TableCell>
                    <TableCell>{log?.analyticsLabel}</TableCell>
                    <TableCell align="center">
                      {log?.imageUrl?.showUri && (
                        <ExternalLink
                          href={log?.imageUrl?.showUri}
                          target="__blank"
                        >
                          <Image src={log?.imageUrl?.showUri} alt="" />
                        </ExternalLink>
                      )}
                    </TableCell>
                    <TableCell>{log?.link}</TableCell>
                    <TableCell>
                      {log?.scheduleTime ? <Time>
                        {dayjs(log?.scheduleTime).format(
                          "DD-MMM-YYYY\nHH:mm:ss"
                        )}
                      </Time> : <p>-</p>}

                    </TableCell>
                    <TableCell>{getStatus(log)}</TableCell>
                    <TableCell>
                      <Typography component={"b"}>
                        {log?.Club?.clubId ? log?.Club?.clubName : "All"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {`${log?.createdBy?.firstName} ${log?.createdBy?.lastName}`}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(log?.createdAt).format("DD-MMM-YYYY\nHH:mm:ss")}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            {isEmpty(
              data?.getPushNotificationLogAdmin?.pushNotificationLog
            ) && <NoDataFound />}
            {pages > 1 && (
              <Pagination
                count={pages}
                disabled={loading}
                page={page}
                onChange={onPageChange}
              />
            )}
          </Stack>
        </Fragment>
      )}
    </Fragment>
  );
};
export default PushedNotifications;

const getStatus = (log: Maybe<PushNotificationLog>) => {
  if (log?.scheduleTime === null) {
    if (log?.isPushNotificationSent === null || log?.isPushNotificationSent === true) return "Sent";
    return "Pending";
  } else {
    if (log?.isPushNotificationSent) return "Sent";
    return "Pending";
  }
}

const ExternalLink = styled.a`
  text-decoration: none;
`;

const Container = styled.div`
  padding: 16px;
`;

const Image = styled.img`
  object-fit: cover;
  height: 36px;
  width: 36px;
  border-radius: 4px;
`;

const Time = styled.time`
  white-space: break-spaces;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;
