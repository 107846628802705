import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "@emotion/styled";
import {
  SuspendedStatus,
  GetReportActionStatusDocument,
  ReportStatus,
  useGetReportOnUserActionStatusQuery,
  usePerformActionOnUserReportMutation,
} from "generated/graphql";
import { isEmpty } from "lodash-es";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-hot-toast";
import { DisplayStatus } from "./DisplayStatus";
import { CircularProgress } from "@mui/material";

export const UserReportAction: FC = () => {
  const confirm = useConfirm();
  const { reportId: siteReportId } = useParams<{ reportId: string }>();
  const { data, loading } = useGetReportOnUserActionStatusQuery({
    variables: { siteReportId: `${siteReportId}` },
  });
  const [reportAction] = usePerformActionOnUserReportMutation({
    refetchQueries: [
      {
        query: GetReportActionStatusDocument,
        variables: {
          siteReportId,
        },
      },
    ],
  });
  const [remark, setRemark] = useState<string>("");
  const [reportStatus, setReportStatus] = useState(ReportStatus.Resolved);

  useEffect(() => {
    if (data?.getReportOnUserActionStatus?.ReportStatus) {
      setReportStatus(data.getReportOnUserActionStatus.ReportStatus);
    }
  }, [data]);
  const [suspendedStatus, setSuspendedStatus] = useState<
    SuspendedStatus.IsNotSuspended | SuspendedStatus.IsSuspended
  >(
    data?.getReportOnUserActionStatus?.SuspendedStatus ||
      SuspendedStatus.IsNotSuspended
  );

  console.log(
    "API VALUE",
    data?.getReportOnUserActionStatus?.ReportStatus,
    "DEFAULT VALUE",
    reportStatus
  );
  const handleReportAction = useCallback(() => {
    confirm({
      title: "Report Actions",
      confirmationText: "Submit",
      content: (
        <DisplayStatus
          remark={remark}
          reportStatus={reportStatus}
          suspendedStatus={suspendedStatus}
        />
      ),
    }).then(() => {
      reportAction({
        variables: {
          data: {
            ReportId: `${siteReportId}`,
            ReportStatus: reportStatus,
            SuspendedStatus: suspendedStatus,
            remark: remark,
          },
        },
      })
        .then(() => {
          setRemark("");
          toast.success("Report action sumitted successfully!");
        })
        .catch(() => {
          toast.error("Something went wrong!");
        });
    });
  }, [
    siteReportId,
    remark,
    reportStatus,
    suspendedStatus,
    reportAction,
    confirm,
    setRemark,
  ]);

  if (loading) return <CircularProgress />;
  return (
    <Fragment>
      <Title>Actions</Title>
      <StatusContainer>
        <Status>
          User Status:
          <select
            value={suspendedStatus}
            onChange={({ target }) => setSuspendedStatus(target.value as any)}
          >
            <option
              value={SuspendedStatus.IsSuspended}
              style={{ color: "red" }}
            >
              Suspended
            </option>
            <option
              value={SuspendedStatus.IsNotSuspended}
              style={{ color: "#000" }}
            >
              Not Suspended
            </option>
          </select>
        </Status>
      </StatusContainer>
      <Title>Your Remark</Title>
      <ContainerBorder>
        <textarea
          placeholder="Your remarks for the report goes here"
          onChange={({ target }) => setRemark(target.value)}
          value={remark}
        ></textarea>
        <ActionContainer>
          <Status>
            Report Status:
            <select
              value={reportStatus}
              onChange={({ target }) => setReportStatus(target.value as any)}
            >
              <option value={ReportStatus.Resolved}>Resolved</option>
              <option value={ReportStatus.Rejected}>Rejected</option>
              <option value={ReportStatus.Pending}>Pending</option>
            </select>
          </Status>
          <button onClick={handleReportAction} disabled={isEmpty(remark)}>
            Save
          </button>
        </ActionContainer>
      </ContainerBorder>
    </Fragment>
  );
};

const ContainerBorder = styled.div`
  background: #ffffff;
  border: 1px solid #7b7b7b;
  border-radius: 8px;
  margin: 10px 0;
  overflow: hidden;
  textarea {
    border: 0;
    flex: 1;
    outline: 0;
    width: 100%;
    padding: 10px;
    resize: none;
    height: 200px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.65);
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ebebeb;
  border-radius: 0px 0px 8px 8px;
  padding: 5px;
  button {
    background: #3751ff;
    box-shadow: 0px 2px 6px rgba(9, 142, 223, 0.3);
    border-radius: 1px;
    padding: 2px 16px;
    border: 0;
    color: #fff;
    cursor: pointer;
  }
  button[disabled],
  button:disabled {
    background-color: #c3c3c3;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const Title = styled.div<{ right?: boolean }>`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
  ${({ right }) =>
    right &&
    `
        text-align: right;
    `}
`;

const StatusContainer = styled.div`
  flex-direction: row;
  display: flex;
  padding: 10px 0;
  margin-left: 10px;
`;

const Status = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #6a707e;

  select {
    border: 0;
    margin-left: 5px;
    color: rgb(29, 161, 243);
  }
`;
