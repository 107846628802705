import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { Button, Dialog } from "@mui/material";
import { AddFeatureForm } from "./components/AddFeatureForm";

const AddFeature: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <Container>
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        Add Feature
      </Button>
      <CustomDialog open={open} onClose={handleCloseModal}>
        <AddFeatureForm handleCloseModal={handleCloseModal} />
      </CustomDialog>
    </Container>
  );
};

export default AddFeature;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  .MuiButton-root {
    text-transform: capitalize;
  }
`;

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 580px;
  }
  .MuiTextField-root {
    margin-top: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 12px 8px;
    .MuiButton-root {
      max-width: 200px;
      width: 100%;
    }
  }
  .MuiTypography-root {
      font-size: 12px;
    }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
  @media (max-width: 540px) {
    .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialogTitle-root {
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
   
  }
`;
