import { Box, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import "./phone.css";
import "./iphone.css";



interface FormProps {
  formData: any
  image: any
}

const Expanded: FC<FormProps> = ({ formData, image }) => {
  return (
    <Box>
      <Box className="smartphone">
        <Box className="content">
          <Box
            className="top"
            style={{
              background: "white",
              marginTop: "5px",
              borderRadius: "2px",
              flexDirection: "column",
            }}
          >
            <Box>
              <Stack spacing={0.01}>
                <Typography
                  variant="caption"
                  style={{ paddingLeft: "5px", fontWeight: "bold" }}
                >
                  {formData.message}
                </Typography>
                <Typography variant="caption" style={{ paddingLeft: "5px" }}>
                  {formData.body}
                </Typography>
              </Stack>
            </Box>
            <Box style={{ width: "100%" }}>
              <img
                src={image && URL.createObjectURL(image)}
                alt=""
                style={{
                  width: "20%",
                  padding: "5px",
                  display: "block",
                  margin: "auto",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <p style={{ textAlign: "center", fontSize: "0.8em" }}>Android</p>
      {/* <Box className="container">
        <Box className="wrapper">
          <Box
            style={{
              background: "white",
              marginTop: "25px",
              borderRadius: "2px",
            }}
          >
            <Box className="top" style={{ flexDirection: "column" }}>
              <Box>
                <Stack spacing={0.01}>
                  <Typography
                    variant="caption"
                    style={{ paddingLeft: "5px", fontWeight: "bold" }}
                  >
                    {formData.message}
                  </Typography>
                  <Typography variant="caption" style={{ paddingLeft: "5px" }}>
                    {formData.body}
                  </Typography>
                </Stack>
              </Box>
              <Box style={{ width: "100%" }}>
                <img
                  src={image && URL.createObjectURL(image)}
                  alt=""
                  style={{
                    width: "20%",
                    padding: "5px",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <p style={{ textAlign: "center", fontSize: "0.8em" }}>Apple</p> */}
    </Box>
  );
}

export default Expanded