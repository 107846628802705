import { Stack } from "@mui/material";
import React from "react";
import AddBadgeFeedback from "./AddBadgeFeedback";
import BadgeFeedbackList from "./BadgeFeedbackList";
import { withAdminRoleRequired } from "config/auth";

import { keyRoleMap } from "config/side-menu-items";

const BadgeFeedback = () => {
  return (
    <>
      <Stack>
        <AddBadgeFeedback />
      </Stack>
      <BadgeFeedbackList />
    </>
  );
};

export default withAdminRoleRequired(BadgeFeedback, {
  roles: keyRoleMap["Badge System"],
});
