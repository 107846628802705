import React, { FC } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";

export const ReportHeader: FC<{
    reportCount: number;
    lastReportedTime: any;
}> = ({ reportCount, lastReportedTime }) => {
    return (
        <Header>
            <div>
                <span>Report Count</span>
                <span>{reportCount}</span>
            </div>
            <div>
                <span>Last Reported</span>
                <span>{dayjs(lastReportedTime).format('HH:mm A DD MMM, YYYY')}</span>
            </div>
        </Header>
    )
}


const Header = styled.div`
    border-bottom: 1px solid #C7C7C7;
    display: flex;
    flex-direction: row;

    div {
        flex: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        span:first-child {
            font-family: "Mulish";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #9FA2B4;
        }

        span:last-child {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 1px;
            color: #252733;
        }
    }
`;