import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";

const InterstitialsTableHead = () => (
  <TableHead>
    <TableRow>
      <TableCell rowSpan={2} sx={{ width: 200 }}>
        Component Type
      </TableCell>
      <TableCell rowSpan={2} sx={{ width: 300 }}>
        Title
      </TableCell>
      <TableCell rowSpan={2} align="right" sx={{ width: 100 }}>
        Position
      </TableCell>
      <TableCell rowSpan={2} align="right" sx={{ width: 100 }}>
        Audience
      </TableCell>
      <TableCell rowSpan={2} align="right" sx={{ width: 100 }}>
        Platform
      </TableCell>
      {/* <TableCell rowSpan={2} align="center" sx={{ minWidth: 300 }}>
        Data
      </TableCell> */}
      <TableCell colSpan={3} align="center">
        Actions
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell align="center" sx={{ width: 100 }}>
        Active/Inactive
      </TableCell>
      <TableCell align="center" sx={{ width: 80 }}>
        Edit
      </TableCell>
      <TableCell align="center" sx={{ width: 80 }}>
        Delete
      </TableCell>
    </TableRow>
  </TableHead>
);

export default InterstitialsTableHead;
