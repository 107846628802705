import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  IconButton,
  Pagination,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
  Switch,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import React, { useMemo, Fragment, useState } from "react";
import { toast } from "react-hot-toast";
import ShowMoreText from "react-show-more-text";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import {
  useGetListOfUpcomingEventsQuery,
  Maybe,
  UpcomingEvents,
  useDeleteUpcomingEventMutation,
  useToggleEventActiveStatusMutation,
} from "generated/graphql";
import EditEvent from "./EditEvent";

const dateTimeFormat = (
  date?: string | number | Date | dayjs.Dayjs | null | undefined
) => {
  if (!date) return null;
  return dayjs(date).format("DD-MMM-YYYY\nhh:mm:ss a");
};

const LIMIT = 10;

const Events = () => {
  const confirm = useConfirm();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = (Number(searchParams.get("page")) || 1) as number;
  const { data, loading } = useGetListOfUpcomingEventsQuery({
    variables: { page, limit: LIMIT },
  });
  const [deleteUpcomingEvent] = useDeleteUpcomingEventMutation();
  const [toggleEventActiveStatus] = useToggleEventActiveStatusMutation();
  const [{ open, event }, setState] = useState<{
    open: boolean;
    event: Maybe<UpcomingEvents> | null | undefined;
  }>({ open: false, event: undefined });

  const pages = useMemo(
    () => Math.ceil(Number(data?.getListOfUpcomingEvents?.total) / LIMIT),
    [data?.getListOfUpcomingEvents?.total]
  );

  const onPageChange = (_: any, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleCloseModal = () => setState({ open: false, event: undefined });

  const handleDeleteEvent = (
    event: Maybe<UpcomingEvents> | null | undefined
  ) => {
    confirm({
      title: "Are you sure you want to delete this event?",
      confirmationText: "Delete",
      confirmationButtonProps: {
        color: "error",
      },
      allowClose: true,
      dialogProps: {
        maxWidth: "xs",
      },
    })
      .then(() => {
        deleteUpcomingEvent({
          variables: { eventId: event?.eventId },
          update: (cache, { data }) => {
            if (event && data?.deleteUpcomingEvent?.success) {
              cache.evict({ id: cache.identify(event) });
              toast.success(data?.deleteUpcomingEvent?.message as string);
            }
          },
        });
      })
      .catch(() => console.log("Modal Close"));
  };

  const handleToggleStatus = (
    event: Maybe<UpcomingEvents> | null | undefined
  ) => {
    confirm({
      title: `Are you sure you want to ${
        event?.isActive ? "inactive" : "active"
      } this event?`,
      confirmationText: event?.isActive ? "Inactive" : "Active",
      confirmationButtonProps: {
        color: event?.isActive ? "error" : "success",
      },
      allowClose: true,
      dialogProps: {
        maxWidth: "xs",
      },
    })
      .then(() => {
        toggleEventActiveStatus({
          variables: { eventId: event?.eventId },
        });
      })
      .catch(() => console.log("Modal Close"));
  };

  return (
    <Fragment>
      <Container>
        <TableContainer component={SimpleBar}>
          <Table size="small" sx={{ minWidth: 1800, width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell>Event Title</TableCell>
                <TableCell>Event Image</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Audience</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell align="center">CTA Button</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated By</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getListOfUpcomingEvents?.eventsList?.map((event) => (
                <TableRow key={event?.eventId}>
                  <TableCell>{event?.title}</TableCell>
                  <TableCell>
                    {Boolean(event?.imageUrl) && (
                      <Link
                        href={(event?.imageUrl as any)?.HighResUri}
                        target="_blank"
                      >
                        <Image
                          title="Click to view image"
                          src={(event?.imageUrl as any)?.showUri}
                          height={75}
                          width={75}
                          alt=""
                        />
                      </Link>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      component={ShowMoreText}
                      lines={1}
                      width={200}
                      whiteSpace={"pre-line"}
                      keepNewLines
                      more="more"
                      less="less"
                    >
                      {event?.description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {event?.audience}
                  </TableCell>
                  <TableCell>{dateTimeFormat(event?.startTime)}</TableCell>
                  <TableCell>{dateTimeFormat(event?.endTime)}</TableCell>
                  <TableCell align="center">
                    {Boolean(event?.ctaButtonText) && (
                      <Button
                        sx={{ minWidth: 120 }}
                        size="small"
                        href={event?.ctaButtonUrl as string}
                        target="_blank"
                      >
                        {event?.ctaButtonText}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {event?.createdBy?.firstName} {event?.createdBy?.lastName}
                  </TableCell>
                  <TableCell>{dateTimeFormat(event?.createdAt)}</TableCell>
                  <TableCell>
                    {event?.updatedBy?.firstName} {event?.updatedBy?.lastName}
                  </TableCell>
                  <TableCell>{dateTimeFormat(event?.updatedAt)}</TableCell>
                  <TableCell align="center">
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Switch
                        checked={event?.isActive as boolean}
                        onChange={() => handleToggleStatus(event)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => setState({ open: true, event })}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => handleDeleteEvent(event)}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {pages > 1 && (
          <Pagination
            count={pages}
            disabled={loading}
            page={page}
            onChange={onPageChange}
          />
        )}
      </Container>
      <EditEvent
        open={open}
        event={event}
        handleCloseModal={handleCloseModal}
      />
    </Fragment>
  );
};

export default Events;

const Container = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  object-fit: cover;
`;
