import React, { FC } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

interface DisabledCheckboxProps {
    label: React.ReactNode;
    name?: string | undefined;
    checked?: boolean | undefined
}

export const DisabledCheckbox: FC<DisabledCheckboxProps> = ({ label, checked, name }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    name={name}
                    onChange={undefined}
                    readOnly={true}
                    disableRipple
                    sx={{
                        cursor: "default"
                    }}
                />
            }
            label={label}
            sx={{
                cursor: "default"
            }}
        />
    )
}