import React, { FC, Fragment } from "react";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  TextField,
  CircularProgress,
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  DialogContent,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";

import {
  FeatureClubCategory,
  FeatureClubCategoryUpdateArgs,
  InputMaybe,
  useUpdateClubCategoryV2Mutation,
} from "generated/graphql";
import UploadCreative from "components/UploadCreative";
import { uploadFiles } from "api/rest";

interface UpdateClubCategoryProps extends DialogProps {
  category: FeatureClubCategory | null | undefined;
  handleCloseModal: () => void;
}

const UpdateClubCategories: FC<UpdateClubCategoryProps> = ({
  category,
  handleCloseModal,
  ...rest
}) => {
  return (
    <CustomDialog onClose={handleCloseModal} {...rest}>
      <UpdateClubCategoriesForm
        handleCloseModal={handleCloseModal}
        category={category}
      />
    </CustomDialog>
  );
};

export default UpdateClubCategories;

interface UpdateClubCategoriesFormProps {
  handleCloseModal: () => void;
  category: FeatureClubCategory | null | undefined;
}

export interface ValuesOfUpdateCategories {
  clubCategoryId: string;
  clubCategoryName: string;
  cover: File | undefined;
  icon: File | undefined;
  headline: string;
  description: string;
  priority: number;
}

const UpdateClubCategoriesForm: FC<UpdateClubCategoriesFormProps> = ({
  handleCloseModal,
  category,
}) => {
  const [updateClubCategoryV2] = useUpdateClubCategoryV2Mutation();

  const initialValues: ValuesOfUpdateCategories = {
    clubCategoryId: category?.clubCategoryId || "",
    clubCategoryName: category?.clubCategoryName || "",
    cover: undefined,
    icon: undefined,
    headline: category?.headline || "",
    description: category?.description || "",
    priority: category?.priority || 0,
  };

  const UpdateValidationSchema = Yup.object().shape({
    clubCategoryName: Yup.string()
      .required("Name required")
      .max(50, "Maximum 50 characters allowed"),

    headline: Yup.string()
      .required("Headline required")
      .max(100, "Maximum 100 characters allowed"),
    description: Yup.string()
      .required("Description required")
      .max(200, "Maximum 200 characters allowed"),
    priority: Yup.number().required("Priority required"),
  });

  const onSubmit = async (
    values: ValuesOfUpdateCategories,
    formikHelpers: FormikHelpers<ValuesOfUpdateCategories>
  ) => {
    if (values?.clubCategoryId) {
      const { icon, cover, ...rest } = values;

      const input: InputMaybe<FeatureClubCategoryUpdateArgs> | undefined = {
        clubCategoryId: rest.clubCategoryId,
        clubCategoryName: rest.clubCategoryName,
        description: rest.description,
        headline: rest.headline,
        priority: rest.priority,
        cover: "",
        icon: "",
      };

      try {
        if (icon) {
          const { data } = await uploadFiles([icon]);
          input.icon = data?.[0]?.HighRes;
        } else {
          input.icon = category?.icon as any;
        }
        if (cover) {
          const { data } = await uploadFiles([cover]);
          input.cover = data?.[0]?.HighRes;
        } else {
          input.cover = category?.cover as any;
        }

        await updateClubCategoryV2({
          variables: {
            data: input,
          },
        });

        toast.success("Successfully Updated!");
        handleCloseModal();
      } catch (e) {
        console.error(e);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    }
  };

  return (
    <Fragment>
      <DialogTitle className="modalTitle">
        <h3>Update category</h3>
        <IconButton onClick={handleCloseModal}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={UpdateValidationSchema}
        >
          {({
            values,
            submitForm,
            handleChange,
            handleBlur,
            errors,
            setFieldValue,
            isSubmitting,
            touched,
          }) => (
            <>
              {isSubmitting && <Loading />}
              <TextField
                fullWidth
                label="Category Name"
                name="clubCategoryName"
                value={values.clubCategoryName}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={Boolean(
                  touched?.clubCategoryName && errors?.clubCategoryName
                )}
                helperText={
                  touched.clubCategoryName ? errors?.clubCategoryName : null
                }
              />
              <FormControl fullWidth>
                <UploadCreative
                  // error={Boolean(errors?.icon)}
                  title="Upload cover"
                  image={values.cover || (category?.cover as any)}
                  setImage={(image) => setFieldValue("cover", image)}
                />
                <FormHelperText error={Boolean(errors?.cover)}>
                  {errors?.cover}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <UploadCreative
                  // error={Boolean(errors?.icon)}
                  title="Upload icon"
                  image={values.icon || (category?.icon as any)}
                  setImage={(image) => setFieldValue("icon", image)}
                />
                <FormHelperText error={Boolean(errors?.icon)}>
                  {errors?.icon}
                </FormHelperText>
              </FormControl>
              <TextField
                fullWidth
                label="Headline"
                name="headline"
                value={values?.headline}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={Boolean(touched?.headline && errors?.headline)}
                helperText={touched.headline ? errors?.headline : null}
              />
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={values?.description}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={Boolean(touched?.description && errors?.description)}
                helperText={touched.description ? errors?.description : null}
              />
              <TextField
                fullWidth
                label="Priority"
                type="number"
                name="priority"
                value={values?.priority}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
              />
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </DialogContent>
    </Fragment>
  );
};

const Loading = () => (
  <Absolute>
    <CircularProgress />
  </Absolute>
);

const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
`;

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 480px;
  }
  .MuiTextField-root {
    margin-top: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 12px 8px;
    .MuiButton-root {
      max-width: 200px;
      width: 100%;
    }
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
`;
