import React, { FC, ReactNode } from "react";
import { StyledTable, THead, TBody, TFoot, TH, TR, TD } from "./styles";

export const Table: FC<{ children: ReactNode }> & {
  Head: FC<any>;
  Body: FC<any>;
  Foot: FC<any>;
  TH: FC<any>;
  TR: FC<any>;
  TD: FC<any>;
} = ({ children, ...rest }) => {
  return <StyledTable {...rest}>{children}</StyledTable>;
};

Table.Head = ({ children, ...rest }) => {
  return <THead {...rest}>{children}</THead>;
};
Table.Head.displayName = "Table.Head";

Table.Body = ({ children, ...rest }) => {
  return <TBody {...rest}>{children}</TBody>;
};
Table.Body.displayName = "Table.Body";

Table.Foot = ({ children, ...rest }) => {
  return <TFoot {...rest}>{children}</TFoot>;
};
Table.Foot.displayName = "Table.Foot";

Table.TH = ({ children, ...rest }) => {
  return <TH {...rest}>{children}</TH>;
};
Table.TH.displayName = "Table.TH";

Table.TR = ({ children, innerRef, ...rest }) => {
  return (
    <TR {...rest} ref={innerRef}>
      {children}
    </TR>
  );
};
Table.TR.displayName = "Table.TR";

Table.TD = ({ children, ...rest }) => {
  return <TD {...rest}>{children}</TD>;
};

Table.TD.displayName = "Table.TD";
