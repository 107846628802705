import styled from "@emotion/styled";
import {
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { Maybe, useGetFeatureMappingListQuery } from "generated/graphql";
import React, { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EditFeature from "./EditFeature";
import { FeatureWithPermissionDataOutput } from "generated/graphql";
import NoDataFound from "components/NoDataFound";
import { isEmpty } from "lodash-es";
import { FeatureRow } from "./components/FeatureRow";
import { FeatureTableHead } from "./components/FeatureTableHead";

const LIMIT = 10;

const FeatureListTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ openModal, feature }, setState] = useState<{
    openModal: boolean;
    feature: Maybe<FeatureWithPermissionDataOutput> | null | undefined;
  }>({ openModal: false, feature: undefined });
  const page = (Number(searchParams.get("page")) || 1) as number;
  const { data, loading } = useGetFeatureMappingListQuery({
    variables: {
      page: Number(searchParams.get("page")) || 1,
      limit: LIMIT,
    },
  });


  const pages = Math.ceil(Number(data?.getFeatureMappingList?.total) / LIMIT);

  const onPageChange = (_: any, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleCloseModal = () =>
    setState({ openModal: false, feature: undefined });
  const handleOpenEditModal = (feature: any) => {
    setState({ openModal: true, feature });
  };

  return (
    <Fragment>
      <Container>
        <TableContainer>
          <Table size="small" sx={{ minWidth: 425, width: "100%" }}>
            <FeatureTableHead />
            <TableBody>
              {data?.getFeatureMappingList?.dataList?.map((feature) => (
                <FeatureRow
                  key={feature?.featureId}
                  feature={feature}
                  handleOpenEditModal={handleOpenEditModal}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            {isEmpty(data?.getFeatureMappingList?.dataList) && <NoDataFound />}
            {pages > 1 && (
              <Pagination
                count={pages}
                disabled={loading}
                page={page}
                onChange={onPageChange}
              />
            )}
          </Stack>
        </Fragment>
      )}
      <EditFeature
        open={openModal}
        feature={feature as any}
        handleCloseModal={handleCloseModal}
      />
    </Fragment>
  );
};

const Container = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

export default FeatureListTable;
