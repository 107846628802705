import React, { FC, useId } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectProps,  } from "@mui/material";
import { useGetClubsListForAdminQuery } from "generated/graphql";

const ClubDropdown: FC<SelectProps> = (props) => {
    const id = useId();
    const { data } = useGetClubsListForAdminQuery({
        variables: {
            page: 1,
            limit: 100
        }
    });
    return (
        <FormControl size="small">
            <InputLabel id={`${id}-club-label`}>{props.label || "Club"}</InputLabel>
            <Select
                labelId={`${id}-club-label`}
                id={id}
                label="Club"
                {...props}
            >
                <MenuItem value="all">All</MenuItem>
                {data?.getClubsListForAdmin?.clubData?.map((club) => (
                    <MenuItem key={club?.clubId} value={club?.clubId as string}>
                        {`${club?.clubName} (@${club?.clubHandle})`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}


export default ClubDropdown;
