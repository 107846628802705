import React, { FC, useRef } from "react";
import styled from "@emotion/styled";
import { useConfirm } from "material-ui-confirm";

interface UploadCreativeProps {
  error?: boolean;
  image: File | string | undefined;
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>;
  title?: string; // Optional title prop
}

const UploadCreative: FC<UploadCreativeProps> = ({
  error,
  image,
  setImage,
  title = "Upload Image",
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const confirm = useConfirm();
  const handleOnClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const handleImageChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.currentTarget;
    if (files?.length) {
      if (files[0].size > 10 * 1024 * 1024) {
        return confirm({
          confirmationText: "Continue",
          confirmationButtonProps: {
            variant: "contained",
            color: "primary",
            size: "small",
          },
          cancellationButtonProps: {
            style: {
              display: "none",
            },
          },
          dialogProps: {
            classes: {
              paper: "AlertPaper",
            },
          },
          title: "Upload error",
          description: "Image size exceeds 10MB",
        });
      }
      setImage(files[0]);
    }
    e.target.value = "";
  };

  return (
    <UploadCreativeContainer
      onClick={handleOnClick}
      style={error ? { border: "1px solid red" } : {}}
    >
      {image instanceof File ? (
        <img src={URL.createObjectURL(image)} alt="" height={200} width={420} />
      ) : (
        <img src={image} alt="" height={200} width={420} />
      )}
      <span>{title}</span>
      <input
        type="file"
        accept=".png, .jpg, .jpeg, .gif"
        ref={ref}
        onChange={handleImageChange}
      />
    </UploadCreativeContainer>
  );
};

export default UploadCreative;

const UploadCreativeContainer = styled.div`
  height: 200px;
  background: #f0f0f0;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 16px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
  span {
    border: 1px dashed;
    padding: 4px 8px;
    border-radius: 12px;
    background-color: #fff;
    opacity: 0.75;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
  }
  input {
    display: none;
  }
`;
