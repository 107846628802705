import React, { FC, useId } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import styled from "@emotion/styled";
import { PushNotificationFilter } from "generated/graphql";

export interface Values {
  filter: PushNotificationFilter;
}

export type OnSubmit = (
  values: Values,
  formikHelpers: FormikHelpers<Values>
) => void | Promise<any>;

interface PushedNotificationsFilterProps {
  initialValues: Values;
  onSubmit: OnSubmit;
}

const PushedNotificationsFilter: FC<PushedNotificationsFilterProps> = ({
  initialValues,
  onSubmit,
}) => {
  const id = useId();
  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur }) => (
          <ResponsiveForm>
            <FormControl size="small">
              <InputLabel id={`${id}-schedule-job-status`}>Status</InputLabel>
              <Select
                labelId={`${id}-schedule-job-status`}
                id={id}
                label="Status"
                name="filter"
                value={values.filter}
                onChange={handleChange}
                onBlur={handleBlur}
                defaultChecked
              >
                <MenuItem value={PushNotificationFilter?.All}>
                  All
                </MenuItem>
                <MenuItem value={PushNotificationFilter?.Completed}>
                  Sent
                </MenuItem>
                <MenuItem value={PushNotificationFilter?.Pending}>
                  Pending
                </MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" type="submit">
              Search
            </Button>
          </ResponsiveForm>
        )}
      </Formik>
    </Container>
  );
};

export default PushedNotificationsFilter;

const Container = styled.div`
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-end;
  min-width: 200px;
  width: 50%;
  margin: 10px;
  .MuiButton-root {
    text-transform: capitalize;
  }
`;

const ResponsiveForm = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  .MuiFormControl-root {
    margin-bottom: 8px;
  }
  legend {
    font-size: 0.75rem;
  }
  @media (min-width: 425px) {
    flex-direction: row;
    padding: 16px;
    .MuiFormControl-root {
      margin-bottom: 0;
      width: 100%;
      max-width: 172px;
      margin-right: 8px;
    }
    .MuiButtonBase-root {
      max-width: 150px;
      width: 100%;
    }
  }
`;
