import React, { FC } from 'react';
import { styled } from '@mui/material';
import { User } from 'generated/graphql';
import imgGreenTick from 'assets/images/green-check.png';
import imgBlueTick from 'assets/images/blue-check.png';
import imgYellowTick from 'assets/images/yellow-check.png';

const Tick: FC<User> = ({ isVerified, tick3, greenTick }) => {
    if (isVerified)
        return <Image src={imgBlueTick} />
    if (greenTick)
        return <Image src={imgGreenTick} />
    if (tick3)
        return <Image src={imgYellowTick} />
    return null;
}

export default Tick;

const Image = styled('img')({

})