import { Box, Grid, styled } from "@mui/material";
import React, { FC, Fragment, lazy, Suspense } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import PendingJobsCard from "./components/PendingJobsCard";
import NPSScoreChart from "./components/NPSScoreChart";
import { checkAccessibility, PermissionsFor } from "constants/permission";
import ReportsCard from "./components/ReportsCard";
import UserChartCard from "./components/UserChartCard";
import dayjs from "dayjs";
import MembershipLevelCard from "./components/MembershipLevelCard";
import NPSOverChart from "./components/NPSOverChart";
import { useGetRoles } from "config/auth";

const DailySessionChart = lazy(() => import("./components/DailySessionChart"));
const DailySignupChart = lazy(() => import("./components/DailySignupChart"));

interface TimeProps {
  timePeriod: any;
  searchParams: URLSearchParams;
}
const MainBody: FC<TimeProps> = () => {
  const roles = useGetRoles();
  // const roles = [
  //   // Roles.SocialAdmin,
  //   // Roles.SocialMIS,
  //   // Roles.SocialTechnicalOperator,
  //   // Roles.SocialSupport,
  //   // Roles.SocialModerator
  // ];

  const dateFormat = (date: Date | string | undefined | null) =>
    date ? dayjs(date).format("YYYY-MM-DD") : "";
  const sevenDayPrevious = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const toDay = new Date();

  const staticDates = {
    startDate: dateFormat(sevenDayPrevious),
    endDate: dateFormat(toDay),
  };

  return (
    <FlexWrapContainer>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            {checkAccessibility(roles, PermissionsFor.MIS) && (
              <LazyLoadComponent>
                <Suspense fallback={<Fragment />}>
                  <MembershipLevelCard />
                </Suspense>
              </LazyLoadComponent>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={8} xl={9}>
            {checkAccessibility(roles, PermissionsFor.MIS) && (
              <LazyLoadComponent>
                <Suspense fallback={<Fragment />}>
                  <DailySignupChart timePeriod={staticDates} />
                </Suspense>
              </LazyLoadComponent>
            )}
          </Grid>
          <Grid item xs={12}>
            {checkAccessibility(roles, PermissionsFor.MIS) && (
              <LazyLoadComponent>
                <Suspense fallback={<Fragment />}>
                  <DailySessionChart timePeriod={staticDates} />
                </Suspense>
              </LazyLoadComponent>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <NPSOverChart />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <NPSScoreChart />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            {checkAccessibility(roles, PermissionsFor.Users) && (
              <UserChartCard
                title="Verified Users"
                route="/users/search-users"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            {checkAccessibility(roles, PermissionsFor.Reports) && (
              <ReportsCard
                title="Reports"
                route="/reports/summaries"
                timePeriod={staticDates}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            {checkAccessibility(roles, PermissionsFor.JOBS) && (
              <PendingJobsCard
                title="Pending Jobs"
                route="/jobs/pending-schedule-jobs"
                timePeriod={staticDates}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </FlexWrapContainer>
  );
};

export default MainBody;

const FlexWrapContainer = styled("div")({
  padding: 16,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 16,
});
