import React, { ChangeEvent, FC, useMemo, useState } from "react";
import { Switch, TableCell, TableRow } from "@mui/material";
import {
  Interstitials,
  Maybe,
  useGetInterstitialsConfigForAdminQuery,
} from "generated/graphql";
import { EditIconButton, DeleteIconButton } from "components/buttons";
import EditInterstitialModal from "./EditInterstitialModal";

interface InterstitialsRowProps {
  interstitial: Maybe<Interstitials> | undefined;

  handleDeleteModal: (
    interstitial: Maybe<Interstitials> | undefined
  ) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleToggleModal: (
    interstitial: Maybe<Interstitials> | undefined
  ) => (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const InterstitialsRow: FC<InterstitialsRowProps> = ({
  interstitial,

  handleDeleteModal,
  handleToggleModal,
}) => {
  const [editInterstitial, setEditInterstitial] = useState<
    Maybe<Interstitials> | undefined
  >();
  const { data } = useGetInterstitialsConfigForAdminQuery();
  const handleCloseEditModal = () => setEditInterstitial(undefined);
  const handleEditModal =
    (interstitial: Maybe<Interstitials> | undefined) => () => {
      setEditInterstitial(interstitial);
    };

  const componentTypeMap = useMemo<Record<string, string> | undefined>(() => {
    return data?.getInterstitialsConfigForAdmin?.reduce<Record<string, string>>(
      (a, c) => {
        return {
          ...a,
          [c?.componentType as string]: c?.componentTypeName as string,
        };
      },
      {}
    );
  }, [data?.getInterstitialsConfigForAdmin]);

  // const componentTypeDropdownMap = useMemo(
  //   () =>
  //     data?.getInterstitialsConfigForAdmin?.reduce<
  //       Record<string, Record<string, string> | undefined>
  //     >(
  //       (a, c) => ({
  //         ...a,
  //         [c?.componentType as string]: c?.dropdown?.reduce<
  //           Record<string, string>
  //         >(
  //           (a, c) => ({ ...a, [c?.data as string]: c?.name as string }),
  //           {} as Record<string, string>
  //         ),
  //       }),
  //       {} as Record<string, Record<string, string>>
  //     ),
  //   [data?.getInterstitialsConfigForAdmin]
  // );

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: 200 }}>
          {componentTypeMap?.[interstitial?.componentType as string]}
        </TableCell>
        <TableCell sx={{ width: 300 }}>{interstitial?.title}</TableCell>
        <TableCell align="right" sx={{ width: 100 }}>
          {interstitial?.position}
        </TableCell>
        <TableCell align="right" sx={{ width: 100 }}>
          {interstitial?.audience}
        </TableCell>
        <TableCell align="right" sx={{ width: 100 }}>
          {interstitial?.platformType}
        </TableCell>
        {/* <TableCell align="center" sx={{ minWidth: "500px" }}>
          {interstitial?.componentType === ComponentType.PromotionsForSmartFeed
            ? interstitial?.data
            : componentTypeDropdownMap?.[
                interstitial?.componentType as string
              ]?.[interstitial?.data as string]}
        </TableCell> */}
        <TableCell align="center" sx={{ width: 100 }}>
          <Switch
            checked={interstitial?.isActive as boolean}
            onChange={handleToggleModal(interstitial)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </TableCell>
        <TableCell align="center" sx={{ width: 80 }}>
          <EditIconButton onClick={handleEditModal(interstitial)} />
        </TableCell>
        <TableCell align="center" sx={{ width: 80 }}>
          <DeleteIconButton onClick={handleDeleteModal(interstitial)} />
        </TableCell>
      </TableRow>

      <EditInterstitialModal
        open={Boolean(editInterstitial)}
        interstitial={editInterstitial as any}
        onClose={handleCloseEditModal}
        handleClose={handleCloseEditModal}
      />
    </>
  );
};

export default InterstitialsRow;
