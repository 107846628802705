import React, { FC } from "react";
import Collapse from "@mui/material/Collapse";
import { Box, Grid, Typography, Card } from "@mui/material";
import { Maybe, PlatformPermissionOutputType } from "generated/graphql";
import { DisabledCheckbox } from "components/DisabledCheckbox";
interface FeaturePermissionsProps {
  open: boolean;
  platformPermissionData?: Maybe<PlatformPermissionOutputType> | undefined;
}

export const FeaturePermissions: FC<FeaturePermissionsProps> = ({
  open,
  platformPermissionData,
}) => {
  return (
    <Collapse in={open} timeout="auto" sx={{ backgroundColor: "transparent" }}>
      <Card variant="outlined" sx={{ marginBottom: 1, paddingTop: 1 }}>
        <Typography variant="caption" sx={{ marginLeft: 2, marginTop: 2 }}>
          Permissions
        </Typography>
        <Box sx={{ margin: 1, paddingLeft: 5 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canPost)}
                name="canPost"
                label="Post"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canEngage)}
                name="canEngage"
                label="Engage"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canViewPost)}
                name="canViewPost"
                label="Post View"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canFollow)}
                name="canFollow"
                label="Follow"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canBeFollowed)}
                name="canBeFollowed"
                label="Get Followed"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={platformPermissionData?.canBlock as boolean}
                name="canBlock"
                label="Block"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canReport)}
                name="canReport"
                label="Report"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canSeeWatchList)}
                name="canSeeWatchList"
                label="See Watchlist"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canSeeOthersFeedPosts)}
                name="canSeeOthersFeedPosts"
                label="See Others Posts"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canSeeMarketingPage)}
                name="canSeeMarketingPage"
                label="See Marketing Page"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canAutoJoinBlueTick)}
                name="canAutoJoinBlueTick"
                label="Auto Join Blue Tick"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canAutoJoinGreenTick)}
                name="canAutoJoinGreenTick"
                label="Auto Join Green Tick"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canAutoJoinYellowTick)}
                name="canAutoJoinYellowTick"
                label="Auto Join Yellow Tick"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canFollowPost)}
                name="canFollowPost"
                label="Follow Post"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canShowNps)}
                name="canShowNps"
                label="Show NPS"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canSeeClubPromotion)}
                name="canSeeClubPromotion"
                label="See Club Promotion"
              />
            </Grid>
            <Grid xs={4}>
              <DisabledCheckbox
                checked={Boolean(platformPermissionData?.canSeePremiumPromotion)}
                name="canSeePremiumPromotion"
                label="See Premium Promotion"
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Collapse>
  );
};
