import React, { FC } from "react";
import styled from "@emotion/styled";
import { DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

interface CustomDialogTitleProps {
  children?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const CustomDialogTitle: FC<CustomDialogTitleProps> = ({
  children,
  onClose,
}) => {
  return (
    <DialogTitle>
      {children}
      {onClose ? (
        <AbsoluteIconButton onClick={onClose}>
          <Close />
        </AbsoluteIconButton>
      ) : null}
    </DialogTitle>
  );
};

export default CustomDialogTitle;

const AbsoluteIconButton = styled(IconButton)`
  position: absolute;
  right: 8px;
`;
