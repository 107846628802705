import styled from "@emotion/styled";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FormikHelpers, Formik, Form } from "formik";
import {
  FilterComponentType,
  FilterPlatformType,
  InputMaybe,
  useGetInterstitialsConfigForAdminQuery,
} from "generated/graphql";
import React, { FC, useId } from "react";

export interface Values {
  componentType?: InputMaybe<FilterComponentType> | undefined;
  platformType?: InputMaybe<FilterPlatformType> | undefined;
}

export type OnSubmit = (
  values: Values,
  formikHelpers: FormikHelpers<Values>
) => void | Promise<any>;

interface IntertstitialsFilterProps {
  initialValues: Values;
  onSubmit: OnSubmit;
}
const FilterInterstitials: FC<IntertstitialsFilterProps> = ({
  initialValues,
  onSubmit,
}) => {
  const id = useId();
  const { data } = useGetInterstitialsConfigForAdminQuery();
  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur }) => (
          <>
            <ResponsiveForm>
              <FormControl size="small" sx={{ minWidth: 200 }}>
                <InputLabel id={`${id}-component-type`}>
                  Component Type
                </InputLabel>
                <Select
                  labelId={`${id}-component-type`}
                  id={id}
                  label="Component Type"
                  name="componentType"
                  value={values?.componentType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultChecked
                >
                  <MenuItem
                    key={FilterComponentType.All}
                    value={FilterComponentType.All}
                  >
                    All
                  </MenuItem>
                  {data?.getInterstitialsConfigForAdmin?.map((item) => (
                    <MenuItem
                      key={item?.componentType}
                      value={item?.componentType as string}
                    >
                      {item?.componentTypeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl size="small" sx={{ minWidth: 200 }}>
                <InputLabel id={`${id}-platform-type`}>
                  Platform Type
                </InputLabel>
                <Select
                  labelId={`${id}-platform-type`}
                  id={id}
                  label="Platform"
                  name="platformType"
                  value={values?.platformType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultChecked
                >
                  <MenuItem
                    key={FilterPlatformType.All}
                    value={FilterPlatformType.All}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    key={FilterPlatformType.Mobile}
                    value={FilterPlatformType.Mobile}
                  >
                    Mobile
                  </MenuItem>
                  <MenuItem
                    key={FilterPlatformType.Web}
                    value={FilterPlatformType.Web}
                  >
                    Web
                  </MenuItem>
                </Select>{" "}
              </FormControl>

              <Button variant="contained" type="submit">
                Search
              </Button>
            </ResponsiveForm>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default FilterInterstitials;

const Container = styled.div`
  min-width: 200px;
  width: 50%;
  .MuiButton-root {
    text-transform: capitalize;
  }
`;

const ResponsiveForm = styled(Form)`
  display: flex;
  flex-direction: column;
  .MuiFormLabel-root {
    font-size: 0.875rem;
  }
  .MuiFormControl-root {
    margin-bottom: 8px;
  }
  legend {
    font-size: 0.75rem;
  }
  @media (min-width: 425px) {
    flex-direction: row;
    padding: 16px;
    .MuiFormControl-root {
      margin-bottom: 0;
      width: 100%;
      max-width: 200px;
      margin-right: 8px;
    }
    .MuiButtonBase-root {
      max-width: 150px;
      width: 100%;
    }
  }
`;
