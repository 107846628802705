import styled from "@emotion/styled";
import { Edit, Delete } from "@mui/icons-material";
import {
  TableRow,
  TableCell,
  Stack,
  Typography,
  IconButton,
  Link,
} from "@mui/material";
import {
  FeatureClubCategory,
  GetClubCategoryListDocument,
  useDeleteClubCategoryMutation
} from "generated/graphql";
import { useConfirm } from "material-ui-confirm";
import React, { FC } from "react";
import toast from "react-hot-toast";
import { Maybe } from "yup";
interface RowProps {
  category: any;
  handleUpdateClubCategory: (
    popup: Maybe<FeatureClubCategory> | undefined
  ) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
const ClubCategoryListRow: FC<RowProps> = ({
  category,
  handleUpdateClubCategory,
}) => {
  const confirm = useConfirm();

  const [deleteClubCategory] = useDeleteClubCategoryMutation({
    refetchQueries: [GetClubCategoryListDocument],
    onError(error) {
      toast.error(error.message);
    },
  });

  const handleDeleteclubCategory = (
    category: Maybe<FeatureClubCategory> | null | undefined
  ) => {
    confirm({
      title: "Are you sure you want to delete this category?",
      confirmationText: "Delete",
      confirmationButtonProps: {
        color: "error",
      },
      allowClose: true,
      dialogProps: {
        maxWidth: "xs",
      },
    })
      .then(() => {
        deleteClubCategory({
          variables: { clubCategoryId: category?.clubCategoryId as any },
          update: (cache, { data }) => {
            if (category && data?.deleteClubCategory?.success) {
              cache.evict({ id: cache.identify(category) });
              toast.success(data?.deleteClubCategory?.message as string);
            }
          },
        });
      })
      .catch(() => console.log("Modal Close"));
  };

  return (
    <>
      <TableRow key={category?.clubCategoryId}>
        <TableCell align="left">
          <Typography>{category?.clubCategoryName}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ width: 50 }}>
          {category?.cover?.length != 0 && category?.cover && (
            <Link href={category?.cover as any} target="_blank">
              <Image
                src={category?.cover as any}
                height={75}
                width={75}
                alt=""
              />
            </Link>
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: 50 }}>
          {category?.icon?.length != 0 && category?.icon && (
            <Link href={category?.icon as any} target="_blank">
              <Image
                src={category?.icon as any}
                height={75}
                width={75}
                alt=""
              />
            </Link>
          )}
        </TableCell>
        <TableCell align="left">
          <Typography>{category?.headline}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography>{category?.description}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography>{Number(category?.priority)}</Typography>
        </TableCell>
        <TableCell align="right">
          {" "}
          <Stack direction="row" justifyContent="right" spacing={1}>
            <IconButton
              size="small"
              color="primary"
              id={`${category?.clubCategoryId}`}
              onClick={handleUpdateClubCategory(category)}
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              color="error"
              onClick={() => handleDeleteclubCategory(category)}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ClubCategoryListRow;
const Image = styled.img`
  object-fit: cover;
`;
