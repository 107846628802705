import styled from "@emotion/styled";

export const StyledTable = styled.table`
  width: 100%;
`;

export const THead = styled.thead`
  // custom css goes here
`;

export const TFoot = styled.tfoot`
  // custom css goes here
`;

export const TBody = styled.tbody`
  // custom css goes here
`;

export const TR = styled.tr`
  // custom css goes here
`;

export const TH = styled.th`
  background-color: #fff;
  padding: 10px 0;
  position: sticky;
  top: 64px;
  z-index: 999;
`;

export const TD = styled.td`
  // custom css goes here
  border-bottom: 1px solid #3637400d;
  text-align: center;
  vertical-align: middle;
`;
