import React, { FC, Fragment } from "react";
import { Formik } from "formik";
import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  IconButton,
  DialogTitle
} from "@mui/material";
import { FeatureWithPermissionDataOutput, useEditFeatureDataForClubGroupMutation } from "generated/graphql";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Close } from "@mui/icons-material";
import toast from "react-hot-toast";
import { isEmpty } from "lodash-es";

interface EditFeatureProps {
  open: boolean;
  feature: FeatureWithPermissionDataOutput | null | undefined;
  handleCloseModal: () => void;
}

const EditFeature: FC<EditFeatureProps> = ({
  open,
  feature,
  handleCloseModal,
}) => {
  const fullScreen = useMediaQuery("(max-width:540px)");
  return (
    <CustomDialog
      open={open}
      onClose={handleCloseModal}
      fullScreen={fullScreen}
      disableEscapeKeyDown
    >
      <CreatePromotionForm
        feature={feature}
        handleCloseModal={handleCloseModal}
      />
    </CustomDialog>
  );
};

export default EditFeature;

const CreatePromotionForm: FC<Pick<EditFeatureProps, "feature" | "handleCloseModal">> = ({ feature, handleCloseModal }) => {
  const [updateFeatureMapping] = useEditFeatureDataForClubGroupMutation();

  return (
    <Fragment>
      <Formik
        initialValues={{
          featureData: {
            featureName: feature?.featureName,
            featureAlias: feature?.featureAlias,
          }
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log("values", values);
          updateFeatureMapping({
            variables: {
              ...values,
              featureId: feature?.featureId as string
            },
            onCompleted: (data) => {
              if (data?.editFeatureDataForClubGroup) {
                toast.success("Successfully updated!");
                handleCloseModal();
              }
            },
            onError: (error) => {
              toast.error(error.message);
            }
          }).finally(() => {
            setSubmitting(false);
          })

        }}
        enableReinitialize
        validate={(values) => {
          const featureData: Record<string, string> = {};
          if (!values.featureData.featureName) featureData.featureName = "Required";
          if (!values.featureData.featureAlias) featureData.featureAlias = "Required";
          return isEmpty(featureData) ? {} : { featureData };
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          handleBlur,
          errors,
          submitForm,
        }) => (
          <Fragment>
            <CustomDialogTitle>
              Edit Feature
              <CustomIconButton onClick={handleCloseModal}>
                <Close />
              </CustomIconButton>
            </CustomDialogTitle>
            <DialogContent>
              <TextField
                label="Feature Name"
                value={values.featureData.featureName}
                name="featureData.featureName"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors?.featureData?.featureName)}
                helperText={errors?.featureData?.featureName}
                fullWidth
              />
              <TextField
                label="Feature Alias"
                value={values.featureData.featureAlias}
                name="featureData.featureAlias"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors?.featureData?.featureAlias)}
                helperText={errors?.featureData?.featureAlias}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isSubmitting}
                onClick={submitForm}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 580px;
  }
  .MuiTextField-root {
    margin-top: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 12px 8px;
    .MuiButton-root {
      max-width: 200px;
      width: 100%;
    }
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
`;

const CustomIconButton = styled(IconButton)`
  position: absolute;
  right: 16px;
`;

const CustomDialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
`;
