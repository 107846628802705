import { Edit, Delete } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { IconButton, Stack, TableCell, TableRow } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  FeatureWithPermissionDataOutput,
  Maybe,
  useDeleteFeatureDataForClubGroupMutation,
} from "generated/graphql";
import React, { FC } from "react";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import styled from "@emotion/styled";
import { ClubLists } from "./ClubLists";

const dateFormat = (date: Date | string) => dayjs(date).format("YYYY-MM-DD");

interface FeatureRowProps {
  feature: Maybe<FeatureWithPermissionDataOutput> | undefined;
  handleOpenEditModal: (
    feature: Maybe<FeatureWithPermissionDataOutput> | undefined
  ) => void;
}

export const FeatureRow: FC<FeatureRowProps> = ({
  feature,
  handleOpenEditModal,
}) => {
  const confirm = useConfirm();
  const [open, setOpen] = React.useState(false);
  const [deleteFeatureMapping] = useDeleteFeatureDataForClubGroupMutation();
  const handleDeleteEvent = (
    feature: Maybe<FeatureWithPermissionDataOutput> | undefined
  ) => {
    confirm({
      title: "Are you sure you want to delete this feature?",
      confirmationText: "Delete",
      confirmationButtonProps: {
        color: "error",
      },
      allowClose: true,
      dialogProps: {
        maxWidth: "xs",
      },
    })
      .then(() => {
        deleteFeatureMapping({
          variables: { featureId: feature?.featureId as any },
          update: (cache, { data }) => {
            if (data?.deleteFeatureDataForClubGroup?.success) {
              if (feature) {
                cache.evict({ id: cache.identify(feature) });
              }
              toast.success(
                data?.deleteFeatureDataForClubGroup?.message as string
              );
            }
          },
        });
      })
      .catch(() => console.log("Modal Close"));
  };
  return (
    <React.Fragment>
      <BorderBottomLessTableRow>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          &nbsp;{feature?.featureName}
        </TableCell>
        <TableCell>{feature?.featureAlias}</TableCell>
        <TableCell>
          {feature?.createdBy?.firstName} {feature?.createdBy?.lastName}
        </TableCell>
        <TableCell>{dateFormat(feature?.createdAt)}</TableCell>
        <TableCell align="right">
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => handleOpenEditModal(feature)}
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              color="error"
              onClick={() => handleDeleteEvent(feature)}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Stack>
        </TableCell>
      </BorderBottomLessTableRow>
      <TableRow>
        <TableCell colSpan={5} sx={{ padding: 0 }}>
          <ClubLists open={open} feature={feature} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const BorderBottomLessTableRow = styled(TableRow)`
  .MuiTableCell-root {
    border-bottom: 0;
  }
`;
