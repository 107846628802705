import {
  Box,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useDropzone } from "react-dropzone";
import { WhatsNewFormInitialValues } from "./WhatsNewForm";
import { Delete } from "@mui/icons-material";

const WhatsNewItem: FC<{ index: number; remove: () => void }> = ({
  index,
  remove,
}) => {
  const { values, errors, handleChange, handleBlur, setFieldValue } =
    useFormikContext<WhatsNewFormInitialValues>();
  const whatsNew = values.whatsNew[index];
  const onDrop = (files: File[]) => {
    console.log({ files });
    setFieldValue(`whatsNew[${index}].image`, files[0]);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    maxFiles: 1,
    onDrop,
  });

  return (
    <Container
      draggable
      sx={{ display: "flex", flexDirection: "row" }}
      onDrag={console.log}
    >
      <Stack direction="column" flex={1} spacing={1.5}>
        <TextField
          label="Title"
          name={`whatsNew[${index}].title`}
          value={whatsNew.title}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean((errors?.whatsNew?.[index] as any)?.title)}
          helperText={(errors?.whatsNew?.[index] as any)?.title}
        />
        <TextField
          label="Description"
          name={`whatsNew[${index}].subText`}
          value={whatsNew.subText}
          onChange={handleChange}
          onBlur={handleBlur}
          multiline
        />
      </Stack>
      <DropzoneContainer
        title="Drag & Drop, or click to select image"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {whatsNew.image ? (
          <Image
            src={
              whatsNew.image instanceof File
                ? URL.createObjectURL(whatsNew.image)
                : whatsNew.image
            }
          />
        ) : (
          <Typography
            variant="caption"
            textAlign="center"
            sx={{ fontSize: 10 }}
          >
            Drag & Drop, or click to select image
          </Typography>
        )}
      </DropzoneContainer>
      <Box>
        {values.whatsNew.length > 1 ? (
          <DeleteIconButton color="error" title="Remove" onClick={remove}>
            <Delete color="error" />
          </DeleteIconButton>
        ) : null}
      </Box>
    </Container>
  );
};

export default WhatsNewItem;

const DeleteIconButton = styled(IconButton)({
  backgroundColor: "#fff",
});

const Container = styled(Paper)({
  paddingLeft: 12,
  paddingRight: 12,
  paddingTop: 16,
  paddingBottom: 12,
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: 12,
});

const DropzoneContainer = styled(Paper)({
  position: "relative",
  width: 100,
  height: 100,
  padding: 8,
  alignSelf: "stretch",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Image = styled("img")({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "contain",
  objectPosition: "center",
  zIndex: 0,
  cursor: "pointer",
});
