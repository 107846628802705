import React from "react";
import Users from "./components/users";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";

const TickUsers = () => {
  return <Users />;
};

export default withAdminRoleRequired(TickUsers, {
  roles: keyRoleMap["Onboarding"],
});
