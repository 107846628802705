import styled from "@emotion/styled";
import { CircularProgress, Stack, Table, TableBody, TableContainer } from "@mui/material";
import { Maybe, useGetFeatureMappingListForClubQuery } from "generated/graphql";
import React, { Fragment, useState } from "react";
import EditFeature from "./EditFeature";
import { FeatureWithPermissionDataOutput } from "generated/graphql";
import NoDataFound from "components/NoDataFound";
import { isEmpty } from "lodash-es";
import { FeatureRow } from "./components/FeatureRow";
import { FeatureTableHead } from "./components/FeatureTableHead";

const FeatureListTable = () => {
  const [{ openModal, feature }, setState] = useState<{
    openModal: boolean;
    feature: Maybe<FeatureWithPermissionDataOutput> | null | undefined;
  }>({ openModal: false, feature: undefined });

  const { data, loading } = useGetFeatureMappingListForClubQuery();

  const handleCloseModal = () => setState({ openModal: false, feature: undefined });
  const handleOpenEditModal = (feature: any) => {
    setState({ openModal: true, feature })
  }

  return (
    <Fragment>
      <Container>
        <TableContainer>
          <Table size="small" sx={{ minWidth: 425, width: "100%" }}>
            <FeatureTableHead />
            <TableBody>
              {data?.getFeatureMappingListForClub?.map((feature) => (
                <FeatureRow
                  key={feature?.featureId}
                  feature={feature}
                  handleOpenEditModal={handleOpenEditModal}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            {isEmpty(data?.getFeatureMappingListForClub) && <NoDataFound />}
          </Stack>
        </Fragment>
      )}
      <EditFeature
        open={openModal}
        feature={feature as any}
        handleCloseModal={handleCloseModal}
      />
    </Fragment>
  );
};

const Container = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

export default FeatureListTable;
