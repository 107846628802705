import { Paper, styled } from "@mui/material";
import dayjs from "dayjs";
import { useGetNpsInIntervalV2Query, DateRange } from "generated/graphql";
import React, { FC, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useWindowSize } from "react-use";

const NPSOverChart: FC = () => {
  const dateFormat = (date: Date | string | undefined | null) =>
    date ? dayjs(date).format("YYYY-MM-DD") : "";

  const [sevenDayPrevious, toDay] = useMemo(() => {
    return [
      new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
      new Date(Date.now() - 1 * 24 * 60 * 60 * 1000),
    ];
  }, []);
  const { data } = useGetNpsInIntervalV2Query({
    variables: {
      startDate: dateFormat(sevenDayPrevious),
      endDate: dateFormat(toDay),
      filter: DateRange.Daily,
    },
  });
  console.log("NPS", data);
  const width = useWindowSize()?.width;
  const [series, options] = useMemo(() => {
    const series: ApexAxisChartSeries | ApexNonAxisChartSeries = [
      {
        name: "Defactors (%)",

        data: data?.getNpsInIntervalV2?.data?.at(1)?.values || [],
      },
      {
        name: "Passive (%)",

        data: data?.getNpsInIntervalV2?.data?.at(2)?.values || [],
      },
      {
        name: "Promotors (%)",

        data: data?.getNpsInIntervalV2?.data?.at(0)?.values || [],
      },
      //   {
      //     name: "NPS Score",
      //     type: "line",
      //     data: [23, 42, 35, 27, 43, 22, 17, 31],
      //   }
    ];

    const options: ApexCharts.ApexOptions = {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      colors: ["#da8475", "#f6c85f", "#789f7a"],
      title: {
        text: "NPS Stats Chart (last 7 days)",
        align: "left",
      },
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       legend: {
      //         position: "bottom",
      //         offsetX: -10,
      //         offsetY: 0,
      //       },
      //     },
      //   },
      // ],
      xaxis: {
        categories: data?.getNpsInIntervalV2?.dateRange?.map((d) =>
          dayjs(d).format("dddd")
        ),
        type: "category",
        title: {
          text: `${dateFormat(sevenDayPrevious)} - ${dateFormat(toDay)}`,
        },
      },

      //   yaxis: [
      //     {
      //       title: {
      //         text: "Counts",
      //       },
      //     },
      //     {
      //       max: 100,
      //       min: 0,
      //       opposite: true,
      //       title: {
      //         text: "(%) Complete",
      //       },
      //     },
      //   ],
      fill: {
        opacity: 1,
      },
      legend: {
        position: width <= 48 ? "top" : "bottom",
        horizontalAlign: "left",
        offsetX: width <= 48 && (-10 as any),
        offsetY: width <= 48 && (0 as any),
      },
    };
    return [series, options];
  }, [data?.getNpsInIntervalV2, sevenDayPrevious, toDay, width]);

  console.log("data", series, options);

  //   const series = [
  //     {
  //       name: "Defactors",
  //       type: "column",
  //       data: [13, 23, 20, 8, 13, 27, 33, 12],
  //     },
  //     {
  //       name: "Passive",
  //       type: "column",
  //       data: [11, 17, 15, 15, 21, 14, 15, 13],
  //     },
  //     {
  //       name: "Promotors",
  //       type: "column",
  //       data: [44, 55, 41, 67, 22, 43, 21, 49],
  //     },
  //     // {
  //     //   name: "NPS Score",
  //     //   type: "line",
  //     //   data: [23, 42, 35, 0, 12, 22, 17, 31],
  //     // },
  //   ];

  //   const options: ApexCharts.ApexOptions = {
  //     chart: {
  //       //   background: "#222",
  //       height: 400,
  //     //   redrawOnParentResize: true,
  //     //   stacked: true,
  //     //   stackType: "100%",
  //       toolbar: {
  //         show: true,
  //       },
  //       zoom: {
  //         enabled: true,
  //       },
  //       type: "line",
  //     },
  //     colors: ["#da8475", "#f6c85f", "#789f7a", "#000"],
  //     dataLabels: {
  //       enabled: true,
  //       enabledOnSeries: [3],
  //     },
  //     noData: {
  //       align: "center",
  //       text: "No data available at the moment",
  //       verticalAlign: "middle",
  //     },
  //     plotOptions: {
  //       bar: {
  //         borderRadius: 8,
  //         horizontal: false,
  //       },
  //     },
  //     stroke: {
  //       width: [0, 0, 4],
  //     },
  //     title: {
  //       text: "Completion Count and Percentage",
  //     },
  //     xaxis: {
  //       categories: [
  //         "2011 Q1",
  //         "2011 Q2",
  //         "2011 Q3",
  //         "2011 Q4",
  //         "2012 Q1",
  //         "2012 Q2",
  //         "2012 Q3",
  //         "2012 Q4",
  //       ],
  //       type: "category",
  //     },
  //     yaxis: [
  //       {
  //         max: 100,
  //         min: 0,
  //         title: {
  //           text: "Counts",
  //         },
  //       },
  //       {
  //         max: 100,
  //         min: -100,
  //         opposite: true,
  //         title: {
  //           text: "(%) Complete",
  //         },
  //       },
  //     ],
  //   };
  return (
    <Container>
      <ReactApexChart
        series={series}
        options={options}
        type="bar"
        height={350}
        width={"100%"}
      />
    </Container>
  );
};

export default NPSOverChart;

const Container = styled(Paper)({
  "@media (max-width:500px)": {
    maxWidth: 289,
    width: "100%",
  },
  padding: "20px 10px",
  flex: 1,
});
