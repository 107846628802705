import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { FieldArray, Formik, FormikHelpers } from "formik";
import React, { FC, Fragment } from "react";
import PlatformType from "./PlatformType";
import WhatsNewItem from "./WhatsNewItem";
import { PlatformTypeForWhatsNew } from "generated/graphql";
import ModalLoading from "components/ModalLoading";

type WhatsNew = {
  title: string;
  image: File | null;
  subText: string;
};
export type WhatsNewFormInitialValues = {
  versionNo: string;
  isActive: boolean;
  platformType: Array<PlatformTypeForWhatsNew>;
  whatsNew: Array<WhatsNew>;
};

const initialWhatsNew: WhatsNew = {
  title: "",
  subText: "",
  image: null,
};

export type OnSubmit = (
  values: WhatsNewFormInitialValues,
  formikHelpers: FormikHelpers<WhatsNewFormInitialValues>
) => void | Promise<any>;

type WhatsNewFormProps = {
  initialValues: WhatsNewFormInitialValues;
  onSubmit: OnSubmit;
};

const WhatsNewForm: FC<WhatsNewFormProps> = ({ initialValues, onSubmit }) => {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values,errors, isSubmitting, handleChange, handleBlur, submitForm }) => (
        <Fragment>
          {isSubmitting ? <ModalLoading /> : null}
          <DialogContent dividers>
            <Stack direction="column" spacing={1} alignItems="flex-start">
              <TextField
                label="Released Version"
                name="versionNo"
                value={values.versionNo}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.versionNo)}
                helperText={errors.versionNo}
              />
              <PlatformType />
              <FormControl component="fieldset" sx={{ alignSelf: "stretch" }}>
                <FormLabel component="legend">Whats New</FormLabel>
                <Stack direction="column" spacing={2} paddingY={2}>
                  <FieldArray
                    name="whatsNew"
                    render={(arrayHelpers: any) => (
                      <Fragment>
                        {values?.whatsNew?.map((_, index) => (
                          <WhatsNewItem
                            key={index}
                            index={index}
                            remove={() => arrayHelpers.remove(index)}
                          />
                        ))}
                        <Button
                          startIcon={"+"}
                          onClick={() => arrayHelpers.push(initialWhatsNew)}
                        >
                          Add New Item
                        </Button>
                      </Fragment>
                    )}
                  />
                </Stack>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              value={true}
              control={<Switch color="primary" />}
              label="Is active?"
              labelPlacement="start"
              checked={values.isActive}
              name="isActive"
              onChange={handleChange}
            />
            <Button size="medium" sx={{ minWidth: 150 }} onClick={submitForm}>
              Submit
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Formik>
  );
};

export default WhatsNewForm;
