import styled from "@emotion/styled";
import { Button, Dialog } from "@mui/material";
import React, { useState } from "react";
import AddClubCategoryModal from "./AddClubCategoryModal";
import CustomDialogTitle from "./component/CustomDialogeTitle";

const AddClubCategory = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <Container>
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        Add Club Category
      </Button>
      <CustomDialog open={open} onClose={handleCloseModal}>
        <CustomDialogTitle
          title="Add Club Category"
          onClick={() => setOpen(false)}
        />
        <AddClubCategoryModal onClose={() => setOpen(false)} />
      </CustomDialog>
    </Container>
  );
};

export default AddClubCategory;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  .MuiButton-root {
    text-transform: capitalize;
  }
`;

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 480px;
  }
  .MuiTextField-root {
    margin-top: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 12px 8px;
    .MuiButton-root {
      max-width: 200px;
      width: 100%;
    }
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
`;
