import styled from "@emotion/styled";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { FC, Fragment } from "react";

import {
  ContentMadeByUser,
  TickTypeForContent,
} from "generated/graphql";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface TickUsersFilterProps {
  searchParams: URLSearchParams;
  setSearchParams: any;
}

const TickUsersFilter: FC<TickUsersFilterProps> = ({
  searchParams,
  setSearchParams,
}) => {
  const onFilter = (values: any) => {
    if (values.typeOfContentFilter?.length) {
      searchParams?.set(
        "typeOfContentFilter",
        (values.typeOfContentFilter || []).join(",")
      );
    } else {
      searchParams.delete("typeOfContentFilter");
    }
    if (values?.searchText?.length) {
      searchParams?.set("searchText", values?.searchText);
    } else {
      searchParams.delete("searchText");
    }
    if (values.tickFilter?.length) {
      searchParams?.set("tickFilter", (values.tickFilter || []).join(","));
    } else {
      searchParams.delete("tickFilter");
    }
    setSearchParams(searchParams);
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={
          {
            typeOfContentFilter: searchParams?.get("typeOfContentFilter")?.split(",") || [],
            searchText: searchParams?.get("searchText") || "",
            tickFilter: searchParams?.get("tickFilter")?.split(",") || []
          } as any
        }
        onSubmit={onFilter}
      >
        {({ values, handleChange, handleBlur, setFieldValue }) => (
          <ResponsiveForm>
            <TextField
              label="Search User"
              name="searchText"
              value={values.searchText}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{minWidth:"200px"}}
            />
            <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel id={`typeOfContentFilter`}>Creator type</InputLabel>
              <Select
                labelId={`typeOfContentFilter`}
                name="typeOfContentFilter"
                value={values?.typeOfContentFilter}
                onChange={(e) => {
                  const { value } = e.target;
                  setFieldValue(
                    "typeOfContentFilter",
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                multiple
                input={<OutlinedInput label="Creator type" />}
                renderValue={(selected) => Array(selected).join(", ")}
                MenuProps={MenuProps}
              >
                <MenuItem
                  key={ContentMadeByUser.AllContent}
                  value={ContentMadeByUser.AllContent}
                >
                  <Checkbox
                    checked={
                      values?.typeOfContentFilter?.indexOf(
                        ContentMadeByUser.AllContent
                      ) > -1
                    }
                  />
                  <ListItemText primary={ContentMadeByUser.AllContent} />
                </MenuItem>
                <MenuItem
                  key={ContentMadeByUser.BeginnerContent}
                  value={ContentMadeByUser.BeginnerContent}
                >
                  <Checkbox
                    checked={
                      values?.typeOfContentFilter?.indexOf(
                        ContentMadeByUser.BeginnerContent
                      ) > -1
                    }
                  />
                  <ListItemText primary={ContentMadeByUser.BeginnerContent} />
                </MenuItem>
                <MenuItem
                  key={ContentMadeByUser.InvestorContent}
                  value={ContentMadeByUser.InvestorContent}
                >
                  <Checkbox
                    checked={
                      values?.typeOfContentFilter?.indexOf(
                        ContentMadeByUser.InvestorContent
                      ) > -1
                    }
                  />
                  <ListItemText primary={ContentMadeByUser.InvestorContent} />
                </MenuItem>
                <MenuItem
                  key={ContentMadeByUser.TraderContent}
                  value={ContentMadeByUser.TraderContent}
                >
                  <Checkbox
                    checked={
                      values?.typeOfContentFilter?.indexOf(
                        ContentMadeByUser.TraderContent
                      ) > -1
                    }
                  />
                  <ListItemText primary={ContentMadeByUser.TraderContent} />
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel id={`tickFilter`}>Tick</InputLabel>
              <Select
                labelId={`tickFilter`}
                name="tickFilter"
                value={values?.tickFilter}
                onChange={(e) => {
                  const { value } = e.target;
                  setFieldValue(
                    "tickFilter",
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                multiple
                input={<OutlinedInput label="Tick" />}
                renderValue={(selected) => Array(selected).join(", ")}
                MenuProps={MenuProps}
              >
                <MenuItem
                  key={TickTypeForContent.GreenTick}
                  value={TickTypeForContent.GreenTick}
                >
                  <Checkbox
                    checked={
                      values?.tickFilter?.indexOf(
                        TickTypeForContent?.GreenTick
                      ) > -1
                    }
                  />
                  <ListItemText primary="Green Tick" />
                </MenuItem>
                <MenuItem
                  key={TickTypeForContent.IsVerified}
                  value={TickTypeForContent.IsVerified}
                >
                  <Checkbox
                    checked={
                      values?.tickFilter?.indexOf(
                        TickTypeForContent?.IsVerified
                      ) > -1
                    }
                  />
                  <ListItemText primary="Blue Tick" />
                </MenuItem>
                {/* <MenuItem
                  key={TickTypeForContent.Tick3}
                  value={TickTypeForContent.Tick3}
                >
                  <Checkbox
                    checked={
                      values?.tickFilter?.indexOf(TickTypeForContent?.Tick3) >
                      -1
                    }
                  />
                  <ListItemText primary="Golden Tick" />
                </MenuItem> */}
              </Select>
            </FormControl>

            <Button variant="contained" type="submit">
              Search
            </Button>
          </ResponsiveForm>
        )}
      </Formik>
    </Fragment>
  );
};

export default TickUsersFilter;
const ResponsiveForm = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  .MuiFormLabel-root {
    font-size: 0.875rem;
  }
  .MuiFormControl-root {
    margin-bottom: 8px;
  }
  legend {
    font-size: 0.75rem;
  }
  @media (min-width: 705px) {
    flex-direction: row;
    padding: 16px;
    .MuiFormControl-root {
      margin-bottom: 0;
      width: 100%;
      max-width: 200px;
      margin-right: 8px;
    }
    .MuiButtonBase-root {
      max-width: 150px;
      width: 100%;
    }
  }
`;
