import React, { FC, Fragment } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  useAddFeatureMappingMutation,
  NewFeatureWithPermissionDataOutputFragmentDoc,
} from "generated/graphql";
import { Formik } from "formik";
import { Close } from "@mui/icons-material";
import toast from "react-hot-toast";
import { isEmpty } from "lodash-es";

interface AddFeatureFormProps {
  handleCloseModal: () => void;
}

export const AddFeatureForm: FC<AddFeatureFormProps> = ({
  handleCloseModal,
}) => {
  const [addFeatureMapping, { loading }] = useAddFeatureMappingMutation();
  return (
    <Fragment>
      <Formik
        initialValues={{
          featureData: {
            featureName: "",
            featureAlias: "",
          },
          platformPermissionData: {
            canPost: false,
            canEngage: false,
            canViewPost: false,
            canFollow: false,
            canBeFollowed: false,
            canBlock: false,
            canReport: false,
            canSeeWatchList: false,
            canSeeOthersFeedPosts: false,
            canSeeMarketingPage: false,
            canAutoJoinBlueTick: false,
            canAutoJoinGreenTick: false,
            canAutoJoinYellowTick: false,
            canFollowPost: false,
            canShowNps: false,
            canSeePremiumPromotion:false,
            canSeeClubPromotion:false
          },
        }}
        validate={(values) => {
          const featureData: Record<string, string> = {};
          if (!values.featureData.featureName)
            featureData.featureName = "Required";
          // if (!values.featureData.featureAlias)
          //   featureData.featureAlias = "Required";
          return isEmpty(featureData) ? {} : { featureData };
        }}
        onSubmit={(values, { setSubmitting }) => {
          addFeatureMapping({
            variables: { data: values },
            update: (cache, { data }) => {
              cache.modify({
                fields: {
                  getFeatureMappingList: (previous) => {
                    const newFeatureMappingRef = cache.writeFragment({
                      data: data?.addFeatureMapping,
                      fragment: NewFeatureWithPermissionDataOutputFragmentDoc,
                    });
                    return {
                      ...previous,
                      dataList: [...previous.dataList, newFeatureMappingRef],
                      total: previous.total + 1,
                    };
                  },
                },
              });
            },
            onCompleted: (data) => {
              if (data?.addFeatureMapping) {
                toast.success("Successfully added!");
                handleCloseModal();
              }
            },
            onError: (error) => {
              toast.error(error.message);
            },
          }).finally(() => {
            setSubmitting(false);
          });
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          handleBlur,
          errors,
          submitForm,
        }) => (
          <Fragment>
            <CustomDialogTitle>
              Add Feature
              <CustomIconButton onClick={handleCloseModal}>
                <Close />
              </CustomIconButton>
            </CustomDialogTitle>
            <DialogContent>
              <TextField
                label="Feature Name"
                value={values.featureData.featureName}
                name="featureData.featureName"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={Boolean(errors?.featureData?.featureName)}
                helperText={errors?.featureData?.featureName}
              />
              <TextField
                label="Feature Alias"
                value={values.featureData.featureAlias}
                name="featureData.featureAlias"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={Boolean(errors?.featureData?.featureAlias)}
                helperText={errors?.featureData?.featureAlias}
              />
              <PermissionHeader>Platform Permissions</PermissionHeader>
              <Box sx={{ display: "flex" }}>
                <FormControl
                  sx={{ m: 1 }}
                  component="fieldset"
                  variant="standard"
                  style={{ paddingLeft: 12 }}
                >
                  <GridContainer>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={values?.platformPermissionData.canPost}
                            name="platformPermissionData.canPost"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Post"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={values?.platformPermissionData.canEngage}
                            name="platformPermissionData.canEngage"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Engage"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={values?.platformPermissionData.canViewPost}
                            name="platformPermissionData.canViewPost"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Post View"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={values?.platformPermissionData.canFollow}
                            name="platformPermissionData.canFollow"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Follow"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={values?.platformPermissionData.canBeFollowed}
                            name="platformPermissionData.canBeFollowed"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Get Followed"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={values?.platformPermissionData.canBlock}
                            name="platformPermissionData.canBlock"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Block"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={values?.platformPermissionData.canReport}
                            name="platformPermissionData.canReport"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Report"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={
                              values?.platformPermissionData.canSeeWatchList
                            }
                            name="platformPermissionData.canSeeWatchList"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="See Watchlist"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={
                              values?.platformPermissionData
                                .canSeeOthersFeedPosts
                            }
                            name="platformPermissionData.canSeeOthersFeedPosts"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="See Others Posts"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={
                              values?.platformPermissionData.canSeeMarketingPage
                            }
                            name="platformPermissionData.canSeeMarketingPage"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="See Marketing Page"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={
                              values?.platformPermissionData.canAutoJoinBlueTick
                            }
                            name="platformPermissionData.canAutoJoinBlueTick"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Auto Join Blue Tick"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={
                              values?.platformPermissionData
                                .canAutoJoinGreenTick
                            }
                            name="platformPermissionData.canAutoJoinGreenTick"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Auto Join Green Tick"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={
                              values?.platformPermissionData
                                .canAutoJoinYellowTick
                            }
                            name="platformPermissionData.canAutoJoinYellowTick"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Auto Join Yellow Tick"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canFollowPost
                            )}
                            name="platformPermissionData.canFollowPost"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Follow Post"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canShowNps
                            )}
                            name="platformPermissionData.canShowNps"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Show NPS"
                      />
                    </Grid>

                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canSeeClubPromotion
                            )}
                            name="platformPermissionData.canSeeClubPromotion"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="See Club Promotion"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canSeePremiumPromotion
                            )}
                            name="platformPermissionData.canSeePremiumPromotion"
                          />
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="See Premium Promotion"
                      />
                    </Grid>
                  </GridContainer>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isSubmitting || loading}
                onClick={submitForm}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

const CustomIconButton = styled(IconButton)`
  position: absolute;
  right: 16px;
`;

const CustomDialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
`;

const PermissionHeader = styled(Typography)`
  font-size: 14px !important;
  padding-top: 10px;
`;

const GridContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;

  @media (min-width: 440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
