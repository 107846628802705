import { Tab, Tabs } from "@mui/material";
import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { Link, useLocation, Outlet } from "react-router-dom";
import AddCRONJob from "./AddCRONJob";
import { withAdminRoleRequired } from "config/auth";

import { keyRoleMap } from "config/side-menu-items";

const Jobs = () => {
  const { pathname } = useLocation();
  return (
    <Fragment>
      <Container>
        <Tabs value={pathname} indicatorColor="primary">
          <Tab
            label="Schedule Jobs"
            component={Link}
            value="/jobs/schedule-jobs"
            to="schedule-jobs"
          />
          <Tab
            label="Pending Schedule Jobs"
            component={Link}
            value="/jobs/pending-schedule-jobs"
            to="pending-schedule-jobs"
          />
          <Tab
            label="CRON Job History"
            component={Link}
            value="/jobs/job-history"
            to="job-history"
          />
        </Tabs>
        <AddCRONJob />
      </Container>
      <Outlet />
    </Fragment>
  );
};

export default withAdminRoleRequired(Jobs, {
  roles: keyRoleMap["CRON / Schedule Jobs"],
});

const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
    padding: 16px 16px 0 16px;
    .MuiTabs-root {
        margin-top: 16px;
        .MuiTabs-scroller  {
            overflow-x: auto!important;
        
    }
    @media (min-width: 540px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .MuiTabs-root {
            margin-top: 0;
            .MuiTabs-scroller  {
                overflow-x: hidden!important;
            }
        }
    }
`;
