import React, { FC, Fragment, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Stack,
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Box,
  FormControlLabel,
  Radio,
} from "@mui/material";
import UploadCreative from "components/UploadCreative";
import {
  Maybe,
  UserAudienceType,
  UpcomingEvents,
  useEditUpcomingEventMutation,
} from "generated/graphql";
import { toast } from "react-hot-toast";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import AbsoluteLoader from "components/AbsoluteLoader";
import { editEventValidationSchema } from "./validation";
import CustomDialogTitle from "components/DialogTitle";
import dayjs, { Dayjs } from "dayjs";
import { uploadFiles } from "api/rest";

interface Values {
  eventId: string;
  text: string;
  image: File | undefined;
  startTime: Dayjs | undefined;
  ctaButtonText: string;
  ctaButtonUrl: string;
  endTime: Dayjs | undefined;
  description: string;
  isActive: Maybe<boolean> | undefined;
  audience: UserAudienceType;
}

interface EditEventProps {
  open: boolean;
  event: Maybe<UpcomingEvents> | null | undefined;
  handleCloseModal: () => void;
}

const EditEvent: FC<EditEventProps> = ({ open, event, handleCloseModal }) => {
  const fullScreen = useMediaQuery("(max-width:540px)");
  return (
    <CustomDialog
      open={open}
      // onClose={handleCloseModal}
      fullScreen={fullScreen}
      disableEscapeKeyDown
    >
      <CreatePromotionForm event={event} handleCloseModal={handleCloseModal} />
    </CustomDialog>
  );
};

export default EditEvent;

const CreatePromotionForm: FC<
  Pick<EditEventProps, "event" | "handleCloseModal">
> = ({ event, handleCloseModal }) => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [editUpcomingEvent, { loading }] = useEditUpcomingEventMutation();

  const handleSubmit = async (
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ) => {
    const { image, ...rest } = values;
    const variables: any = { ...rest };
    setIsFileUploading(true);
    try {
      if (image) {
        const { data } = await uploadFiles([image]);
        variables.imageUrl = data?.[0]?.uploadfilename;
      } else {
        variables.imageUrl = event?.imageUrl?.filename;
      }
      await editUpcomingEvent({ variables });
      toast.success("Event updated!");
      handleCloseModal();
    } catch (e) {
      console.error(e);
    } finally {
      setIsFileUploading(false);
      formikHelpers.setSubmitting(false);
    }
  };

  const initialValues: Values = {
    eventId: event?.eventId as string,
    text: event?.title as string,
    image: undefined,
    startTime: event?.startTime ? dayjs(event?.startTime) : undefined,
    ctaButtonText: event?.ctaButtonText as string,
    ctaButtonUrl: event?.ctaButtonUrl as string,
    endTime: event?.endTime ? dayjs(event?.endTime) : undefined,
    description: event?.description || "",
    isActive: event?.isActive,
    audience: event?.audience as UserAudienceType,
  };

  console.log("Edit Event", initialValues);
  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={editEventValidationSchema}
        enableReinitialize
      >
        {({
          isSubmitting,
          values,
          handleChange,
          handleBlur,
          errors,
          setFieldValue,
          submitForm,
        }) => (
          <Fragment>
            <AbsoluteLoader
              loading={isSubmitting || loading || isFileUploading}
            />
            <CustomDialogTitle onClose={handleCloseModal}>
              Edit Event
            </CustomDialogTitle>
            <DialogContent>
              <TextField
                label="Title"
                value={values.text}
                name="text"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors?.text)}
                helperText={errors.text}
              />
              <FormControl fullWidth>
                <UploadCreative
                  image={values.image || (event?.imageUrl as any)?.showUri}
                  setImage={(image) => setFieldValue("image", image)}
                />
                <FormHelperText error={Boolean(errors?.image)}>
                  {errors.image}
                </FormHelperText>
              </FormControl>
              <Stack direction="row" spacing={2}>
                <DateTimePicker
                  label="Start"
                  value={values.startTime}
                  onChange={(startTime) =>
                    setFieldValue("startTime", startTime)
                  }
                  // renderInput={props => <TextField {...props}
                  //     error={Boolean(errors?.startTime)}
                  //     helperText={errors.startTime}
                  // />}
                  maxDate={values.endTime}
                />
                <DateTimePicker
                  label="End"
                  value={values.endTime}
                  onChange={(endTime) => setFieldValue("endTime", endTime)}
                  // renderInput={props => <TextField {...props}
                  //     error={Boolean(errors?.endTime)}
                  //     helperText={errors.endTime}
                  // />}
                  minDate={values.startTime}
                />
              </Stack>
              <TextField
                label="CTA Button Text"
                value={values.ctaButtonText}
                name="ctaButtonText"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors?.ctaButtonText)}
                helperText={errors.ctaButtonText}
              />
              <TextField
                label="CTA Button URL"
                value={values.ctaButtonUrl}
                name="ctaButtonUrl"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors?.ctaButtonUrl)}
                helperText={errors.ctaButtonUrl}
              />
              <TextField
                label="Description"
                value={values.description}
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                multiline
                rows={4}
                error={Boolean(errors?.description)}
                helperText={errors.description}
              />
              <FormControl
                sx={{ mt: "13px" }}
                component="fieldset"
                variant="standard"
              >
                <Stack direction="row" gap={5}>
                  <FormLabel sx={{ mt: "10px" }}>Audience</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="audience"
                    value={values.audience}
                    onChange={handleChange}
                  >
                    <Stack direction="row">
                      <Box>
                        <FormControlLabel
                          value={UserAudienceType.Club}
                          control={<Radio />}
                          label="Club"
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value={UserAudienceType.Premium}
                          control={<Radio />}
                          label="Premium"
                        />
                      </Box>
                    </Stack>
                  </RadioGroup>
                </Stack>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button disabled={isSubmitting || loading} onClick={submitForm}>
                Save
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 480px;
  }
  .MuiTextField-root {
    margin-top: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 12px 8px;
    .MuiButton-root {
      max-width: 200px;
      width: 100%;
    }
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
`;
