import { Close } from "@mui/icons-material";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import React, { FC } from "react";
import styled from "@emotion/styled";

interface ModalHeaderProps {
    title?: string;
    onClickCloseButton?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const ModalHeader: FC<ModalHeaderProps> = ({ title, onClickCloseButton }) => {
    return (
        <CustomDialogTitle>
            <Typography variant="h5">{title}</Typography>
            <CustomIconButton onClick={onClickCloseButton}>
                <Close />
            </CustomIconButton>
        </CustomDialogTitle>
    )
}

export default ModalHeader;

const CustomIconButton = styled(IconButton)`
    position: absolute;
    right: 16px;
`;

const CustomDialogTitle = styled(DialogTitle)`
    display: flex;
    align-items: center;
`;