import { Roles } from "./roles";

export const checkAccessibility = (
  roles: Array<string>,
  target: Array<string>
) => target.some((v) => roles.includes(v));

export const PermissionsFor = {
  Home: [Roles.SocialAdmin, Roles.SocialSupport],
  Activities: [Roles.SocialAdmin],
  Reports: [Roles.SocialAdmin, Roles.SocialModerator, Roles.SocialSupport],
  MIS: [Roles.SocialAdmin, Roles.SocialMIS],
  UserEngagementSummary: [Roles.SocialAdmin, Roles.SocialMIS],
  PostSummary: [Roles.SocialAdmin, Roles.SocialMIS],
  CommentSummary: [Roles.SocialAdmin, Roles.SocialMIS],
  ReplySummary: [Roles.SocialAdmin, Roles.SocialMIS],
  SessionSummary: [Roles.SocialAdmin, Roles.SocialMIS],
  Feedbacks: [Roles.SocialAdmin, Roles.SocialSupport],
  Suspected: [Roles.SocialAdmin, Roles.SocialModerator],
  Users: [Roles.SocialAdmin, Roles.SocialSupport],
  MOM: [Roles.SocialAdmin, Roles.SocialSupport],
  PushNotifications: [Roles.SocialAdmin],
  NPS: [Roles.SocialAdmin, Roles.SocialSupport],
  JOBS: [Roles.SocialTechnicalOperator],
  Clubs: [
    Roles.SocialTechnicalOperator,
    Roles.SocialSupport,
    Roles.SocialAdmin,
  ],
  SocialTechnicalOperatorFeatures: [Roles.SocialTechnicalOperator],
  ExternalResource: [Roles.SocialAdmin, Roles.SocialSupport],
  Promotions: [Roles.SocialAdmin, Roles.SocialSupport],
  ProductTour: [Roles.SocialAdmin, Roles.SocialTechnicalOperator],
  Feature: [Roles.SocialTechnicalOperator],
  Events: [Roles.SocialAdmin, Roles.SocialSupport],
  Onboarding: [
    Roles.SocialSupport,
    Roles.SocialAdmin,
    Roles.SocialTechnicalOperator,
  ],
  Interstitials: [Roles.SocialTechnicalOperator, Roles.SocialSupport],
  BadgeSystem: [Roles.SocialTechnicalOperator],
  ABTesting: [Roles.SocialTechnicalOperator],
  PriorityProfiles: [Roles.SocialTechnicalOperator],
  WhatsNew: [Roles.SocialTechnicalOperator],
};
