import React, { FC } from "react";
import { Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import dayjs from "dayjs";

const DateTime: FC<{ date: string | number | undefined }> = ({ date }) => {
    const time = dayjs(date).format('hh:mm A');
    let formatedDate = `${dayjs(date).format("MMMM D, YYYY")} at ${time}`;
    const hour = dayjs().diff(dayjs(date), "hour");
    if (hour < 7 * 24) {
        formatedDate = `${dayjs(date).format("dddd")} at ${time}`;
    }
    if (hour < 24) {
        formatedDate = hour + (hour > 1 ? " hours" : " hour") + " ago";
    }
    const minute = dayjs().diff(dayjs(date), "minute");
    if (minute < 60) {
        formatedDate = minute + (minute > 1 ? " minutes" : " minute") + " ago";
    }
    const second = dayjs().diff(dayjs(date), "second");
    if (second < 60) {
        formatedDate = second + (second > 1 ? " seconds" : " second") + " ago";
    }
    if (date)
        return (
            <Tooltip title={`${dayjs(date).format('dddd, MMM D, YYYY')} at ${time}`} arrow>
                <Time>{formatedDate}</Time>
            </Tooltip>
        )
    return null
}

export default DateTime;

const Time = styled.time`
`;