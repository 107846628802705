import React, { FC } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { UserHashtagContentType } from "generated/graphql";

const CategoryDropdown: FC<SelectProps> = (props) => {
    return (
        <FormControl size="small" sx={{minWidth: 200}}>
            <InputLabel id={`category-label`}>Category</InputLabel>
            <Select
                labelId={`category-label`}
                id={"category"}
                label="Category"
                {...props}
            >
                <MenuItem key={UserHashtagContentType.Beginner} value={UserHashtagContentType.Beginner}>
                    {UserHashtagContentType.Beginner}
                </MenuItem>
                <MenuItem key={UserHashtagContentType.Investor} value={UserHashtagContentType.Investor}>
                    {UserHashtagContentType.Investor}
                </MenuItem>
                <MenuItem key={UserHashtagContentType.Trader} value={UserHashtagContentType.Trader}>
                    {UserHashtagContentType.Trader}
                </MenuItem>
            </Select>
        </FormControl>
    )
}


export default CategoryDropdown;
