import React, { FC, Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  // TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { Formik, ErrorMessage } from "formik";
import { NameOfCron, useCreateManualCronJobMutation } from "generated/graphql";
import toast from "react-hot-toast";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const AddCRONJob = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <Fragment>
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        Add Schedule Job
      </Button>
      <AddCRONJobModal open={open} handleCloseModal={handleCloseModal} />
    </Fragment>
  );
};

export default AddCRONJob;

interface AddCRONJobModalProps extends DialogProps {
  handleCloseModal: () => void;
}

interface InitialValues {
  nameOfCron: NameOfCron;
  scheduleTime: Date | null;
}

const AddCRONJobModal: FC<AddCRONJobModalProps> = ({
  handleCloseModal,
  ...rest
}) => {
  const [createManualCronJob] = useCreateManualCronJobMutation();
  return (
    <Modal {...rest} onClose={handleCloseModal}>
      <Formik
        initialValues={{
          nameOfCron: "",
          scheduleTime: null,
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.nameOfCron) {
            errors.nameOfCron = "Name of cron is required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          createManualCronJob({
            variables: values as InitialValues,
          })
            .then(() => {
              toast.success("Successfully created");
              handleCloseModal();
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <Fragment>
            <DialogTitle className="modalTitle">
              <h3>Schedule Job</h3>
              <IconButton onClick={handleCloseModal}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <FormControl
                size="small"
                error={Boolean(errors?.nameOfCron)}
                variant="outlined"
                fullWidth
              >
                <InputLabel>Name of Cron</InputLabel>
                <Select
                  label="Name of Cron"
                  name="nameOfCron"
                  value={values.nameOfCron}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value={NameOfCron.CountSync}>CountSync</MenuItem>
                  <MenuItem value={NameOfCron.CurrentWeekMissed}>
                    CurrentWeekMissed
                  </MenuItem>
                  <MenuItem value={NameOfCron.DeleteCommentCron}>
                    DeleteCommentCron
                  </MenuItem>
                  <MenuItem value={NameOfCron.DeleteNotificationCron}>
                    DeleteNotificationCron
                  </MenuItem>
                  <MenuItem value={NameOfCron.DeletePostCron}>
                    DeletePostCron
                  </MenuItem>
                  <MenuItem value={NameOfCron.DeleteReplyCron}>
                    DeleteReplyCron
                  </MenuItem>
                  <MenuItem value={NameOfCron.ExpiryDateSyncForRecentExpires}>
                    ExpiryDateSyncForRecentExpires
                  </MenuItem>
                  <MenuItem value={NameOfCron.PostHashTagSync}>
                    PostHashTagSync
                  </MenuItem>
                  <MenuItem value={NameOfCron.PostStockSync}>
                    PostStockSync
                  </MenuItem>
                  <MenuItem value={NameOfCron.SyncActivityOfPostCommentReply}>
                    SyncActivityOfPostCommentReply
                  </MenuItem>
                  <MenuItem value={NameOfCron.PostDollarTagSync}>
                    PostDollarTagSync
                  </MenuItem>
                  <MenuItem value={NameOfCron.IndustryDataSync}>
                    IndustryDataSync
                  </MenuItem>
                  <MenuItem value={NameOfCron.SectorDataSync}>
                    SectorDataSync
                  </MenuItem>
                  <MenuItem value={NameOfCron.PreComputeTopPostsOfAnalysts}>
                    PreComputeTopPostsOfAnalysts
                  </MenuItem>
                  <MenuItem value={NameOfCron.FcmUserTokenSubscription}>
                    FcmUserTokenSubscription
                  </MenuItem>
                  <MenuItem value={NameOfCron.ContainsAMissedWeek}>
                    ContainsAMissedWeek
                  </MenuItem>
                </Select>
                <ErrorMessage name="nameOfCron" component={FormHelperText} />
              </FormControl>
              <DateTimePicker
                label="Schedule Time"
                format="YYYY-MM-DD HH:MM:ss"
                value={values.scheduleTime}
                onChange={(scheduleTime) =>
                  setFieldValue("scheduleTime", scheduleTime)
                }
                // renderInput={(params) => <TextField {...params} />}
                // InputProps={{
                //   size: "small",
                //   fullWidth: true
                // }}
                desktopModeMediaQuery="@media (min-width: 540px)"
                minDate={dayjs()}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Create
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </Modal>
  );
};

const Modal = styled(Dialog)`
  .modalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .MuiPaper-root {
    overflow-y: initial;
  }
  .MuiDialog-container {
    align-items: flex-start;
  }
  .MuiDialog-paper {
    width: 100%;
    max-width: 500px;
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
  .MuiDialogContent-root {
    .MuiFormControl-root {
      width: 100%;
      margin-top: 16px;
      &:last-child {
        margin-bottom: 16px;
      }
    }
    padding: 8px 16px;
    .MuiChip-outlined {
      margin: auto 5px;
      height: 20px;
    }
  }
  .MuiDialogActions-root {
    justify-content: center;
    .MuiButtonBase-root {
      max-width: 150px;
      width: 100%;
    }
  }
  @media (max-width: 540px) {
    .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialogTitle-root {
      justify-content: flex-start;
      align-items: center;
    }
    .MuiDialogTitle-root {
      padding: 8px;
      margin: 0;
    }
    .MuiTypography-root {
      font-size: 16px;
    }
    .back-btn {
      display: block;
      margin-right: 8px;
    }
    .close-btn {
      display: none;
    }
  }
  @media (min-width: 481px) {
    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;
