import React, { FC } from "react";
import { Formik, FormikHelpers, Form } from "formik";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import styled from "@emotion/styled";
import CustomDatePicker from "components/CustomDatePicker";
import { FilterType, MisForCreatorsOutput } from "generated/graphql";
import { UserSearchModal } from "./UserSearchModal";
import dayjs, { Dayjs } from "dayjs";

export interface Values {
  startDate: Dayjs;
  endDate: Dayjs;
  filter:
    | FilterType.Bluetick
    | FilterType.Greentick
    | FilterType.Goldentick
    | FilterType.AllCreators;
  userId: string | undefined;
  fullName: string | undefined;
}

export type OnSubmit = (
  values: Values,
  formikHelpers: FormikHelpers<Values>
) => void | Promise<any>;

interface DateFilterProps {
  user: MisForCreatorsOutput;
  initialValues: Values;
  onSubmit: OnSubmit;
  onClear: () => void;
  handleClearUser: (a: any, b: any) => void;
}

const DateFilter2: FC<DateFilterProps> = ({
  initialValues,
  onSubmit,
  onClear,
  handleClearUser,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleBlur }) => (
        <ResponsiveForm>
          <CustomStack direction="row">
            <CustomBox>
              <CustomDatePicker
                label="Start Date"
                value={values.startDate}
                onChange={(date) => setFieldValue("startDate", date)}
                maxDate={values.endDate || dayjs()}
              />
            </CustomBox>

            <CustomBox>
              <CustomDatePicker
                label="End Date"
                value={values.endDate}
                onChange={(date) => setFieldValue("endDate", date)}
                maxDate={dayjs()}
                minDate={values.startDate}
              />
            </CustomBox>
            <CustomBox>
              <FormControl variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">
                  User Type
                </InputLabel>
                <Select
                  defaultValue={FilterType.AllCreators}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="User Type"
                  name="filter"
                  value={values.filter}
                  onChange={(e) => {
                    if (e.target.value != values.filter) {
                      setFieldValue("userId", "");
                    }
                    setFieldValue("filter", e.target.value);
                  }}
                  onBlur={handleBlur}

                  //   value={filter}
                  // onChange={({ target: { value } }: any) =>
                  //   setData((x) => ({ ...x, filter: value }))
                  // }
                >
                  <MenuItem
                    value={FilterType.Bluetick}
                    onClick={() =>
                      handleClearUser(values.filter, FilterType.Bluetick)
                    }
                  >
                    Blue Tick
                  </MenuItem>
                  <MenuItem
                    value={FilterType.Greentick}
                    onClick={() =>
                      handleClearUser(values.filter, FilterType.Greentick)
                    }
                  >
                    Green Tick
                  </MenuItem>
                  {/* <MenuItem
                    value={FilterType.Goldentick}
                    onClick={() =>
                      handleClearUser(values.filter, FilterType.Goldentick)
                    }
                  >
                    Golden Tick
                  </MenuItem> */}
                  <MenuItem
                    value={FilterType.AllCreators}
                    onClick={() =>
                      handleClearUser(values.filter, FilterType.AllCreators)
                    }
                  >
                    All Creators
                  </MenuItem>
                </Select>
              </FormControl>
            </CustomBox>
            <CustomBox>
              <UserSearchModal onClear={onClear} />
            </CustomBox>

            <CustomBox>
              <Button type="submit" variant="contained">
                Search
              </Button>
            </CustomBox>

            {/* <CustomBox>
              <Button variant="contained">Post</Button>
            </CustomBox> */}
          </CustomStack>
        </ResponsiveForm>
      )}
    </Formik>
  );
};

export default DateFilter2;

const ResponsiveForm = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  .MuiFormControl-root {
    margin-bottom: 8px;
  }
  legend {
    font-size: 0.75rem;
  }
  @media (min-width: 425px) {
    flex-direction: row;
    padding: 16px;
    .MuiFormControl-root {
      margin-bottom: 0;
      width: 100%;
      max-width: 272px;
      margin-right: 8px;
    }
    .MuiButtonBase-root {
      max-width: 150px;
      width: 100%;
      padding: 7px;
    }
    @media (max-width: 625px) {
      margin: auto;
      .MuiButtonBase-root {
        max-width: 250px;
        width: 100%;
      }
    }
  }
`;

const CustomStack = styled(Stack)`
  @media (max-width: 625px) {
    flex-direction: column;
    width: 100%;
  }
`;

const CustomBox = styled(Box)`
  width: 100%;
  margin: 5px;
`;
