import React, { FC, Fragment, Suspense, lazy } from "react";
import { Dialog, DialogProps } from "@mui/material";
import CustomDialogTitle from "components/DialogTitle";
import styled from "@emotion/styled";

const AddInterstitialForm = lazy(() => import("./AddInterstitialForm"));

interface AddInterstitialModalProps extends DialogProps {
  handleClose: () => void;
}

const AddInterstitialModal: FC<AddInterstitialModalProps> = (props) => {
  return (
    <Modal {...props}>
      <CustomDialogTitle onClose={props.handleClose}>
        New Smart Feed
      </CustomDialogTitle>
      <Suspense fallback={<Fragment />}>
        <AddInterstitialForm onClose={props.handleClose} />
      </Suspense>
    </Modal>
  );
};

export default AddInterstitialModal;

const Modal = styled(Dialog)`
.MuiDialog-paper {
  max-width: 600px;
}
.MuiTextField-root {
  margin-top: 16px;
}
.MuiDialogActions-root {
  justify-content: center;
  padding: 12px 8px;
  .MuiButton-root {
      max-width: 200px;
      width: 100%;
  }
}
.MuiDialogTitle-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 16px;
  height: 50px;
  .MuiIconButton-root {
      padding: 8px;
  }
}
  @media (max-width: 540px) {
    .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialogTitle-root {
      align-items: center;
    }
    .MuiDialogTitle-root {
      padding: 8px;
      margin: 0;
    }
    .MuiTypography-root {
      font-size: 16px;
    }
    .back-btn {
      display: block;
      margin-right: 8px;
    }
    .close-btn {
      display: none;
    }
  }
  @media (min-width: 481px) {
    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;
