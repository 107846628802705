import React, { FC } from "react";
import Collapse from "@mui/material/Collapse";
import { FeatureWithPermissionDataOutput, Maybe, useGetClubsByFeatureIdQuery } from "generated/graphql";
import { Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { isEmpty } from "lodash-es";
import Club from "./Club";

interface FeaturePermissionsProps {
    open: boolean;
    feature: Maybe<FeatureWithPermissionDataOutput> | undefined;
}

export const ClubLists: FC<FeaturePermissionsProps> = ({ open, feature }) => {
    return (
        <Collapse in={open} timeout="auto" sx={{ backgroundColor: "transparent" }}>
            {open && <Clubs feature={feature} />}
        </Collapse>
    );
}

const Clubs: FC<{ feature: Maybe<FeatureWithPermissionDataOutput> | undefined; }> = ({ feature }) => {
    const { data } = useGetClubsByFeatureIdQuery({
        variables: {
            featureId: feature?.featureId
        }
    })
    return (
        <Container variant="elevation" elevation={0}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" paddingY={1.5}>
                <Typography variant="h6">Clubs</Typography>
            </Stack>
            <Divider />
            <Grid container spacing={1} marginTop={0}>
                {data?.getClubsByFeatureId?.map(club => <Club
                    key={feature?.featureId}
                    feature={feature}
                    club={club}
                />)}
            </Grid>
            {isEmpty(data?.getClubsByFeatureId) && <Typography align="center">No clubs founds</Typography>}
        </Container>
    )
}

const Container = styled(Paper)`
    padding: 8px 16px;
    margin-bottom: 16px;
`;