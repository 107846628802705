import React, { Fragment } from "react";
import FeatureListTable from "./FeatureListTable";
import AddFeature from "./AddFeature";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";

const Feature = () => {
  return (
    <Fragment>
      <AddFeature />
      <FeatureListTable />
    </Fragment>
  );
};

export default withAdminRoleRequired(Feature, {
  roles: keyRoleMap["Features"],
});
