import React, { FC } from "react";
import styled from "@emotion/styled";
import { HiddenStatus, Maybe, ReportStatus, SuspectedStatus, SuspendedStatus } from "generated/graphql";
import { meanings, meaningColors } from '../../constants';

export const DisplayStatus: FC<{
    remark: string;
    hiddenStatus?: Maybe<HiddenStatus> | undefined;
    suspectedStatus?: Maybe<SuspectedStatus> | undefined;
    reportStatus?: Maybe<ReportStatus> | undefined;
    suspendedStatus?: Maybe<SuspendedStatus> | undefined
}> = ({ remark, reportStatus, hiddenStatus, suspendedStatus, suspectedStatus }) => {
    return (
        <Container>
            <table>
                {Boolean(hiddenStatus) && <tr>
                    <th>Visible Status</th>
                    <td><em style={{ color: meaningColors(hiddenStatus || "") }}>{meanings(hiddenStatus || "")}</em></td>
                </tr>}
                {Boolean(suspectedStatus) && <tr>
                    <th>Suspected Status</th>
                    <td><em style={{ color: meaningColors(suspectedStatus || "") }}>{meanings(suspectedStatus || "")}</em></td>
                </tr>}
                {Boolean(suspendedStatus) && <tr>
                    <th>User Status</th>
                    <td><em style={{ color: meaningColors(suspendedStatus || "") }}>{meanings(suspendedStatus || "")}</em></td>
                </tr>}
                {Boolean(reportStatus) && <tr>
                    <th>Report Status</th>
                    <td><em style={{ color: meaningColors(reportStatus || "") }}>{reportStatus}</em></td>
                </tr>}
            </table>
            <fieldset>
                <legend>Remark</legend>
                <p>{remark}</p>
            </fieldset>
        </Container>
    )
}

const Container = styled.div`
    table {
        width: -webkit-fill-available;
        text-align: left;
        border: 1px solid #ebebeb;
        border-collapse: collapse;
        border-radius: 6px;

        td,th {
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #334d6e;
            mix-blend-mode: normal;
            padding: 8px 10px;
            border: 1px solid #ebebeb;
        }
    }

    fieldset {
        margin-top: 32px;
        border: 1px solid #ebebeb;
        border-radius: 6px;
        font-family: Mulish;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #334d6e;
        mix-blend-mode: normal;
        legend {
            font-weight: bold;
        }
        p{
            margin: 0;
            padding: 0;
        }
    }
`;
