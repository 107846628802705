import React, { FC, Fragment, useCallback, useState } from "react";
import { useParams } from "react-router";
import styled from "@emotion/styled";
import { HiddenStatus, SuspectedStatus, SuspendedStatus, GetReportActionStatusDocument, usePerformActionMutation, ReportStatus, useGetReportActionStatusQuery, Maybe } from "generated/graphql";
import { isEmpty } from "lodash-es";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-hot-toast";
import { DisplayStatus } from "./DisplayStatus";
import { CircularProgress } from "@mui/material";

export const ReportAction: FC = () => {
    const confirm = useConfirm();
    const { reportId: siteReportId } = useParams<{ reportId: string }>();
    const [remark, setRemark] = useState<string>('')
    const [hiddenStatus, setHiddenStatus] = useState<Maybe<HiddenStatus> | undefined>(undefined);
    const [reportStatus, setReportStatus] = useState<Maybe<ReportStatus> | undefined>(undefined);
    const [suspendedStatus, setSuspendedStatus] = useState<Maybe<SuspendedStatus> | undefined>(undefined);
    const [suspectedStatus, setSuspectedStatus] = useState<Maybe<SuspectedStatus> | undefined>(undefined);
    const { loading } = useGetReportActionStatusQuery({
        variables: {
            siteReportId: `${siteReportId}`,
        },
        onCompleted: (data) => {
            if (data?.getReportActionStatus) {
                const { HiddenStatus, ReportStatus, SuspendedStatus, SuspectedStatus } = data.getReportActionStatus;
                setHiddenStatus(HiddenStatus);
                setReportStatus(ReportStatus);
                setSuspendedStatus(SuspendedStatus);
                setSuspectedStatus(SuspectedStatus);
            }
        }
    })
    const [reportAction] = usePerformActionMutation({
        refetchQueries: [
            {
                query: GetReportActionStatusDocument, variables: {
                    siteReportId
                }
            }
        ]
    });

    const handleReportAction = useCallback(() => {
        confirm({
            title: 'Report Actions',
            confirmationText: 'Submit',
            content: <DisplayStatus suspectedStatus={suspectedStatus} remark={remark} hiddenStatus={hiddenStatus} reportStatus={reportStatus} suspendedStatus={suspendedStatus} />
        }).then(() => {
            if (hiddenStatus && reportStatus && suspectedStatus && suspendedStatus) {
                reportAction({
                    variables: {
                        data: {
                            ReportId: `${siteReportId}`,
                            HiddenStatus: hiddenStatus,
                            ReportStatus: reportStatus,
                            SuspectedStatus: suspectedStatus,
                            SuspendedStatus: suspendedStatus,
                            remark: remark
                        }
                    }
                }).then(() => {
                    setRemark('');
                    toast.success("Report action sumitted successfully!")
                }).catch(() => {
                    toast.error("Something went wrong!")
                })
            }
        })
    }, [siteReportId, remark, hiddenStatus, reportStatus, suspendedStatus, reportAction, confirm, suspectedStatus, setRemark])

    if (loading) return <CircularProgress />
    return (
        <Fragment>
            <Title>Actions</Title>
            <StatusContainer>
                <Status>
                    Visible Status:
                    <select value={hiddenStatus || ""} onChange={({ target }) => setHiddenStatus(target.value as any)}>
                        <option value={HiddenStatus.IsHidden} style={{ color: "#c3c3c3" }}>Hidden</option>
                        <option value={HiddenStatus.IsVisible} style={{ color: "#000" }}>Visible</option>
                    </select>
                </Status>
            </StatusContainer>
            <StatusContainer>
                <Status>
                    Suspected Status:
                    <select value={suspectedStatus || ""} onChange={({ target }) => setSuspectedStatus(target.value as any)}>
                        <option value={SuspectedStatus.IsSuspected} style={{ color: "red" }}>Suspected</option>
                        <option value={SuspectedStatus.IsUnsuspected} style={{ color: "#000" }}>Not Suspected</option>
                    </select>
                </Status>
            </StatusContainer>
            <StatusContainer>
                <Status>
                    User Status:
                    <select value={suspendedStatus || ""} onChange={({ target }) => setSuspendedStatus(target.value as any)}>
                        <option value={SuspendedStatus.IsSuspended} style={{ color: "red" }}>Suspended</option>
                        <option value={SuspendedStatus.IsNotSuspended} style={{ color: "#000" }}>Not Suspended</option>
                    </select>
                </Status>
            </StatusContainer>
            <Title>Your Remark</Title>
            <ContainerBorder>
                <textarea
                    placeholder="Your remarks for the report goes here"
                    onChange={({ target }) => setRemark(target.value)}
                    value={remark}
                ></textarea>
                <ActionContainer>
                    <Status>
                        Report Status:
                        <select value={reportStatus || ""} onChange={({ target }) => setReportStatus(target.value as any)}>
                            <option value={ReportStatus.Resolved}>Resolved</option>
                            <option value={ReportStatus.Rejected}>Rejected</option>
                            <option value={ReportStatus.Pending}>Pending</option>
                        </select>
                    </Status>
                    <button onClick={handleReportAction} disabled={isEmpty(remark)}>Save</button>
                </ActionContainer>
            </ContainerBorder>
        </Fragment>
    );
};

const ContainerBorder = styled.div`
  background: #FFFFFF;
  border: 1px solid #7B7B7B;
  border-radius: 8px;
  margin: 10px 0;
  overflow: hidden;
  textarea {
    border: 0;
    flex: 1;
    outline: 0;
    width: 100%;
    padding: 10px;
    resize: none;
    height: 200px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.65);
  }
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #EBEBEB;
    border-radius: 0px 0px 8px 8px;
    padding: 5px;
    button {
        background: #3751FF;
        box-shadow: 0px 2px 6px rgba(9, 142, 223, 0.3);
        border-radius: 1px;
        padding: 2px 16px;
        border: 0;
        color: #fff;
        cursor: pointer;
    }
    button[disabled], button:disabled {
        background-color: #c3c3c3;
        cursor: not-allowed;
        pointer-events: none;
    }
`;
// const Grid = styled.div`
//   padding: 10px;
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-column-gap: 24px;
// `;
// const GridItem = styled.div``;

const Title = styled.div<{ right?: boolean }>`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
  ${({ right }) =>
        right &&
        `
        text-align: right;
    `}
`;

// const ProgressContainer = styled.div`
//   padding: 20px 0;
//   ul {
//     margin: 0 !important;
//     padding-bottom: 10px;
//   }
// `;

const StatusContainer = styled.div`
    flex-direction: row;
    display: flex;
    padding: 10px 0;
    margin-left: 10px;
`;


const Status = styled.div`
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #6A707E;

    select {
        border:0;
        margin-left: 5px;
        color: rgb(29, 161, 243);
    }
`;