import React, { Fragment } from "react";
import AddEvent from "./AddEvent";
import Events from "./Events";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";

const Event = () => {
  return (
    <Fragment>
      <AddEvent />
      <Events />
    </Fragment>
  );
};

export default withAdminRoleRequired(Event, {
  roles: keyRoleMap["Events"],
});
