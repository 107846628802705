import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useGetReportCountQuery } from "generated/graphql";
import { ArrowOutward } from "@mui/icons-material";

interface CardProps {
  title: string;
  route: string;
  timePeriod: any;
}

const ReportsCard: React.FC<CardProps> = ({ title, route, timePeriod }) => {
  const { data, loading } = useGetReportCountQuery({
    variables: {
      startDate: timePeriod.startDate,
      endDate: timePeriod.endDate,
    },
  });
  console.log(data);

  route = `${route}?startDate=${timePeriod.startDate}&endDate=${timePeriod.endDate}&type=All&reportedByType=All&reportStatus=All&page=1`;
  return (
    <Card
      sx={{
        maxWidth: 600,
        height: "fit-content",
        minWidth: 200,
        "@media (max-width:1600px)": {
          minWidth: 185,
          width: "100%",
        },
        "@media (max-width:500px)": {
          maxWidth: 289,
          width: "100%",
        },
      }}
    >
      <CardContent>
        <Typography
          noWrap
          gutterBottom
          variant="h5"
          pt={2}
          pl={1}
          sx={{
            "@media (max-width:1600px)": {},
          }}
        >
          {title}
          <Link to={route}>
            <Tooltip title="MIS" sx={{ fontSize: "1rem", float: "right" }}>
              <IconButton>
                <ArrowOutward sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Link>
        </Typography>
        <>
          <Typography
            gutterBottom
            noWrap
            variant="h6"
            component="div"
            p={1}
            color="text.secondary"
            sx={{
              fontSize: "0.9rem",
              cursor: "pointer",
              "@media (max-width:1600px)": {},
            }}
          >
            {"Pending"}
            <span style={{ float: "right", paddingLeft: "15px" }}>
              <>
                {data?.getReportCount?.siteReportData?.pending}
                {loading ? (
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: 0.7 }}
                  >
                    <CircularProgress size={16} />
                  </Stack>
                ) : null}
              </>
            </span>
          </Typography>

          <Typography
            gutterBottom
            noWrap
            variant="h6"
            component="div"
            p={1}
            color="text.secondary"
            sx={{
              fontSize: "0.9rem",
              cursor: "pointer",
              "@media (max-width:1600px)": {},
            }}
          >
            {"Rejected"}
            <span style={{ float: "right", paddingLeft: "15px" }}>
              <>
                {data?.getReportCount?.siteReportData?.rejected}
                {loading ? (
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: 0.7 }}
                  >
                    <CircularProgress size={16} />
                  </Stack>
                ) : null}
              </>
            </span>
          </Typography>

          <Typography
            gutterBottom
            noWrap
            variant="h6"
            component="div"
            p={1}
            color="text.secondary"
            sx={{
              fontSize: "0.9rem",
              cursor: "pointer",
              "@media (max-width:1600px)": {},
            }}
          >
            {"Resolved"}
            <span style={{ float: "right", paddingLeft: "15px" }}>
              <>
                {data?.getReportCount?.siteReportData?.resolved}
                {loading ? (
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: 0.7 }}
                  >
                    <CircularProgress size={16} />
                  </Stack>
                ) : null}
              </>
            </span>
          </Typography>
        </>
        {/* ) : loading ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ margin:1 }}
            >
              <CircularProgress size={24} />
            </Stack>
          ) : 
          (
            isEmpty(data?.getTotalMis?.engagements) && <NoDataFound />
          )
        } */}

        <Box></Box>
        <Box></Box>
      </CardContent>
    </Card>
  );
};

export default ReportsCard;
