import React, { FC, Fragment } from "react";
import { Formik } from "formik";
import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  IconButton,
  DialogTitle,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  FormControl,
} from "@mui/material";
import {
  FeatureWithPermissionDataOutput,
  useUpdateFeatureMappingMutation,
} from "generated/graphql";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Close } from "@mui/icons-material";
import toast from "react-hot-toast";
import omit from "lodash-es/omit";
import { isEmpty } from "lodash-es";

interface EditFeatureProps {
  open: boolean;
  feature: FeatureWithPermissionDataOutput | null | undefined;
  handleCloseModal: () => void;
}

const EditFeature: FC<EditFeatureProps> = ({
  open,
  feature,
  handleCloseModal,
}) => {
  const fullScreen = useMediaQuery("(max-width:540px)");
  return (
    <CustomDialog
      open={open}
      onClose={handleCloseModal}
      fullScreen={fullScreen}
      disableEscapeKeyDown
    >
      <CreatePromotionForm
        feature={feature}
        handleCloseModal={handleCloseModal}
      />
    </CustomDialog>
  );
};

export default EditFeature;

const CreatePromotionForm: FC<
  Pick<EditFeatureProps, "feature" | "handleCloseModal">
> = ({ feature, handleCloseModal }) => {
  const [updateFeatureMapping] = useUpdateFeatureMappingMutation();

  return (
    <Fragment>
      <Formik
        initialValues={{
          featureData: {
            featureName: feature?.featureName,
            featureAlias: feature?.featureAlias,
          },
          platformPermissionData: omit(feature?.platformPermissionData, [
            "__typename",
          ]),
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log("values", values);
          updateFeatureMapping({
            variables: {
              featureId: feature?.featureId as string,
              data: { ...values },
            },
            onCompleted: (data) => {
              if (data?.updateFeatureMapping) {
                toast.success("Successfully updated!");
                handleCloseModal();
              }
            },
            onError: (error) => {
              toast.error(error.message);
            },
          }).finally(() => {
            setSubmitting(false);
          });
        }}
        enableReinitialize
        validate={(values) => {
          const featureData: Record<string, string> = {};
          if (!values.featureData.featureName)
            featureData.featureName = "Required";
          // if (!values.featureData.featureAlias)
          //   featureData.featureAlias = "Required";
          return isEmpty(featureData) ? {} : { featureData };
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          handleBlur,
          errors,
          submitForm,
        }) => (
          <Fragment>
            <CustomDialogTitle>
              Edit Feature
              <CustomIconButton onClick={handleCloseModal}>
                <Close />
              </CustomIconButton>
            </CustomDialogTitle>
            <DialogContent>
              <TextField
                label="Feature Name"
                value={values.featureData.featureName}
                name="featureData.featureName"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors?.featureData?.featureName)}
                helperText={errors?.featureData?.featureName}
                fullWidth
              />
              <TextField
                label="Feature Alias"
                value={values.featureData.featureAlias}
                name="featureData.featureAlias"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors?.featureData?.featureAlias)}
                helperText={errors?.featureData?.featureAlias}
                fullWidth
              />
              <PermissionHeader>Platform Permissions</PermissionHeader>
              <Box sx={{ display: "flex" }}>
                <FormControl
                  sx={{ m: 1 }}
                  component="fieldset"
                  variant="standard"
                  style={{ paddingLeft: 12 }}
                >
                  <GridContainer>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canPost
                            )}
                            name="platformPermissionData.canPost"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Post"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canEngage
                            )}
                            name="platformPermissionData.canEngage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Engage"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canViewPost
                            )}
                            name="platformPermissionData.canViewPost"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Post View"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canFollow
                            )}
                            name="platformPermissionData.canFollow"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Follow"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canBeFollowed
                            )}
                            name="platformPermissionData.canBeFollowed"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Get Followed"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canBlock
                            )}
                            name="platformPermissionData.canBlock"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Block"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canReport
                            )}
                            name="platformPermissionData.canReport"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Report"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData?.canSeeWatchList
                            )}
                            name="platformPermissionData.canSeeWatchList"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="See Watchlist"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData
                                ?.canSeeOthersFeedPosts
                            )}
                            name="platformPermissionData.canSeeOthersFeedPosts"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="See Others Posts"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData
                                ?.canSeeMarketingPage
                            )}
                            name="platformPermissionData.canSeeMarketingPage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="See Marketing Page"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData
                                ?.canAutoJoinBlueTick
                            )}
                            name="platformPermissionData.canAutoJoinBlueTick"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Auto Join Blue Tick"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData
                                ?.canAutoJoinGreenTick
                            )}
                            name="platformPermissionData.canAutoJoinGreenTick"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Auto Join Green Tick"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData
                                ?.canAutoJoinYellowTick
                            )}
                            name="platformPermissionData.canAutoJoinYellowTick"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Auto Join Yellow Tick"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData
                                ?.canFollowPost
                            )}
                            name="platformPermissionData.canFollowPost"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Follow Post"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData
                                ?.canShowNps
                            )}
                            name="platformPermissionData.canShowNps"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Show NPS"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData
                                ?.canSeeClubPromotion
                            )}
                            name="platformPermissionData.canSeeClubPromotion"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="See Club Promotion"
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              values?.platformPermissionData
                                ?.canSeePremiumPromotion
                            )}
                            name="platformPermissionData.canSeePremiumPromotion"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="See Premium Promotion"
                      />
                    </Grid>
                  </GridContainer>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isSubmitting}
                onClick={submitForm}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 580px;
  }
  .MuiTextField-root {
    margin-top: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 12px 8px;
    .MuiButton-root {
      max-width: 200px;
      width: 100%;
    }
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
`;

const CustomIconButton = styled(IconButton)`
  position: absolute;
  right: 16px;
`;

const CustomDialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
`;

const PermissionHeader = styled(Typography)`
  font-size: 14px !important;
  padding-top: 10px;
`;

const GridContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;

  @media (min-width: 440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
