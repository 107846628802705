import React, { FC, memo, useState } from "react";
import { Link } from "react-router-dom";
import reactStringReplace from "pages/feedbacks/reactStringReplace";
import styled from "@emotion/styled";
// import { COLORS } from "../../assets/colors";
// import Emoji, { Emojione } from "react-emoji-render";

const COLORS = {
  pureWhite: "#FFFFFF", // card color
  white: "#f7f9fa",
  black: "#13161a",
  green: "#3DCC65", // Stocktag color
  blue: "#031B4E", // Text color | Bottom bar selected ICON color
  lightBlue: "#1da1f3", // Button follow following color | #tag color
  mutedBlue: "#e8f3ff",
  danger: "#dd4b39",
  warning: "#FBD71F", // watchlist badge color
  muted: "#c9ccd1",
  mutedLight: "#e6e8ea", // Background color app
  mutedDark: "#5c7082", // small size text color | ICON color
};

interface Props {
  text: string;
  seeMore?: boolean;
}
const Br = () => <br />;
const splitN = (text: string) => {
  const splitArr = text?.split(new RegExp("\r?\n", "g"))||[];
  return splitArr.reduce<Array<any>>(function (accumulator, currentValue) {
    accumulator.push(currentValue);
    accumulator.push(<Br />);
    return accumulator;
  }, []);
};

const TextPostRender: FC<Props> = ({ text, seeMore = false }) => {

  const [less, setLess] = useState<boolean>(seeMore);

  // console.log({ text });
  // console.log({ arr: splitN(text) })
  // text = text.replace(new RegExp('\r?\n', 'g'), '<br/>');
  // console.log({ text });
  // const [less, setLess] = useState<boolean>(seeMore);
  // text = 'jjhdhjd↵kjkjkds↵]klklklds↵ikjuds↵ioiods↵popoids↵]poopds↵poopds↵poopds↵]poopds↵]';
  let replacedText: any = splitN(text as string);
  // Match URLs
  // replacedText = reactStringReplace(
  //   [text],
  //   /↵/gi,
  //   (match: string, i: number) => {
  //     return (
  //       <>
  //         <br key={match + i} />
  //         {match}
  //       </>
  //     );
  //   }
  // );

  replacedText = reactStringReplace(
    replacedText,
    /(https?:\/\/\S+)/gi,
    (match: string, i: number) => (
      <MentionLink
        key={match + i}
        href={match}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e: any) => {
          e.preventDefault();
          window.open(match, "_blank");
        }}
      >
        {match}
      </MentionLink>
    )
  );

  // Match users
  replacedText = reactStringReplace(
    replacedText,
    /\[(@[^:]+[^\]]+)\]/gi,
    (match: string, i: number) => {
      const [display, param] = match.split(":");
      const username = param.split("/").shift();
      return (
        <MentionUserLink key={match + i} to={`/profile/${username}`}>
          {display}
        </MentionUserLink>
      );
    }
  );

  // Match stocktag
  replacedText = reactStringReplace(
    replacedText,
    /\[(\$[^:]+[^\]]+)\]/gi,
    (match: string, i: number) => {
      const [display, param] = match.split(":");
      const paramsLower = param.toLocaleLowerCase();
      let to = `/stocktag/${paramsLower}`;

      if (
        paramsLower.includes("stock/") ||
        paramsLower.includes("sector/") ||
        paramsLower.includes("industry/")
      ) {
        to = `/${paramsLower}`;
      }

      console.log(paramsLower, to);
      return (
        <MentionStockTagLink key={match + i} to={to}>
          {display}
        </MentionStockTagLink>
      );
    }
  );

  // Match hashtags
  replacedText = reactStringReplace(
    replacedText,
    /\[(#[^:]+[^\]]+)\]/gi,
    (match: string, i: number) => {
      const [display, param] = match.split(":");
      return (
        <MentionHashTagLink key={match + i} to={`/hashtag/${param}`}>
          {display}
        </MentionHashTagLink>
      );
    }
  );

  // Match new Line
  // replacedText = reactStringReplace(
  //   replacedText,
  //   /\n\n/gi,
  //   (match: string, i: number) => {
  //     console.log("\n\n");
  //     console.log({ br: match + i });
  //     return (
  //       <>
  //         <br key={match + i} />
  //         <br key={match + i + 1} />
  //         {match}
  //       </>
  //     );
  //   }
  // );
  // Match new Line
  // replacedText = reactStringReplace(
  //   replacedText,
  //   /\n/gi,
  //   (match: string, i: number) => {
  //     console.log("\n");
  //     console.log({ br: match + i });
  //     return (
  //       <>
  //         <br key={match + i} />
  //         {match}
  //       </>
  //     );
  //   }
  // );

  const handleSeeMore = (e: any) => {
    e.preventDefault();
    setLess(false);
  };
  // console.log(replacedText);
  // const test = replacedText.map((t) => {
  //   // console.log("Type of -> ",typeof t)
  //   if(typeof t === "string"){
  //     return <Emojione  text = {t}></Emojione>
  //   }
  //   return t
  // }
  //   )
  //   console.log(test)
  return (
    <>
      <Div>{replacedText}</Div>
      {text?.length > 100 && less && (
        <span onClick={handleSeeMore}>see more</span>
      )}
    </>
  );
};

TextPostRender.defaultProps = {
  seeMore: false,
};

export default memo(TextPostRender);

const Div = styled.div`
  white-space: pre-wrap;
  line-height: 1.25;
  margin-bottom: 0.5rem;
  overflow-wrap: break-word;
  font-size: 16px;
  line-height: 22px;
  color: #131722;
  font-family: "Trebuchet MS", sans-serif;

  @media (max-width: 480px) {
    font-size: 15px;
  }
`;


const MentionLink = styled.a`
  color: ${COLORS.lightBlue};
  text-decoration: none;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const MentionUserLink = styled(Link)`
  text-decoration: none;
  color: rgb(27, 149, 224);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionHashTagLink = styled(Link)`
  text-decoration: none;
  color: rgb(27, 149, 224);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionStockTagLink = styled(Link)`
  text-decoration: none;
  color: #1f4386;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;
// const ReadMore = ({ children }) => {
//   const text = children;
//   const [isReadMore, setIsReadMore] = useState(true);
//   const toggleReadMore = () => {
//     setIsReadMore(!isReadMore);
//   };
//   return (
//     <div style={{ whiteSpace: "pre-wrap" }}>
//       {isReadMore ? text.slice(0, 150) : text}
//       <span onClick={toggleReadMore} className="read-or-hide">
//         {isReadMore ? "...read more" : " show less"}
//       </span>
//     </div>
//   );
// };
