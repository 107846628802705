import * as Yup from "yup";

const MAX_FILE_SIZE = 1024 * 1024 * 2; //2MB

const validFileExtensions: Record<string, Array<string>> = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

function isValidFileType(fileName: string, fileType: string) {
    return Boolean(fileName && validFileExtensions[fileType].indexOf(fileName?.split('.')?.pop() as string) > -1);
}

export const validationSchema = Yup.object().shape({
    text: Yup
        .string()
        .required('Required')
        .max(50, 'Max allowed length is 50 characters'),
    image: Yup
        .mixed()
        .required("Required")
        .test("is-valid-type", "Not a valid image type",
            value => isValidFileType((value as any).name.toLowerCase(), "image"))
        .test("is-valid-size", "Max allowed size is 100KB",
            value => value && (value as any).size <= MAX_FILE_SIZE),
    startTime: Yup
        .date()
        .typeError("Required")
        .required("Required"),
    endTime: Yup
        .date()
        .typeError("Required")
        .required("Required"),
    ctaButtonText: Yup
        .string()
        .required("Required")
        .max(15, 'Max allowed length is 15 characters'),
    ctaButtonUrl: Yup
        .string()
        .required("Required")
        .url("Invalid"),
    description: Yup
        .string()
        .max(200, 'Max allowed length is 200 characters')
})

export const editEventValidationSchema = Yup.object().shape({
    text: Yup
        .string()
        .required('Required')
        .max(50, 'Max allowed length is 50 characters'),
    image: Yup
        .mixed()
        .test("is-valid-type", "Not a valid image type",
            value => value ? isValidFileType((value as any)?.name?.toLowerCase(), "image") : true)
        .test("is-valid-size", "Max allowed size is 2MB",
            value => value ? (value as any)?.size <= MAX_FILE_SIZE : true),
    startTime: Yup
        .date()
        .typeError("Required")
        .required("Required"),
    endTime: Yup
        .date()
        .typeError("Required")
        .required("Required"),
    ctaButtonText: Yup
        .string()
        .required("Required")
        .max(15, 'Max allowed length is 15 characters'),
    ctaButtonUrl: Yup
        .string()
        .required("Required")
        .url("Invalid"),
    description: Yup
        .string()
        .max(200, 'Max allowed length is 200 characters')
})