import { Avatar } from "@mui/material";
import React, { FC } from "react";
import styled from "@emotion/styled";
import { textPost } from "../suspected/textPost";
import dayjs from "dayjs";

export const ContentDisplay: FC<{
  post: any;
}> = ({ post }) => {
  return <ContainerBorder>
    <Header>
      <Avatar src={post?.User?.avatar?.LowResUri} />
      <NameContainer>
        <Name>{`${post?.User?.firstName} ${post?.User?.lastName}`}</Name>
        <Flex>
          <Username>@{post?.User?.username}</Username>
          <Time>{dayjs(post?.createdAt).format("DD-MMM-YYYY")}</Time>

        </Flex>
      </NameContainer>
    </Header>
    <Text>{textPost(post?.text || "")}</Text>

    <Image>
      <img src={post?.media[0]?.uri?.LowResUri} />
    </Image>
    {post?.comments?.map((c: any, key: number) => (
      <CommentContainer key={key}>
        <Header>
          <Avatar src={c?.User?.avatar?.LowResUri} />
          <NameContainer>
            <Name>{`${c?.User?.firstName} ${c?.User?.lastName}`}</Name>
            <Flex>
              <Username>@{c?.User?.username}</Username>
              <Time>{dayjs(c?.createdAt).format("DD-MMM-YYYY")}</Time>
            </Flex>
          </NameContainer>
        </Header>
        <Text>{textPost(c?.text || "")}</Text>
        <Image>
          <img src={c?.media[0]?.uri?.LowResUri} />
        </Image>
        {c?.replies?.map((r: any, key: number) => (
          <CommentContainer key={key}>
            <Header>
              <Avatar src={r?.User?.avatar?.LowResUri} />
              <NameContainer>
                <Name>{`${r?.User?.firstName} ${r?.User?.lastName}`}</Name>
                <Flex>
                  <Username>@{r?.User?.username}</Username>
                  <Time>{dayjs(r?.createdAt).format("DD-MMM-YYYY")}</Time>
                </Flex>
              </NameContainer>
            </Header>
            <Text>{textPost(r?.text || "")}</Text>
            <Image>
              <img src={r?.media[0]?.uri?.LowResUri} />
            </Image>

          </CommentContainer>
        ))}
      </CommentContainer>
    ))}
  </ContainerBorder>;
};



const ContainerBorder = styled.div`
  background: #FFFFFF;
  border: 1px solid #7B7B7B;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
`;

const CommentContainer = styled.div`
background: #FFFFFF;
  border: 1px solid #7B7B7B;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  margin-left: 30px;
`;

const Header = styled.div`
  display: flex;
`;
const NameContainer = styled.div`
  margin-left: 10px;
`;
const Name = styled.div`
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
`;
const Username = styled.span`
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #9597A1;
`;
const Time = styled.time`
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  margin-left:5px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #9597A1;
`;

const Text = styled.div`
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 151.63%;
  color: #000000;
  margin: 10px 0;
`;

const Image = styled.div`
height: auto;
    width: 100%;
    cursor: pointer;
`
const Flex = styled.div`
  display: flex;
`;