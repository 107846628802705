import React, { Fragment, useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  CircularProgress,
  Stack
} from "@mui/material";
import {
  ContentMadeByUser,
  InputMaybe,
  Maybe,
  TickTypeForContent,
  User,
  useGetUserCreatorTypeOfContentListQuery,
} from "generated/graphql";
import Tick from "components/Tick";
import { Edit } from "@mui/icons-material";
import TickUsersFilter from "./TickUsersFilter";
import { useSearchParams } from "react-router-dom";
import { FormikHelpers } from "formik";
import NoDataFound from "components/NoDataFound";
import { isEmpty } from "lodash";
import MarkCreatorType from "./MarkCreatorType";
import styled from "@emotion/styled";
// import EditGreenTickModal from "./EditGreenTickModal";
interface Values {
  typeOfContentFilter: ContentMadeByUser;
  searchText: string;
  tickFilter: any;
}
export type OnSubmit = (
  values: Values,
  formikHelpers: FormikHelpers<Values>
) => void | Promise<any>;

function pascalCaseToTitleCase(sentence: string): string {
  // Add a space before each capital letter
  sentence = sentence.replace(/([A-Z])/g, ' $1');
  // Convert the first character to uppercase
  sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);
  return sentence;
}

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    typeOfContentFilter: [],
    searchText: "",
    tickFilter: [],
  });

  const typeOfContentFilter:
    | InputMaybe<
      InputMaybe<ContentMadeByUser> | InputMaybe<ContentMadeByUser>[]
    >
    | undefined = (searchParams?.get("typeOfContentFilter")?.split(",") ||
      []) as InputMaybe<ContentMadeByUser>[];
  const tickFilter:
    | InputMaybe<
      InputMaybe<TickTypeForContent> | InputMaybe<TickTypeForContent>[]
    >
    | undefined = (searchParams?.get("tickFilter")?.split(",") ||
      []) as InputMaybe<TickTypeForContent>[];
  const searchText: InputMaybe<string> | undefined =
    searchParams?.get("searchText") || "";

  const { data, loading } = useGetUserCreatorTypeOfContentListQuery({
    variables: {
      typeOfContentFilter,
      searchText,
      tickFilter,
    },
  });

  console.log(data);
  const [open, setOpen] = useState<boolean>(false);
  // const [openGreenTickEditModalForm, setOpenGreenTickEditModalForm] =
  //   useState(false);
  // const [openGreenTickEditModal, setGreenTickEditModal] =
  //   useState<boolean>(false);
  const [user, setUser] = useState<Maybe<User> | undefined>();
  // const handleGreenTickOpenModal = () => setGreenTickEditModal(true);
  // const handleGreenTickCloseModal = () => setGreenTickEditModal(false);

  // const handleOpen = () => (user: Maybe<User> | undefined) => {
  //   e.preventDefault();
  //   setOpen(true);
  //   setUser(user);
  // }

  const handleOpen = (user: Maybe<User> | undefined) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setOpen(true);
    setUser(user);
  }

  const handleClose = () => {
    setOpen(false);
    setUser(undefined);
  }

  return (
    <Fragment>
      <TickUsersFilter
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <Typography p={2}>
        Total Search Results : {data?.getUserCreatorTypeOfContentList?.total}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>Name</CustomTableCell>
              <CustomTableCell>Username</CustomTableCell>
              <CustomTableCell align="center">Tick Type</CustomTableCell>
              <CustomTableCell>Type of Content Creator</CustomTableCell>
              <CustomTableCell align="center">Actions</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.getUserCreatorTypeOfContentList?.UserObjWithTypeOfContent?.map(
              (user) => {
                return (
                  <>
                    <TableRow key={user?.id}>
                      <TableCell>
                        {user?.firstName} {user?.lastName}
                      </TableCell>
                      <TableCell>@{user?.username}</TableCell>
                      <TableCell align="center">
                        <Tick {...user} />
                      </TableCell>
                      <TableCell>{user?.typeOfContent?.map(x => pascalCaseToTitleCase(x!))?.join(", ")}</TableCell>
                      <TableCell align="center">
                        <IconButton onClick={handleOpen(user)}>
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            {isEmpty(
              data?.getUserCreatorTypeOfContentList?.UserObjWithTypeOfContent
            ) && <NoDataFound />}
          </Stack>
        </Fragment>
      )}
      <MarkCreatorType
        open={open}
        user={user}
        handleClose={handleClose}
      />
      {/* <Dialog open={openGreenTickEditModal} onClose={handleGreenTickCloseModal}>
        <EditGreenTickModal
          onClose={() => setGreenTickEditModal(false)}
          user={user as any}
        />
      </Dialog> */}
    </Fragment>
  );
};

export default Users;

const CustomTableCell = styled(TableCell)`
  min-width: 200px;
`;

// const Modal = styled(Dialog)`
//   .modalTitle {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }
//   .MuiPaper-root {
//     overflow-y: initial;
//   }
//   .MuiDialog-container {
//     align-items: flex-start;
//   }
//   .MuiDialog-paper {
//     width: 100%;
//     max-width: 500px;
//     min-height: 360px;
//   }
//   .MuiDialogTitle-root {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     border-bottom: 1px solid rgba(0, 0, 0, 0.2);
//     padding: 0 16px;
//     height: 50px;
//     .MuiIconButton-root {
//       padding: 8px;
//     }
//   }
//   .MuiDialogContent-root {
//     padding: 8px 16px;
//     .MuiChip-outlined {
//       margin: auto 5px;
//       height: 20px;
//     }
//   }
//   @media (max-width: 540px) {
//     .MuiPaper-root {
//       margin: 0;
//       max-height: 100%;
//       border-radius: 0;
//       height: 100%;
//     }
//     .MuiDialogTitle-root {
//       justify-content: flex-start;
//       align-items: center;
//     }
//     .MuiDialogTitle-root {
//       padding: 8px;
//       margin: 0;
//     }
//     .MuiTypography-root {
//       font-size: 16px;
//     }
//     .back-btn {
//       display: block;
//       margin-right: 8px;
//     }
//     .close-btn {
//       display: none;
//     }
//   }
//   @media (min-width: 481px) {
//     .MuiDialogContent-root {
//       &::-webkit-scrollbar {
//         width: 8px;
//       }

//       &::-webkit-scrollbar-track {
//         box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
//         border-radius: 10px;
//       }

//       &::-webkit-scrollbar-thumb {
//         border-radius: 8px;
//         box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
//       }
//     }
//   }
// `;
