import React, { FC } from 'react';
import { Box, IconButton, Typography, styled } from '@mui/material';
import { Delete, Add } from "@mui/icons-material";
import { Reference } from "@apollo/client";
import { UserHashtagContentType, InputMaybe, useMarkHashtagContentTypeMutation, useUnmarkHashtagContentTypeMutation } from 'generated/graphql';

interface HashtagProps {
    hashtag: string | null | undefined;
    isMarked: boolean | undefined;
    category: InputMaybe<UserHashtagContentType> | undefined;
}

const Hashtag: FC<HashtagProps> = ({ hashtag, isMarked, category }) => {
    const [markHashTag] = useMarkHashtagContentTypeMutation();
    const [unmarkHashtag] = useUnmarkHashtagContentTypeMutation();

    const handleMarkHashtag = () => {
        markHashTag({
            variables: {
                hashtagName: hashtag,
                typeOfContentMadeByHashtag: category
            },
            update: (cache, { data }) => {
                if (data?.markHashtagContentType) {
                    cache.modify({
                        fields: {
                            getHashtagListForAdminBasedOnContentFilter: (hashtagRefs, { storeFieldName }) => {
                                if (storeFieldName === `getHashtagListForAdminBasedOnContentFilter({"typeOfContentMadeByHashtag":"${category}"})`) {
                                    return [...hashtagRefs, data?.markHashtagContentType]
                                }
                                return hashtagRefs;
                            }
                        }
                    })
                }
            }
        })
    }

    const handleUnmarkHashtag = () => {
        unmarkHashtag({
            variables: {
                hashtagName: hashtag,
                typeOfContentMadeByHashtag: category
            },
            update: (cache, { data }) => {
                if (data?.unmarkHashtagContentType) {
                    cache.modify({
                        fields: {
                            getHashtagListForAdminBasedOnContentFilter: (hashtagRefs, { storeFieldName, readField }) => {
                                if (storeFieldName === `getHashtagListForAdminBasedOnContentFilter({"typeOfContentMadeByHashtag":"${category}"})`) {
                                    console.log(storeFieldName)
                                    return hashtagRefs.filter((ref: Reference) => readField('hashtagName', ref) !== data?.unmarkHashtagContentType?.hashtagName);
                                }
                                return hashtagRefs;
                            }
                        }
                    })
                }
            }
        })
    }

    return (
        <Conatiner>
            <Typography>{hashtag}</Typography>
            {isMarked ?
                <IconButton onClick={handleUnmarkHashtag}>
                    <Delete fontSize="small" sx={{color:"#d32f2f"}}/>
                </IconButton>
                :
                <IconButton onClick={handleMarkHashtag}>
                    <Add fontSize="small" color='primary'/>
                </IconButton>
            }
        </Conatiner>
    )
}

export default Hashtag;

const Conatiner = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    padding: '4px 8px',
    border: '1px solid #dbdbdb',
    borderRadius:'3px'
})