import { To } from "react-router-dom";
import { Roles } from "constants/roles";

export type Key =
  | "Dashboard"
  | "Activities"
  | "Reports"
  | "MIS"
  | "Promotion"
  | "External Resource"
  | "Users"
  | "Clubs"
  | "CRON / Schedule Jobs"
  | "Push Notifications"
  | "NPS"
  | "Features"
  | "Events"
  | "Onboarding"
  | "Smart Feed"
  | "Product Tour"
  | "Badge System"
  | "AB Testing"
  | "Priority Profiles"
  | "Whats New";
export interface MenuItem {
  key: Key;
  text: string;
  path?: To;
  roles?: Roles[];
  children?: Array<Partial<MenuItem>>;
}

const menuItems: MenuItem[] = [
  {
    key: "Dashboard",
    text: "Dashboard",
    path: "/",
    roles: [
      Roles.SocialSupport,
      Roles.SocialModerator,
      Roles.SocialAdmin,
      Roles.SocialMIS,
      Roles.SocialTechnicalOperator,
    ],
  },
  {
    key: "Activities",
    text: "Activities",
    path: "/activities",
    roles: [Roles.SocialAdmin],
  },
  {
    key: "Reports",
    text: "Reports",
    roles: [Roles.SocialAdmin, Roles.SocialModerator, Roles.SocialSupport],
    children: [
      { text: "Report Summary", path: "/reports/summaries" },
      { text: "All Reports", path: "/reports/all" },
      { text: "Report Action History", path: "/reports/action-history" },
    ],
  },
  {
    key: "MIS",
    text: "MIS",
    roles: [Roles.SocialAdmin, Roles.SocialMIS],
    children: [
      { text: "Session Summary", path: "/mis/session-summary" },
      { text: "User Engagement Summary", path: "/mis/user-engagement-summary" },
      { text: "Daily Signup Summary", path: "/mis/daily-signup-summary" },
      { text: "Post Summary", path: "/mis/post-summary" },
      { text: "Comment Summary", path: "/mis/comment-summary" },
      { text: "Reply Summary", path: "/mis/reply-summary" },
      { text: "Poll Summary", path: "/mis/poll-summary" },
      { text: "Creators Summary", path: "/mis/creators-summary" },
      {
        text: "Power User",
        children: [
          {
            text: "Badges",
            path: "/mis/power-user/badges",
          },
          {
            text: "Feedbacks",
            path: "/mis/power-user/feedbacks",
          },
          {
            text: "Weekly Chart",
            path: "/mis/power-user/weekly-chart",
          },
          {
            text: "Weekly Progress",
            path: "/mis/power-user/weekly-progress",
          },
          {
            text: "Streak Missed Users",
            path: "/mis/power-user/streak-missed-users",
          },
        ],
      },
    ],
  },
  {
    key: "Promotion",
    text: "Promotion",
    path: "/promotion",
    roles: [Roles.SocialTechnicalOperator, Roles.SocialSupport],
  },
  {
    key: "External Resource",
    text: "External Resource",
    path: "/external-resource",
    roles: [Roles.SocialTechnicalOperator],
  },
  {
    key: "Users",
    text: "Users",
    roles: [Roles.SocialAdmin],
    children: [
      { text: "Search Users", path: "/users/search-users" },
      { text: "Blue Tick Users", path: "/users/blue-tick-users" },
      { text: "Green Tick Users", path: "/users/green-tick-users" },
    ],
  },
  {
    key: "Clubs",
    text: "Clubs",
    path: "/clubs",
    roles: [Roles.SocialTechnicalOperator],
  },
  {
    key: "CRON / Schedule Jobs",
    text: "CRON / Schedule Jobs",
    path: "/jobs/schedule-jobs",
    roles: [Roles.SocialTechnicalOperator],
  },
  {
    key: "Push Notifications",
    text: "Push Notifications",
    path: "/push-notifications",
    roles: [Roles.SocialAdmin, Roles.SocialSupport],
  },
  {
    key: "NPS",
    text: "NPS",
    path: "/nps",
    roles: [Roles.SocialAdmin, Roles.SocialSupport, Roles.SocialMIS],
  },
  {
    key: "Features",
    text: "Features",
    roles: [Roles.SocialTechnicalOperator],
    children: [
      { text: "Platform", path: "/features/platform" },
      { text: "Club", path: "/features/club" },
    ],
  },
  {
    key: "Events",
    text: "Events",
    path: "/events",
    roles: [Roles.SocialAdmin, Roles.SocialSupport],
  },
  {
    key: "Onboarding",
    text: "Onboarding",
    roles: [
      Roles.SocialSupport,
      Roles.SocialAdmin,
      Roles.SocialTechnicalOperator,
    ],
    children: [
      {
        text: "Hashtag Categorization",
        path: "/onboarding/hashtag-categorization",
      },
      {
        text: "Tick Users Categorization",
        path: "/onboarding/tick-users-categorization",
      },
    ],
  },
  {
    key: "Smart Feed",
    text: "Smart Feed",
    path: "/interstitials",
    roles: [Roles.SocialTechnicalOperator, Roles.SocialSupport],
  },
  {
    key: "Product Tour",
    text: "Product Tour",
    path: "/product-tour",
    roles: [Roles.SocialTechnicalOperator],
  },
  {
    key: "Badge System",
    text: "Badge System",
    path: "/badge-system",
    roles: [Roles.SocialTechnicalOperator],
  },
  {
    key: "AB Testing",
    text: "AB Testing",
    path: "/ab-testing",
    roles: [Roles.SocialTechnicalOperator],
  },
  {
    key: "Priority Profiles",
    text: "Priority Profiles",
    path: "/priority-profiles",
    roles: [Roles.SocialTechnicalOperator],
  },
  {
    key: "Whats New",
    text: "Whats New",
    path: "/whats-new",
    roles: [Roles.SocialTechnicalOperator],
  },
];

export default menuItems;

export const keyRoleMap = menuItems?.reduce(
  (a, c) => ({ ...a, [c.key]: c.roles }),
  {} as Record<Key, Array<Roles>>
);
