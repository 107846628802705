import styled from "@emotion/styled";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, Fragment, useState } from "react";
import UploadCreative from "components/UploadCreative";
import toast from "react-hot-toast";
import { Formik } from "formik";
import {
  GetPushNotificationLogAdminDocument,
  useAdminNotificationToTopicMutation,
} from "generated/graphql";
import Initial from "./components/Initial";
import Expanded from "./components/Expanded";
import { rest } from "lodash-es";
import ModalHeader from "./ModalHeader";
import { DateTimePicker } from "@mui/x-date-pickers";
import ClubDropdown from "components/dropdowns/ClubDropdown";
import dayjs from "dayjs";
import { uploadFiles } from "api/rest";

const CreatePushNotifications: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <Container>
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        Create Push Notifications
      </Button>
      <CustomDialog open={open} onClose={handleCloseModal}>
        <CreatePushNotificationsForm handleCloseModal={handleCloseModal} />
      </CustomDialog>
    </Container>
  );
};

export default CreatePushNotifications;

interface CreatePushNotificationsForm {
  handleCloseModal: () => void;
}

const CreatePushNotificationsForm: FC<CreatePushNotificationsForm> = ({
  handleCloseModal,
}) => {
  const [addNotification] = useAdminNotificationToTopicMutation({
    refetchQueries: [GetPushNotificationLogAdminDocument],
  });

  const [image, setImage] = useState<File | undefined>();
  const [previewShow, setPreviewShow] = useState(false);

  const imageSize = image?.size;
  let imgError: any;
  let errorValue: any;

  return (
    <Fragment>
      <Box>
        <Modal
          {...rest}
          open={open as any}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalHeader
            title="Push Notification"
            onClickCloseButton={handleCloseModal}
          />
          <Divider />

          <Formik
            initialValues={{
              link: "",
              message: "",
              analyticsLabel: "",
              body: "",
              clubId: "all",
              imageUrl: "",
              scheduleTime: null,
            }}
            validate={(values) => {
              const errors: Record<string, string> = {};
              if (!values.message) errors.message = "Title is required";
              if ((imageSize as number) > 10485760) {
                errors.image = "error";
                imgError = document.getElementById(
                  "imageError"
                ) as HTMLInputElement;
                imgError.value = "Image must be lesser than 10MB";
                errorValue = imgError.value;
              }
              if (!values.body) errors.body = "Body is required";
              if (
                (values.link.includes("http") ||
                  !values.link.startsWith("/")) &&
                values.link
              )
                errors.link = "Enter only routes , e.g.  /home , /explore ";

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const variables: any = {
                link: values.link || "/home",
                message: values.message,
                analyticsLabel: values.analyticsLabel,
                body: values.body,
                scheduleTime: values.scheduleTime,
              };

              if (values.clubId !== "all") {
                variables.clubId = values.clubId;
              }

              if (image) {
                const { data }: any = await uploadFiles([image as any]);
                variables.imageUrl = data[0]?.uploadfilename;
              }

              addNotification({
                variables,
              })
                .then(() => {
                  toast.success("Successfully created!");
                  handleCloseModal();
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleSubmit,
              isSubmitting,
              handleBlur,
              setFieldValue,
            }) => (
              <>
                <DialogContent>
                  <MainBox>
                    <Box>
                      <TextField
                        sx={{ marginBottom: "10px" }}
                        onChange={handleChange}
                        error={Boolean(errors?.message)}
                        helperText={errors.message}
                        name="message"
                        value={values.message}
                        label="Message"
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                      />
                      <TextField
                        sx={{ marginBottom: "10px" }}
                        error={Boolean(errors?.body)}
                        helperText={errors.body}
                        label="Body"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        name="body"
                        value={values.body}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        sx={{ marginBottom: "10px" }}
                        error={Boolean(errors?.analyticsLabel)}
                        helperText={errors.analyticsLabel}
                        label="Analytics Label"
                        fullWidth
                        variant="outlined"
                        name="analyticsLabel"
                        value={values.analyticsLabel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ClubDropdown
                        name="clubId"
                        value={values.clubId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Audience"
                      />
                      <Box sx={{ marginBottom: "10px" }}>
                        <UploadCreative
                          image={image as any}
                          setImage={setImage as any}
                        />
                        <p
                          id="imageError"
                          style={{
                            color: "red",
                            fontSize: "0.8em",
                            paddingLeft: "12px",
                          }}
                        >
                          {errorValue}
                        </p>
                      </Box>

                      <TextField
                        onChange={handleChange}
                        error={Boolean(errors?.link)}
                        helperText={errors.link}
                        label="Link"
                        fullWidth
                        variant="outlined"
                        name="link"
                        value={values.link}
                        onBlur={handleBlur}
                        placeholder="Enter only routes , e.g. /home , /explore"
                        sx={{ marginBottom: "10px" }}
                      />

                      <DateTimePicker
                        label="Schedule Time"
                        format="YYYY-MM-DD HH:mm:ss"
                        value={values.scheduleTime}
                        onChange={(scheduleTime) =>
                          setFieldValue("scheduleTime", scheduleTime)
                        }
                        // renderInput={(params) => <TextField {...params} />}
                        // InputProps={{
                        //   size: "small",
                        //   fullWidth: true,
                        // }}
                        desktopModeMediaQuery="@media (min-width: 540px)"
                        minDate={dayjs()}
                      />
                    </Box>

                    <CustomBox>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <Box>
                          <Typography
                            style={{ fontWeight: "bold", padding: "10px" }}
                          >
                            Device Preview
                          </Typography>
                        </Box>

                        <Box>
                          <CustomButton onClick={() => setPreviewShow(false)}>
                            Initial
                          </CustomButton>
                          <CustomButton onClick={() => setPreviewShow(true)}>
                            Expanded
                          </CustomButton>
                        </Box>
                      </Box>

                      <PreviewBox style={{ padding: "10px", height: "90%" }}>
                        <Stack
                          direction="row"
                          spacing={3}
                          style={{ padding: "5px", marginBottom: "20px" }}
                        ></Stack>
                        <Stack>
                          {previewShow === false ? (
                            <Initial formData={values} image={image} />
                          ) : (
                            <Expanded formData={values} image={image} />
                          )}
                        </Stack>
                      </PreviewBox>
                    </CustomBox>
                  </MainBox>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </>
            )}
          </Formik>
        </Modal>
      </Box>
    </Fragment>
  );
};

const Container = styled.div`
  // display: flex;
  // flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  .MuiButton-root {
    text-transform: capitalize;
  }
`;

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 850px;
  }
  .MuiTextField-root {
    margin-top: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 12px 8px;
    .MuiButton-root {
      max-width: 200px;
      width: 100%;
    }
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
`;

const CustomBox = styled(Box)`
  border: 1px #e8e8e8 solid;
  border-radius: 5px;
  max-height: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin-left: 15px;
  // margin-top: 15px;
  margin-bottom: 15px;

  @media (max-width: 740px) {
    max-width: 100%;
    margin-left: 0px;
  }
`;
const PreviewBox = styled(Box)`
  background-color: #e8e8e8;

  margin-top: 15px;
`;

const MainBox = styled(Box)`
  display: flex;

  @media (max-width: 740px) {
    flex-direction: column;
  }
`;

const Modal = styled(Dialog)`
  .checkBoxes {
    display: flex;
    padding: 20px;
  }
  .fcl {
    width: 20%;
  }
  .modalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .MuiDialog-paper {
    width: 100%;
    max-width: 840px;
  }
  .MuiDialogContent-root {
    .MuiFormControl-root {
      width: 100%;
      &:last-child {
        margin-bottom: 16px;
      }
    }
    padding: 8px 16px;
    .MuiChip-outlined {
      margin: auto 5px;
      height: 20px;
    }
  }
  .MuiDialogActions-root {
    justify-content: center;
    .MuiButtonBase-root {
      max-width: 150px;
      width: 100%;
    }
  }
  @media (max-width: 540px) {
    .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialogTitle-root {
      justify-content: flex-start;
      align-items: center;
    }
    .MuiTypography-root {
      font-size: 16px;
    }
  }
`;

const CustomButton = styled(Button)`
  background-color: #e8e8e8;
  color: #2196f3;
  margin: 8px;

  &:focus {
    background-color: #bbdefb;
    color: #2196f3;
  }
`;
