import styled from "@emotion/styled";
import MuiCardHeader from "@mui/material/CardHeader";
import { Button } from "@mui/material";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React, { FC, Fragment } from "react";
import {Close} from "@mui/icons-material";
import { COLOR } from "assets";
import { isEmpty, rest } from "lodash-es";
import { useGetPostForAdminQuery } from "generated/graphql";
import DateTime from "./DateTime";
import { ArrowRight } from "@mui/icons-material";
import PostDetailsMedia from "./PostDetailsMedia";
import TextPostRender from "./TextPostRender";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import imgBlueTick from "assets/images/blue-check.png";
import imgGreenTick from "assets/images/green-check.png";
import imgYellowTick from "assets/images/yellow-check.png";
interface props {
  open: boolean;
  postId: string | undefined;
  handleCloseModal: () => void;
  postlink: string | undefined;
}

const PostModal: FC<props> = ({ open, handleCloseModal, postId, postlink }) => {
  const { data } = useGetPostForAdminQuery({
    variables: {
      postId,
    },
  });
  console.log("POST MODAL", postlink);
  return (
    <Fragment>
      <Link href={postlink}>
        <Box>
          <Modal
            {...rest}
            open={open as any}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <DialogTitle className="modalTitle">
              <h3>Post</h3>

              <Link href={postlink} target="_blank">
                <IconButton title="Go to Post">
                  <ArrowCircleRightOutlined />
                </IconButton>
              </Link>
              <IconButton onClick={handleCloseModal}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <MainBox>
                <Box>
                  <Header
                    avatar={
                      <Avatar
                        src={data?.getPostForAdmin?.User?.avatar?.showUri}
                        onClick={(e) => e.preventDefault()}
                      />
                    }
                    title={
                      <>
                        <CustomTypographyTwo variant="body2">
                          {/* <Link
                          // to={`/profile/${user?.username}`}
                          > */}
                          <strong>{`${data?.getPostForAdmin?.User?.firstName} ${data?.getPostForAdmin?.User?.lastName}`}</strong>
                          {/* </Link> */}
                          {Boolean(data?.getPostForAdmin?.User?.isVerified) && (
                            <Image src={imgBlueTick} alt="Blue Tick" />
                          )}
                          {Boolean(data?.getPostForAdmin?.User?.greenTick) && (
                            <Image src={imgGreenTick} alt="Green Tick" />
                          )}
                          {Boolean(data?.getPostForAdmin?.User?.tick3) && (
                            <Image src={imgYellowTick} alt="Yellow Tick" />
                          )}
                          {!isEmpty(data?.getPostForAdmin?.Club) && (
                            <Fragment>
                              <ArrowRight />
                              {/* <Link to={`/club/${club?.clubId}`}> */}
                              {data?.getPostForAdmin?.Club?.clubName}
                              {/* </Link> */}
                            </Fragment>
                          )}
                        </CustomTypographyTwo>
                      </>
                    }
                    subheader={
                      <>
                        <CustomTypography variant="caption">
                          @{`${data?.getPostForAdmin?.User?.username}`}
                          <Dot>&#8226;</Dot>
                          <DateTime date={data?.getPostForAdmin?.createdAt} />
                        </CustomTypography>
                      </>
                    }
                  />

                  <TextPostRender
                    text={data?.getPostForAdmin?.text as string}
                    seeMore
                  />
                  <PostDetailsMedia
                    media={data?.getPostForAdmin?.media as any}
                  />
                </Box>
                <Box></Box>
                <Box></Box>

                <Box>
                  <Container>
                    <Button startIcon={<UpvoteIcon />}>
                      {data?.getPostForAdmin?.upvotecount || ""}
                    </Button>

                    <Button startIcon={<DownvoteIcon />}>
                      {data?.getPostForAdmin?.downvotecount || ""}
                    </Button>

                    <Button startIcon={<CommentIcon />}>
                      {data?.getPostForAdmin?.commentcount || ""}
                    </Button>

                    <Button startIcon={<ShareIcon />}>
                      {data?.getPostForAdmin?.sharecount || ""}
                    </Button>
                  </Container>
                </Box>
              </MainBox>
            </DialogContent>
          </Modal>
        </Box>
      </Link>
    </Fragment>
  );
};

export default PostModal;

const Modal = styled(Dialog)`
  .modalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .MuiPaper-root {
    overflow-y: initial;
  }
  .MuiDialog-container {
    align-items: flex-start;
  }
  .MuiDialog-paper {
    width: 100%;
    max-width: 500px;
    min-height: 160px;
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
  .MuiDialogContent-root {
    padding: 8px 16px;
    .MuiChip-outlined {
      margin: auto 5px;
      height: 20px;
    }
  }
  @media (max-width: 540px) {
    .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialogTitle-root {
      justify-content: flex-start;
      align-items: center;
    }
    .MuiDialogTitle-root {
      padding: 8px;
      margin: 0;
    }
    .MuiTypography-root {
      font-size: 16px;
    }
    .back-btn {
      display: block;
      margin-right: 8px;
    }
    .close-btn {
      display: none;
    }
  }
  @media (min-width: 481px) {
    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;

const Image = styled.img`
  height: 14px;
  width: 14px;
  margin-left: 4px;
`;

const MainBox = styled(Box)`
  display: flex;
  flex-direction: column;

  @media (max-width: 740px) {
    flex-direction: column;
  }
`;

const Header = styled(MuiCardHeader)`
  padding: 8px;

  & .MuiCardHeader-avatar {
    margin-right: 8px;
  }

  & .MuiCardHeader-action {
    margin: 0;
  }

  & .MuiTypography-root {
    line-height: 1.2;

    a {
      text-decoration: none;
    }
  }

  & .MuiCardHeader-title {
    font-size: 14px;
    font-weight: 800;
    align-items: center;
    display: flex;

    & .verified-user {
      height: 15px;
      width: 15px;
    }

    a {
      color: ${COLOR.blue};

      &:hover {
        color: ${COLOR.lightBlue};
      }
    }
  }

  & .MuiCardHeader-subheader {
    font-size: 12px;
    font-weight: 400;
    color: ${COLOR.mutedDark};
  }
`;

const UpvoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
  </svg>
);

const DownvoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17" />
  </svg>
);

const CommentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
  </svg>
);

const ShareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="18" cy="5" r="3"></circle>
    <circle cx="6" cy="12" r="3"></circle>
    <circle cx="18" cy="19" r="3"></circle>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
  </svg>
);

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
  border-top: 1px solid rgba(0, 0, 0, 0.055);
  margin: 0 12px;
  padding-top: 8px;
`;

// const Button = styled(MuiButton) <{ active?: Maybe<number> | undefined }>`
//   && {
//       .MuiButton-startIcon {
//           color: ${({ active }) => active ? "#2094dd" : "#5d5d5d"};
//           margin-right: 8px;
//       }
//       .MuiButton-label {
//           color: #131722;
//           font-size: 1rem;
//       }
//   }
// `;
const Dot = styled.div`
  margin: 0 4px;
`;

const CustomTypography = styled(Typography)`
  display: flex;
  align-items: center;
  color: rgb(92, 112, 130);
  a {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    text-decoration: underline;
    color: inherit;
  }
  font-size: 0.725rem;
  line-height: 0.875rem;
`;
const CustomTypographyTwo = styled(Typography)`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    color: inherit;
    text-decoration: underline;
  }
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: bold;
  /* @media (min-width: 375px) {
        font-size: 1rem;
        line-height: 1.125rem;
    }
    @media (min-width: 540px) {
        font-size: 1.125rem;
        line-height: 1.5rem;
    } */
`;
