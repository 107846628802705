import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";

export const FeatureTableHead = () => (
    <TableHead>
        <TableRow>
            <TableCell style={{ width: 350 }}>
                &nbsp;&nbsp;&nbsp;Feature Name
            </TableCell>
            <TableCell style={{ width: 350 }}>Alias</TableCell>
            <TableCell style={{ width: 350 }}>Created By</TableCell>
            <TableCell style={{ width: 350 }}>Created At</TableCell>
            <TableCell style={{ width: 350 }} align="right">
                Actions
            </TableCell>
        </TableRow>
    </TableHead>
)