import React, { FC } from "react";
import { Edit } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";

export const EditIconButton: FC<IconButtonProps> = (props) => (
    <IconButton size="small" color="primary" {...props}>
        <Edit fontSize="small" />
    </IconButton>
)


