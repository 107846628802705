import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  TextField,
} from "@mui/material";
import { Formik, useFormikContext } from "formik";
import React, { FC, Fragment, useState } from "react";
import styled from "@emotion/styled";
import {Close} from "@mui/icons-material";
import {
  AdminUserSearchFilterType,
  useAdminUserSearchLazyQuery,
} from "generated/graphql";
import { isEmpty } from "lodash-es";
import { CheckCircle } from "@mui/icons-material";
// import { green, yellow } from "@mui/material/colors";

const obj: Record<string, AdminUserSearchFilterType> = {
  greentick: AdminUserSearchFilterType.GreenTick,
  bluetick: AdminUserSearchFilterType.BlueTick,
  yellowtick: AdminUserSearchFilterType.YellowTick,
};

interface FilterProps {
  onClear: () => void;
}

export const UserSearchModal: FC<FilterProps> = ({ onClear }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [searchUsers, { data, loading }] = useAdminUserSearchLazyQuery({
    fetchPolicy: "network-only",
  });
  const [emptyResult, setEmptyResult] = useState(false);
  const filter = values.filter;
  const handleClear = () => {
    setEmptyResult(true);
    values.userId = "";
    setOpen(false);
    onClear();
  };
  return (
    <Fragment>
      <TextField
        id="outlined-basic"
        label="Select User"
        variant="outlined"
        size="small"
        onClick={() => setOpen(true)}
        value={values.fullName || ""}
        inputProps={{ readOnly: true }}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <DialogTitle className="modalTitle">
          <h3>Search & select user</h3>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <SearchScreen>
            <Formik
              initialValues={{ username: "", email: "" }}
              onSubmit={(values, { setSubmitting }) => {
                searchUsers({
                  variables: {
                    ...values,
                    limit: 10,
                    offset: 0,
                    filter: obj[filter],
                  },
                });
                setSubmitting(false);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <SearchContainer id="searchcontainer">
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="search"
                      name="username"
                      className="username"
                      placeholder="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    <Input
                      type="search"
                      name="email"
                      className="email"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={() => setEmptyResult(false)}
                    >
                      Search
                    </Button>
                  </form>
                </SearchContainer>
              )}
            </Formik>
            <SearchResults>
              {loading ? (
                <Loader />
              ) : (
                isEmpty(data?.adminUserSearch) && <P>No users found!</P>
              )}
              {emptyResult === false
                ? data?.adminUserSearch?.map((user) => {
                    // fullName = user?.firstName;
                    return (
                      <Card
                        key={user?.id}
                        className={values.userId === user?.id ? "active" : ""}
                        onClick={() => {
                          setOpen(false);
                          setFieldValue("userId", user?.id);
                          setFieldValue(
                            "fullName",
                            user?.firstName + " " + user?.lastName
                          );
                        }}
                      >
                        <Avatar src={user?.avatar?.showUri} />
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Name>
                            {user?.firstName} {user?.lastName}
                            <span style={{ padding: "3px" }}>
                              {user?.isVerified ? (
                                <CheckCircle
                                  color="primary"
                                  sx={{ fontSize: "small" }}
                                />
                              ) : user?.greenTick ? (
                                <CheckCircle
                                  sx={{ color: "green", fontSize: "small" }}
                                />
                              ) : user?.tick3 ? (
                                <CheckCircle
                                  sx={{ color: "#fbc02d", fontSize: "small" }}
                                />
                              ) : null}
                            </span>
                          </Name>

                          <div>
                            <Username>@{user?.username}</Username>
                            <Email>{user?.email}</Email>
                          </div>
                        </div>
                      </Card>
                    );
                  })
                : ""}
            </SearchResults>
          </SearchScreen>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClear} variant="contained" color="error">
            Clear
          </Button>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Modal>
    </Fragment>
  );
};

const Loader = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress />
  </Box>
);

const P = styled.p`
  color: grey;
  text-align: center;
`;

// const UserCard = styled.div`
//   display: flex;
//   align-items: center;
//   border: 1px solid #ccc;
// `;

const Card = styled.button`
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  border: 0;
  cursor: pointer;
  &.active {
    border: 1px solid #ccc;
  }
`;

const Name = styled.h3`
  margin: 0;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 5px;
  font-weight: 600;
  text-align: start;
`;

const Username = styled.span`
  margin-left: 10px;
  color: #9b9b9b;
`;

const Email = styled.span`
  margin-left: 10px;
  color: #9b9b9b;
`;

const SearchResults = styled.div``;

const Modal = styled(Dialog)`
  .modalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .MuiPaper-root {
    overflow-y: initial;
  }
  .MuiDialog-container {
    align-items: flex-start;
  }
  .MuiDialog-paper {
    width: 100%;
    max-width: 500px;
    min-height: 360px;
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
  .MuiDialogContent-root {
    padding: 8px 16px;
    .MuiChip-outlined {
      margin: auto 5px;
      height: 20px;
    }
  }
  @media (max-width: 540px) {
    .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialogTitle-root {
      justify-content: flex-start;
      align-items: center;
    }
    .MuiDialogTitle-root {
      padding: 8px;
      margin: 0;
    }
    .MuiTypography-root {
      font-size: 16px;
    }
    .back-btn {
      display: block;
      margin-right: 8px;
    }
    .close-btn {
      display: none;
    }
  }
  @media (min-width: 481px) {
    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;

const SearchContainer = styled.div`
  form {
    display: grid;
    grid-template-columns: 1fr 1fr 80px;
    grid-column-gap: 12px;
  }
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
`;

const SearchScreen = styled.div``;
