import styled from "@emotion/styled";
import {
  DialogTitle,
  IconButton,
  DialogContent,
  Dialog,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import MuiDialogActions from "@mui/material/DialogActions";
import { Formik } from "formik";
import React, { FC, Fragment } from "react";
import { Close } from "@mui/icons-material";
import {
  ContentMadeByUser,
  Maybe,
  User,
  useAddUserCreatorTypeOfContentForTickUserMutation,
} from "generated/graphql";
interface MarkCreatorProps {
  open: boolean;
  user: Maybe<User> | undefined;
  handleClose: () => void;
}

interface Values {
  contentMadeByUser: Array<ContentMadeByUser>;
}

const initialValues: Values = {
  contentMadeByUser: [],
};

const MarkCreatorType: FC<MarkCreatorProps> = ({ open, user, handleClose }) => {
  const [addUserCreatorTypeOfContentForTickUser] =
    useAddUserCreatorTypeOfContentForTickUserMutation();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Creator Types
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={{
          ...initialValues,
          contentMadeByUser: user?.typeOfContent || [],
        }}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          addUserCreatorTypeOfContentForTickUser({
            variables: {
              userId: user?.id,
              contentMadeByUser: values?.contentMadeByUser as any,
            },
          }).then(() => {
            resetForm();
            handleClose();
          });
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Fragment>
            <DialogContent dividers>
              <FormGroup>
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: "18px",
                    },
                    padding: "0.5rem",
                  }}
                  control={
                    <Checkbox
                      checked={values?.contentMadeByUser?.includes(
                        ContentMadeByUser?.AllContent
                      )}
                      value={ContentMadeByUser?.AllContent}
                      onChange={(e) => {
                        const { value } = e.target;

                        if (
                          values.contentMadeByUser.includes(
                            value as ContentMadeByUser
                          )
                        ) {
                          setFieldValue("contentMadeByUser", [
                            ...values.contentMadeByUser.filter(
                              (x) => x != ContentMadeByUser?.AllContent
                            ),
                          ]);
                        } else {
                          setFieldValue("contentMadeByUser", [
                            ...values.contentMadeByUser,
                            value,
                          ]);
                        }
                      }}
                    />
                  }
                  label="All Content"
                  name="All Content"
                />
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: "18px",
                    },
                    padding: "0.5rem",
                  }}
                  control={
                    <Checkbox
                      checked={values?.contentMadeByUser?.includes(
                        ContentMadeByUser?.BeginnerContent
                      )}
                      value={ContentMadeByUser?.BeginnerContent}
                      onChange={(e) => {
                        const { value } = e.target;

                        if (
                          values.contentMadeByUser.includes(
                            value as ContentMadeByUser
                          )
                        ) {
                          setFieldValue("contentMadeByUser", [
                            ...values.contentMadeByUser.filter(
                              (x) => x != ContentMadeByUser?.BeginnerContent
                            ),
                          ]);
                        } else {
                          setFieldValue("contentMadeByUser", [
                            ...values.contentMadeByUser,
                            value,
                          ]);
                        }
                      }}
                    />
                  }
                  label="Beginner Content"
                  name="beginner"
                />
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: "18px",
                    },
                    padding: "0.5rem",
                  }}
                  control={
                    <Checkbox
                      checked={values?.contentMadeByUser?.includes(
                        ContentMadeByUser?.InvestorContent
                      )}
                      sx={{ fontSize: "15px !important" }}
                      value={ContentMadeByUser?.InvestorContent}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          values.contentMadeByUser.includes(
                            value as ContentMadeByUser
                          )
                        ) {
                          setFieldValue("contentMadeByUser", [
                            ...values.contentMadeByUser.filter(
                              (x) => x != ContentMadeByUser?.InvestorContent
                            ),
                          ]);
                        } else {
                          setFieldValue("contentMadeByUser", [
                            ...values.contentMadeByUser,
                            value,
                          ]);
                        }
                      }}
                    />
                  }
                  label="Investor Content"
                  name="investor"
                />
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: "18px",
                    },
                    padding: "0.5rem",
                  }}
                  control={
                    <Checkbox
                      checked={values?.contentMadeByUser?.includes(
                        ContentMadeByUser?.TraderContent
                      )}
                      value={ContentMadeByUser?.TraderContent}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          values.contentMadeByUser.includes(
                            value as ContentMadeByUser
                          )
                        ) {
                          setFieldValue("contentMadeByUser", [
                            ...values.contentMadeByUser.filter(
                              (x) => x != ContentMadeByUser.TraderContent
                            ),
                          ]);
                        } else {
                          setFieldValue("contentMadeByUser", [
                            ...values.contentMadeByUser,
                            value,
                          ]);
                        }
                      }}
                    />
                  }
                  label="Trader Content"
                  name="trader"
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <SaveButton onClick={submitForm}>Save</SaveButton>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </Dialog>
  );
};

export default MarkCreatorType;

const DialogActions = styled(MuiDialogActions)({
  justifyContent: "center",
});

const SaveButton = styled((props: ButtonProps) => (
  <MuiButton
    size="medium"
    variant="contained"
    color="primary"
    fullWidth
    {...props}
  />
))({
  maxWidth: 150,
});
