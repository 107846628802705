import React, { FC, Fragment, useState } from "react";
import { Helmet } from "react-helmet-async";
import CategoryDropdown from "./components/CategoryDropdown";
import HashtagList from "./components/HashtagList";
import { SelectChangeEvent, Stack, styled } from "@mui/material";
import { UserHashtagContentType } from "generated/graphql";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";

const HashtagCategorization: FC = () => {
  const [category, setCategory] = useState<UserHashtagContentType>(
    UserHashtagContentType.Beginner
  );

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    setCategory(event.target.value as UserHashtagContentType);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Hashtag Categorization | Onboarding | SES Admin Panel</title>
      </Helmet>
      <Container>
        <Stack direction="row" justifyContent="flex-end">
          <CategoryDropdown value={category} onChange={handleOnChange} />
        </Stack>
        <HashtagList category={category} />
      </Container>
    </Fragment>
  );
};

export default withAdminRoleRequired(HashtagCategorization, {
  roles: keyRoleMap["Onboarding"],
});

const Container = styled("div")`
  padding: 16px;
`;
