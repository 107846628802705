import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC, Fragment, useState } from "react";
import {
  WhatsNew,
  useDeleteWhatsNewMutation,
  useToggleWhatsNewActiveStatusMutation,
} from "generated/graphql";
import { Delete, Edit } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";

const WhatsNewTableRow: FC<WhatsNew & { handleOpenEditModal: () => void }> = (
  props
) => {
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);
  const [toggleWhatsNewActiveStatus] = useToggleWhatsNewActiveStatusMutation();
  const [deleteWhatsNew] = useDeleteWhatsNewMutation();

  const handleToggleIsActive =
    (whatsNewId?: string | null | undefined) => () => {
      if (whatsNewId) {
        toggleWhatsNewActiveStatus({
          variables: {
            whatsNewId,
          },
        });
      }
    };

  const handleDelete = (whatsNewId?: string | null | undefined) => async () => {
    if (!whatsNewId) return;
    try {
      await confirm({
        title: "Are you sure you want to delete this What's New?",
        description:
          "This will delete this whats new permanently. You can not undo this action.",
        dialogProps: {
          PaperProps: {
            sx: {
              width: 400,
            },
          },
        },
        confirmationText: "Delete",
        confirmationButtonProps: {
          color: "error",
          variant: "contained",
          sx: {
            width: 100,
          },
        },
        cancellationButtonProps: {
          color: "info",
          variant: "outlined",
          sx: {
            width: 100,
          },
        },
      });
      await deleteWhatsNew({
        variables: {
          whatsNewId,
        },
        update: (cache) => {
          cache.evict({
            id: cache.identify({
              whatsNewId,
              __typename: "WhatsNew",
            }),
          });
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Fragment>
      <TableRow sx={{ "& > td": { borderBottom: "unset" } }}>
        <TableCell sx={{ width: 40 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{props?.versionNo}</TableCell>
        <TableCell>
          <Stack direction="row" spacing={1 / 2}>
            {props?.platformType?.map((p, key) => (
              <Chip key={key} variant="outlined" label={p} size="small" />
            ))}
          </Stack>
        </TableCell>
        <TableCell sx={{ width: 80 }}>
          <Switch
            checked={Boolean(props?.isActive)}
            color="primary"
            onChange={handleToggleIsActive(props?.whatsNewId)}
          />
        </TableCell>
        <TableCell sx={{ width: 100 }}>
          <Stack direction="row" spacing={1}>
            <IconButton color="primary" onClick={props.handleOpenEditModal}>
              <Edit />
            </IconButton>
            <IconButton color="error" onClick={handleDelete(props?.whatsNewId)}>
              <Delete />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow sx={{ "& > *": { borderTop: "unset" } }}>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper
              variant="elevation"
              elevation={0}
              square
              sx={{
                paddingY: 2,
                paddingX: 2,
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {props?.whatsNew?.map((d, key) => (
                <Card key={key} sx={{ width: 320 }}>
                  <CardMedia
                    component={d?.image ? "img" : "div"}
                    alt="green iguana"
                    height="200"
                    image={d?.image as string}
                    sx={{
                      height: 200,
                    }}
                  />
                  <CardContent>
                    <Typography>{d?.title}</Typography>
                    <Typography>{d?.subText}</Typography>
                  </CardContent>
                  <CardActions />
                </Card>
              ))}
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default WhatsNewTableRow;
