import React from "react";
import { Stack, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { TableChart, Timeline } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
const TableGraphToggle = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [alignment, setAlignment] = React.useState<string | null>(
    searchParams.get("type") === "graph" ? "graph" : "table"
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment as string);
      searchParams.set("type", newAlignment as string);
      setSearchParams(searchParams);
    }
  };

  // useEffect(() => {}, [alignment, searchParams, setSearchParams]);

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };
  return (
    <Stack
      spacing={2}
      alignItems="center"
      style={{ alignItems: "flex-start" }}
      mb={2}
    >
      <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
        {" "}
        <ToggleButton value="table" key="left">
          <TableChart />
        </ToggleButton>
        <ToggleButton value="graph" key="center">
          <Timeline />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default TableGraphToggle;
