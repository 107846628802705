import React, { FC, Fragment } from "react";
import styled from "@emotion/styled";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";
import { useAddFeatureDataForClubGroupMutation, NewFeatureMappingFragmentDoc } from "generated/graphql";
import { Formik } from "formik";
import { Close } from "@mui/icons-material";
import toast from "react-hot-toast";
import { isEmpty } from "lodash-es";

interface AddFeatureFormProps {
    handleCloseModal: () => void;
}

export const AddFeatureForm: FC<AddFeatureFormProps> = ({ handleCloseModal }) => {
    const [addFeatureMapping, { loading }] = useAddFeatureDataForClubGroupMutation();
    return (
        <Fragment>
            <Formik
                initialValues={{
                    featureData: {
                        featureName: "",
                        featureAlias: "",
                    }
                }}
                validate={(values) => {
                    const featureData: Record<string, string> = {};
                    if (!values.featureData.featureName) featureData.featureName = "Required";
                    if (!values.featureData.featureAlias) featureData.featureAlias = "Required";
                    return isEmpty(featureData) ? {} : { featureData };
                }}
                onSubmit={(values, { setSubmitting }) => {
                    addFeatureMapping({
                        variables: values,
                        update: (cache, { data }) => {
                            cache.modify({
                                fields: {
                                    getFeatureMappingListForClub: (previous) => {
                                        const newFeatureMappingRef = cache.writeFragment({
                                            data: data?.addFeatureDataForClubGroup,
                                            fragment: NewFeatureMappingFragmentDoc
                                        })
                                        return [...previous, newFeatureMappingRef]
                                    }
                                }
                            })
                        },
                        onCompleted: (data) => {
                            if (data?.addFeatureDataForClubGroup) {
                                toast.success("Successfully added!");
                                handleCloseModal();
                            }
                        },
                        onError: (error) => {
                            toast.error(error.message);
                        }
                    }).finally(() => {
                        setSubmitting(false);
                    })
                }}
            >
                {({
                    isSubmitting,
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    submitForm,
                }) => (
                    <Fragment>
                        <CustomDialogTitle>
                            Add Feature
                            <CustomIconButton onClick={handleCloseModal}>
                                <Close />
                            </CustomIconButton>
                        </CustomDialogTitle>
                        <DialogContent>
                            <TextField
                                label="Feature Name"
                                value={values.featureData.featureName}
                                name="featureData.featureName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                error={Boolean(errors?.featureData?.featureName)}
                                helperText={errors?.featureData?.featureName}
                            />
                            <TextField
                                label="Feature Alias"
                                value={values.featureData.featureAlias}
                                name="featureData.featureAlias"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                error={Boolean(errors?.featureData?.featureAlias)}
                                helperText={errors?.featureData?.featureAlias}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={isSubmitting || loading}
                                onClick={submitForm}
                                variant="contained"
                                color="primary"
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
};

const CustomIconButton = styled(IconButton)`
  position: absolute;
  right: 16px;
`;

const CustomDialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
`;
