import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { useFormikContext } from "formik";
import { PlatformTypeForWhatsNew } from "generated/graphql";
import React from "react";
import { WhatsNewFormInitialValues } from "./WhatsNewForm";

const PlatformType = () => {
  const { values, errors, handleChange, handleBlur } =
    useFormikContext<WhatsNewFormInitialValues>();
  return (
    <FormControl
      error={Boolean(errors.platformType)}
      component="fieldset"
      variant="standard"
      sx={{ paddingY: 1 }}
    >
      <FormLabel component="legend">Platforms</FormLabel>
      <FormGroup row sx={{ paddingX: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              value={PlatformTypeForWhatsNew.Android}
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.platformType.includes(
                PlatformTypeForWhatsNew.Android
              )}
              name="platformType"
            />
          }
          label="Android"
        />
        <FormControlLabel
          control={
            <Checkbox
              value={PlatformTypeForWhatsNew.Ios}
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.platformType.includes(
                PlatformTypeForWhatsNew.Ios
              )}
              name="platformType"
            />
          }
          label="iOS"
        />
        <FormControlLabel
          control={
            <Checkbox
              value={PlatformTypeForWhatsNew.Web}
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.platformType.includes(
                PlatformTypeForWhatsNew.Web
              )}
              name="platformType"
            />
          }
          label="Web"
        />
      </FormGroup>
      <FormHelperText>{errors.platformType}</FormHelperText>
    </FormControl>
  );
};

export default PlatformType;
