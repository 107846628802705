import React from "react";
import { Formik, FormikConfig } from "formik";
import { Button, Stack, TextField } from "@mui/material";

export interface Values {
    search: string;
}

export const SearchForm = (props: FormikConfig<Values>) => {
    return (
        <Formik {...props} >
            {({ values, handleChange, handleBlur, isSubmitting, submitForm }) => (
                <Stack direction="row" margin={1} spacing={0.5}>
                    <TextField
                        variant="outlined"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.search}
                        name="search"
                        placeholder="search hashtags"
                    />
                    <Button
                        onClick={submitForm}
                        disabled={values.search.length === 0 || isSubmitting}
                    >Search</Button>
                </Stack>
            )}
        </Formik>
    )
}