import {
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Stack,
    CircularProgress,
    Pagination,
} from "@mui/material";
import NoDataFound from "components/NoDataFound";

import { isEmpty } from "lodash-es";
import { useConfirm } from "material-ui-confirm";
import { CustomTable } from "pages/interstitials/AllInterstitials";
import React, { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

import {
    FeedbackQuestions,
    Maybe,
    useDeleteBadgeFeedbackQuestionAdminMutation, useGetBadgeFeedbackQuestionAdminQuery
} from "generated/graphql";
import BadgeFeedbackRow from "./BadgeFeedbackRow";
import UpdateBadgeFeedback from "./UpdateBadgeFeedback";

const LIMIT = 10;
const BadgeFeedbackList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = (Number(searchParams.get("page")) || 1) as number;
  const { data, loading } = useGetBadgeFeedbackQuestionAdminQuery({
    variables: {
      badgeId: searchParams?.get("badgeId"),
    },
  });

  const [updateFeedbackQuestion, setUpdateFeedbackQuestion] = useState<
    Maybe<FeedbackQuestions> | undefined
  >();
  const [deleteBadgeFeedbackQuestion] =
    useDeleteBadgeFeedbackQuestionAdminMutation();
  const handleCloseModal = () => setUpdateFeedbackQuestion(undefined);
  const onPageChange = (_: any, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const pages = Math.ceil(Number(data?.getBadgeFeedbackQuestionAdmin) / LIMIT);
  const confirm = useConfirm();
  const handleDeleteFeedbackQuestion =
    (question: Maybe<FeedbackQuestions> | undefined) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      confirm({
        title: "Are you sure you want to delete this Question?",
        confirmationText: "Delete",
        confirmationButtonProps: {
          color: "error",
        },
        allowClose: true,
        dialogProps: {
          maxWidth: "xs",
        },
      })
        .then(() => {
          deleteBadgeFeedbackQuestion({
            variables: {
              questionId: question?.questionId,
            },
            update: (cache, { data }) => {
              if (question && data?.deleteBadgeFeedbackQuestionAdmin) {
                cache.evict({ id: cache.identify(question) });
                toast.success("Question deleted successfully");
              }
            },
          });
        })
        .catch(() => console.log("Modal Close"));
    };

  const handleUpdateFeedbackQuestion =
    (question: Maybe<FeedbackQuestions> | undefined) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setUpdateFeedbackQuestion(question);
    };
  return (
    <>
      <TableContainer sx={{ padding: 2 }}>
        <CustomTable>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2} sx={{ minWidth: "100px" }}>
                Question
              </TableCell>
              <TableCell rowSpan={2} sx={{ minWidth: "300px" }}>
                Badge
              </TableCell>
              <TableCell rowSpan={2} sx={{ minWidth: "300px" }}>
                Created at
              </TableCell>

              <TableCell align="center" sx={{ minWidth: "100px" }} colSpan={2}>
                Actions
              </TableCell>
            </TableRow>
            {/* <TableRow>
                <TableCell align="center">Delete</TableCell>
                <TableCell align="center">Update</TableCell>
              </TableRow> */}
          </TableHead>
          <TableBody>
            {data?.getBadgeFeedbackQuestionAdmin?.map((question) => (
              <BadgeFeedbackRow
                key={question?.questionId}
                question={question}
                handleUpdateFeedbackQuestion={handleUpdateFeedbackQuestion}
                handleDeleteFeedbackQuestion={handleDeleteFeedbackQuestion as any}
              />
            ))}
            <UpdateBadgeFeedback
              open={!!updateFeedbackQuestion}
              question={updateFeedbackQuestion}
              handleCloseModal={handleCloseModal}
            />
          </TableBody>
        </CustomTable>
      </TableContainer>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            {isEmpty(data?.getBadgeFeedbackQuestionAdmin) && <NoDataFound />}
            {pages > 1 && (
              <Pagination
                count={pages}
                disabled={loading}
                page={page}
                onChange={onPageChange}
              />
            )}
          </Stack>
        </Fragment>
      )}
    </>
  );
};

export default BadgeFeedbackList;
