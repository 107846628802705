import { Box, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import "./phone.css";
import "./iphone.css";


interface FormProps{
  formData:any
  image:any
}
 const Initial:FC<FormProps>=({formData , image}) => {
  // console.log(formData , image)
  return (
    <Box>
      {/* {typeof formData.message} */}
      <Box className="smartphone">
        <Box className="content">
          <Box
            className="top"
            style={{
              background: "white",
              marginTop: "5px",
              borderRadius: "2px",
            }}
          >
            
            <Box>
              <Stack spacing={0.01}>
                <Typography
                  variant="caption"
                  style={{ paddingLeft: "5px", fontWeight: "bold" }}
                >
                  {formData.message}
                  {/* {console.log(formData?.message)} */}
                </Typography>
                <Typography variant="caption" style={{ paddingLeft: "5px" }}>
                  {formData.body}
                  {/* {console.log(formData?.body)} */}
                </Typography>
              </Stack>
            </Box>
            <Box style={{ width: "10%" }}>
              {/* {img} */}
              <img
                src={image && URL.createObjectURL(image)}
                alt=""
                style={{ width: "100%", paddingRight: "5px" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>


      
      <p style={{ textAlign: "center", fontSize: "0.8em" }}>Android</p>
      {/* <Box className="container">
        <Box className="wrapper">
          <Box
            style={{
              background: "white",
              marginTop: "25px",
              borderRadius: "2px",
            }}
          >
            <Box className="top">
              <Box>
                <Stack spacing={0.01}>
                  <Typography
                    variant="caption"
                    style={{ paddingLeft: "5px", fontWeight: "bold" }}
                  >
                    {formData.message}
                  </Typography>
                  <Typography variant="caption" style={{ paddingLeft: "5px" }}>
                    {formData.body}
                  </Typography>
                </Stack>
              </Box>
              <Box style={{ width: "10%" }}>
              <img
                src={image && URL.createObjectURL(image)}
                alt=""
                style={{ width: "100%", paddingRight: "5px" }}
              />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <p style={{ textAlign: "center", fontSize: "0.8em" }}>Apple</p> */}
    </Box>
  );
}

export default Initial
