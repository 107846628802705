import React, { FC } from "react";
import styled from "@emotion/styled";
import { Avatar } from "@mui/material";
import { Maybe, useGetReportedByListQuery } from "generated/graphql";
import imgRobot from "../../assets/images/robot.png";
interface ReportedByProps {
  siteReportId?: Maybe<string> | undefined;
}

export const ReportedBy: FC<ReportedByProps> = ({ siteReportId }) => {
  const { data } = useGetReportedByListQuery({
    variables: { siteReportId },
  });

  const renderAvatars = data?.getReportedByList?.slice(0, 9).map((d) => (
    <AvatarWraper key={d?.repId}>
      {d?.reportedByBot ? (
        <BotWraper>
          <img src={imgRobot} style={{ padding: 5 }} />
          <Name>Bot</Name>
        </BotWraper>
      ) : (
        <>
          <Avatar src={d?.reporter?.avatar?.LowestResUri} />
          <Name>{d?.reporter?.firstName}</Name>
        </>
      )}
    </AvatarWraper>
  ));

  return (
    <Container>
      {renderAvatars}
      {/* <AvatarWraper>
                <Avatar />
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar>T</Avatar>
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar />
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar />
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar>T</Avatar>
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar />
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar />
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar>T</Avatar>
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar />
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar />
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar>T</Avatar>
                <Name>Tomomi</Name>
            </AvatarWraper>
            <AvatarWraper>
                <Avatar />
                <Name>Tomomi</Name>
            </AvatarWraper> */}
      {/* <More>& 16 more</More> */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 10px 0;
`;

const AvatarWraper = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Name = styled.span`
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;
const BotWraper = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
`;
// const More = styled.span`
//     font-family: Mulish;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 18px;
//     text-align: center;
//     letter-spacing: 1px;
//     color: #252733;
// `;
