import React, { FC, Fragment, useState } from "react";
import {
  UserHashtagContentType,
  useGetHashtagListForAdminBasedOnContentFilterQuery,
} from "generated/graphql";
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import AddHashtags from "./AddHashtags";
import HashtagCard from "./Hashtag";
import NoDataFound from "components/NoDataFound";
import { isEmpty } from "lodash-es";
interface HashtagListProps {
  category: UserHashtagContentType;
}

const HashtagList: FC<HashtagListProps> = ({ category }) => {
  const { data, loading } = useGetHashtagListForAdminBasedOnContentFilterQuery({
    variables: { typeOfContentMadeByHashtag: category },
  });
  const [open, setOpen] = useState<boolean>(false);

  const markedHashtags = data?.getHashtagListForAdminBasedOnContentFilter?.map(
    (x) => x?.hashtagName
  );

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{category} Hashtag List</Typography>
        <Button size="small" onClick={() => setOpen(true)}>
          Add {category} Hashtag
        </Button>
      </Stack>
      <Grid>
        {data?.getHashtagListForAdminBasedOnContentFilter?.map(
          (hashtag, key) => (
            <HashtagCard
              key={key}
              hashtag={hashtag?.hashtagName}
              isMarked={markedHashtags?.includes(hashtag?.hashtagName)}
              category={category}
            />
          )
        )}
      </Grid>
      <AddHashtags
        setOpen={setOpen}
        open={open}
        category={category}
        markedHashtags={markedHashtags}
      />
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            {isEmpty(data?.getHashtagListForAdminBasedOnContentFilter) && (
              <NoDataFound />
            )}
          </Stack>
        </Fragment>
      )}
    </Container>
  );
};

export default HashtagList;

const Container = styled("div")`
  padding-top: 16px;
`;

const Grid = styled("div")({
  marginTop: "20px",
  display: "grid",
  "@media (min-width: 640px)": {
    gridTemplateColumns: "1fr 1fr",
    gridGap: "8px",
  },
  "@media (min-width: 960px)": {
    gridTemplateColumns: "1fr 1fr 1fr",
  },
});
