import axios from "axios";
import { getOIDCUser } from "config/auth";

axios.interceptors.request.use(
  (config) => {
    const user = getOIDCUser();
    if (user?.access_token) {
      config.headers["Authorization"] = `Bearer ${user?.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const uploadFiles = (files: Array<File>) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  return axios.post(process.env.MEDIA_UPLOAD_URL as string, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const exportSessionData = (params: {
  startDate: string | null;
  endDate: string | null;
  periodType: string;
}) => {
  return axios.get(`${process.env.GRAPHQL_API_URL}/admin/exportSessionData`, {
    params,
    responseType: "blob",
  });
};

export const exportReplyData = (params: {
  startDate: string | null;
  endDate: string | null;
  clubId: string | null;
  memberType: string | null;
}) => {
  return axios.get(`${process.env.GRAPHQL_API_URL}/admin/exportReplyData`, {
    params,
    responseType: "blob",
  });
};

export const exportPostData = (params: {
  startDate: string | null;
  endDate: string | null;
  clubId: string | null;
  memberType: string | null;
}) => {
  return axios.get(`${process.env.GRAPHQL_API_URL}/admin/exportPostData`, {
    params,
    responseType: "blob",
  });
};

export const exportSignUpData = (params: {
  startDate: string | null;
  endDate: string | null;
}) => {
  return axios.get(`${process.env.GRAPHQL_API_URL}/admin/exportSignUpData`, {
    params,
    responseType: "blob",
  });
};

export const exportCommentData = (params: {
  startDate: string | null;
  endDate: string | null;
  clubId: string | null;
  memberType: string | null;
}) => {
  return axios.get(`${process.env.GRAPHQL_API_URL}/admin/exportCommentData`, {
    params,
    responseType: "blob",
  });
};

export const getFeedbackAsCSV = () => {
  return axios.get(`${process.env.GRAPHQL_API_URL}/admin/getFeedbackAsCSV`, {
    responseType: "blob",
  });
};
