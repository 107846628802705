import React, { FC, Fragment, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  Box,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { FormHelperText, FormControl } from "@mui/material";
import UploadCreative from "components/UploadCreative";
import {
  UserAudienceType,
  useAddUpcomingEventMutation,
} from "generated/graphql";
import { toast } from "react-hot-toast";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import AbsoluteLoader from "components/AbsoluteLoader";
import { validationSchema } from "./validation";
import CustomDialogTitle from "components/DialogTitle";
import { Dayjs } from "dayjs";
import { uploadFiles } from "api/rest";

interface Values {
  text: string;
  image: File | undefined;
  startTime: Dayjs | undefined;
  ctaButtonText: string;
  ctaButtonUrl: string;
  endTime: Dayjs | undefined;
  description: string;
  audience: UserAudienceType;
}

const AddEvent: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const fullScreen = useMediaQuery("(max-width:540px)");
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <Container>
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        Add New Event
      </Button>
      <CustomDialog
        open={open}
        // onClose={handleCloseModal}
        fullScreen={fullScreen}
        disableEscapeKeyDown
      >
        <CreatePromotionForm handleCloseModal={handleCloseModal} />
      </CustomDialog>
    </Container>
  );
};

export default AddEvent;

interface CreatePromotionForm {
  handleCloseModal: () => void;
}

const CreatePromotionForm: FC<CreatePromotionForm> = ({ handleCloseModal }) => {
  const [addUpcomingEvent, { loading }] = useAddUpcomingEventMutation();

  const handleSubmit = async (
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ) => {
    const { image, ...rest } = values;
    const variables: any = { ...rest };
    try {
      if (image) {
        const { data } = await uploadFiles([image]);
        variables.imageUrl = data?.[0]?.uploadfilename;
      }
      await addUpcomingEvent({
        variables,
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              getListOfUpcomingEvents: (existing) => {
                return {
                  ...existing,
                  eventsList: [
                    data?.addUpcomingEvent,
                    ...(existing?.eventsList || []),
                  ],
                };
              },
            },
          });
        },
      });
      toast.success("Event added!");
      handleCloseModal();
    } catch (e) {
      console.error(e);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const initialValues: Values = {
    text: "",
    image: undefined,
    startTime: undefined,
    ctaButtonText: "",
    ctaButtonUrl: "",
    endTime: undefined,
    description: "",
    audience: UserAudienceType.Club,
  };
  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          handleBlur,
          errors,
          setFieldValue,
          submitForm,
        }) => (
          <Fragment>
            <AbsoluteLoader loading={isSubmitting || loading} />
            <CustomDialogTitle onClose={handleCloseModal}>
              New Event
            </CustomDialogTitle>
            <DialogContent>
              <TextField
                label="Title"
                value={values.text}
                name="text"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors?.text)}
                helperText={errors.text}
              />
              <FormControl fullWidth>
                <UploadCreative
                  error={Boolean(errors?.image)}
                  image={values.image}
                  setImage={(image) => setFieldValue("image", image)}
                />
                <FormHelperText error={Boolean(errors?.image)}>
                  {errors.image}
                </FormHelperText>
              </FormControl>
              <Stack direction="row" spacing={2}>
                <DateTimePicker
                  label="Start"
                  value={values.startTime}
                  onChange={(startTime) =>
                    setFieldValue("startTime", startTime)
                  }
                  maxDate={values.endTime}
                />
                <DateTimePicker
                  label="End"
                  value={values.endTime}
                  onChange={(endTime) => setFieldValue("endTime", endTime)}
                  minDate={values.startTime}
                />
              </Stack>
              <TextField
                label="CTA Button Text"
                value={values.ctaButtonText}
                name="ctaButtonText"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors?.ctaButtonText)}
                helperText={errors.ctaButtonText}
              />
              <TextField
                label="CTA Button URL"
                value={values.ctaButtonUrl}
                name="ctaButtonUrl"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors?.ctaButtonUrl)}
                helperText={errors.ctaButtonUrl}
              />
              <TextField
                label="Description"
                value={values.description}
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                multiline
                rows={4}
                error={Boolean(errors?.description)}
                helperText={errors.description}
              />
              <FormControl
                sx={{ mt: "13px" }}
                component="fieldset"
                variant="standard"
              >
                <Stack direction="row" gap={5}>
                  <FormLabel sx={{ mt: "10px" }}>Audience</FormLabel>
                  <RadioGroup
                    name="audience"
                    value={values.audience}
                    onChange={handleChange}
                  >
                    <Stack direction="row">
                      <Box>
                        <FormControlLabel
                          value={UserAudienceType.Club}
                          control={<Radio />}
                          label="Club"
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value={UserAudienceType.Premium}
                          control={<Radio />}
                          label="Premium"
                        />
                      </Box>
                    </Stack>
                  </RadioGroup>
                </Stack>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button disabled={isSubmitting || loading} onClick={submitForm}>
                Submit
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  .MuiButton-root {
    text-transform: capitalize;
  }
`;

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 480px;
  }
  .MuiTextField-root {
    margin-top: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 12px 8px;
    .MuiButton-root {
      max-width: 200px;
      width: 100%;
    }
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
`;
