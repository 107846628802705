import styled from "@emotion/styled";
import {
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import {
  FilterComponentType,
  FilterPlatformType,
  Interstitials,
  Maybe,
  useDeleteInterstitialMutation,
  useGetListOfInterstitialsQuery,
  useToggleInterstitialsActiveStatusMutation,
} from "generated/graphql";
import React, { Fragment } from "react";
import InterstitialsRow from "./InterstitialsRow";
import { useSearchParams } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import toast from "react-hot-toast";
import InterstitialsTableHead from "./InterstitialsTableHead";
import { isEmpty } from "lodash-es";
import NoDataFound from "components/NoDataFound";
import AddInterstitial from "./AddInterstitial";
import FilterInterstitials, { OnSubmit } from "./FilterInterstitials";

const LIMIT = 20;

const AllInterstitials = () => {
  const confirm = useConfirm();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = (Number(searchParams.get("page")) || 1) as number;
  const { loading, data } = useGetListOfInterstitialsQuery({
    variables: {
      page: page,
      limit: LIMIT,
      filter: {
        componentType:
          (searchParams.get("componentType") as any) || FilterComponentType.All,
        platformType:
          (searchParams.get("platformType") as any) || FilterPlatformType.All,
      },
    },
  });

  const [deleteInterstitial] = useDeleteInterstitialMutation();
  const [toggleInterstialStatus] = useToggleInterstitialsActiveStatusMutation();

  const handleDeleteModal =
    (interstitial: Maybe<Interstitials> | undefined) => () => {
      confirm({
        title: "Are you sure you want to delete this interstitial?",
        confirmationText: "Delete",
        confirmationButtonProps: {
          color: "error",
        },
        allowClose: true,
        dialogProps: {
          maxWidth: "xs",
        },
      })
        .then(() => {
          deleteInterstitial({
            variables: { interstitialsId: interstitial?.interstitialsId },
            update: (cache, { data }) => {
              cache.modify({
                fields: {
                  getListOfInterstitials: (previous) => {
                    return {
                      ...previous,
                      total: (previous.total as number) - 1,
                    };
                  },
                },
              });
              if (interstitial && data?.deleteInterstitial?.success) {
                cache.evict({ id: cache.identify(interstitial) });
                toast.success("Smart Feed deleted successfully");
              }
            },
          });
        })
        .catch(() => console.log("Modal Close"));
    };

  const handleToggleModal =
    (interstitial: Maybe<Interstitials> | undefined) => () => {
      confirm({
        title: `Are you sure you want to ${
          interstitial?.isActive ? "inactive" : "active"
        } this interstitial?`,
        confirmationText: interstitial?.isActive ? "Inactive" : "Active",
        confirmationButtonProps: {
          color: interstitial?.isActive ? "error" : "success",
        },
        allowClose: true,
        dialogProps: {
          maxWidth: "xs",
        },
      })
        .then(() => {
          toggleInterstialStatus({
            variables: { interstitialsId: interstitial?.interstitialsId },
            update: (cache, { data }) => {
              if (data?.toggleInterstitialsActiveStatus?.isActive) {
                toast.success("Smart Feed active");
              } else {
                toast.success("Smart Feed inactive");
              }
            },
          });
        })
        .catch(() => console.log("Modal Close"));
    };

  const onPageChange = (_: any, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const pages = Math.ceil(Number(data?.getListOfInterstitials?.total) / LIMIT);

  const onFilter: OnSubmit = (values) => {
    setSearchParams({ ...values, page: "1" } as any);
  };
  return (
    <Fragment>
      <Stack direction="row" alignItems="center" justifyContent="space-between" margin={1}>
        <FilterInterstitials
          initialValues={{
            componentType:
              (searchParams.get("componentType") as any) ||
              FilterComponentType.All,
            platformType:
              (searchParams.get("platformType") as any) ||
              FilterPlatformType.All,
          }}
          onSubmit={onFilter}
        />
        <AddInterstitial />
      </Stack>
      <Typography sx={{ fontWeight: "bold", marginLeft: "16px" }}>
        Total: {data?.getListOfInterstitials?.total}
      </Typography>
      <TableContainer sx={{ padding: 2 }}>
        <CustomTable>
          <InterstitialsTableHead />
          <TableBody>
            {data?.getListOfInterstitials?.interstitialsList?.map(
              (interstitial) => (
                <InterstitialsRow
                  key={interstitial?.interstitialsId}
                  interstitial={interstitial}
                  // handleEditModal={handleEditModal}
                  handleDeleteModal={handleDeleteModal}
                  handleToggleModal={handleToggleModal}
                />
              )
            )}
          </TableBody>
        </CustomTable>
        {loading ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Fragment>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ marginTop: 2 }}
            >
              {isEmpty(data?.getListOfInterstitials?.interstitialsList) && (
                <NoDataFound />
              )}
              {pages > 1 && (
                <Pagination
                  count={pages}
                  disabled={loading}
                  page={page}
                  onChange={onPageChange}
                />
              )}
            </Stack>
          </Fragment>
        )}
      </TableContainer>
    </Fragment>
  );
};

export default AllInterstitials;

export const CustomTable = styled(Table)`
  .MuiTableCell-root {
    padding: 4px 8px;
  }
  th.MuiTableCell-root {
    padding: 4px 8px;
    white-space: nowrap;
    font-weight: bold;
  }
  .inactive,
  .active {
    font-size: 12px;
    background: red;
    color: #fff;
    padding: 2px 6px;
  }
  .active {
    background-color: green;
  }
`;
