import React, { FC } from "react";

const colors: Record<string, string> = {
  abusecount: "#F3F81F",
  hatefulcount: "#3821C6",
  spamcount: "red",
  othercount: "#2AE7C5",
};

export const ProgressItem = () => {
  return (
    <ul style={{
      display: 'flex',
      flexDirection: 'row',
      listStyle: 'none',
      margin: 0,
      padding: 0
    }}>
      {Object.keys(colors).map((type: string) => {
        return (
          <li key={type}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <span style={{
              height: 15,
              width: 15,
              backgroundColor: colors[type],
              margin: '0 5px',
              borderRadius: 2
            }} />
            <span style={{
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 12,
              letterSpacing: '0.01em',
              color: '#334d6e',
              mixBlendMode: 'normal',
              textTransform: 'capitalize'
            }}>{type.replace('count', '')}</span>
          </li>
        );
      })}
    </ul>
  )
}

export const Progress: FC<any> = ({ data, width = 80 }) => {
  let total = 0;
  Object.keys(data).forEach((lang: string) => {
    total += data[lang];
  });
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        margin: "auto",
        padding: 0,
        overflow: "hidden",
        width,
        borderRadius: 10
      }}
    >
      {Object.keys(data).map((language: string) => {
        return (
          <li key={language}>
            <div
              style={{
                backgroundColor: colors[language],
                width: (data[language] / total) * width,
                height: 10,

              }}
            />
          </li>
        );
      })}
    </ul>
  );
};
