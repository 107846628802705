import React, { FC, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ButtonGroup,
  Switch,
} from "@mui/material";
import { ViewColumn } from "@mui/icons-material";

type CustomizeMISTableProps = {
  dataHeaders: (string | null)[] | null | undefined;
  dataValues: ((string | null)[] | null)[] | null | undefined;
};

function getUpdateArray<T>(arr: Array<T>, positionsToRemove: Array<number>) {
  console.log({ arr, positionsToRemove });
  const newArr = arr.reduce((acc: Array<T>, current: T, index: number) => {
    // If the current index is not in the positions to remove, add it to the accumulator
    if (!positionsToRemove.includes(index)) {
      acc.push(current);
    }
    return acc;
  }, []);
  console.log({ newArr });
  return newArr;
}

const CustomizeMISTable: FC<CustomizeMISTableProps> = ({
  dataHeaders,
  dataValues,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [hiddenColumns, setHiddenColumns] = useState<Array<number>>([]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSeeAll = () => {
    setHiddenColumns([]);
  };

  const handleHideAll = () => {
    const length = dataHeaders?.length;
    if (length) {
      setHiddenColumns(Array.from({ length }, (_, index) => index));
    }
  };

  const handleToggleColumnShowHide = (index: number) => () => {
    setHiddenColumns((x) => {
      if (x.includes(index)) {
        return x.filter((d) => d != index);
      } else {
        return [...x, index];
      }
    });
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="flex-end">
        <Tooltip title="Show/Hide columns">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <ViewColumn fontSize="medium" />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          // onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              maxHeight: 400,
              overflowY: "auto",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Stack
            sx={{
              position: "sticky",
              top: 0,
              padding: 1,
              zIndex: 9,
              backgroundColor: "#fff",
            }}
          >
            <ButtonGroup fullWidth>
              <Button onClick={handleHideAll}>Hide all</Button>
              <Button onClick={handleSeeAll}>Show all</Button>
            </ButtonGroup>
          </Stack>
          {dataHeaders?.map((data, key) => (
            <MenuItem key={key} onClick={handleToggleColumnShowHide(key)}>
              <Switch checked={!hiddenColumns.includes(key)} />
              {data}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
      <TableContainer>
        <Table sx={{ minWidth: 425 }}>
          <TableHead>
            <TableRow>
              {dataHeaders
                ? getUpdateArray(dataHeaders, hiddenColumns)?.map((x, key) => (
                    <TableCell
                      key={x}
                      align={
                        dataHeaders?.indexOf("Date") === key ? "left" : "right"
                      }
                    >
                      <Typography noWrap>{x}</Typography>
                    </TableCell>
                  ))
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataValues?.map((d, k) => (
              <TableRow key={k}>
                {d
                  ? getUpdateArray(d, hiddenColumns)?.map((x, key) => (
                      <TableCell
                        key={key}
                        align={
                          dataHeaders?.indexOf("Date") === key
                            ? "left"
                            : "right"
                        }
                      >
                        <Typography noWrap>{x}</Typography>
                      </TableCell>
                    ))
                  : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomizeMISTable;
