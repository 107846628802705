import {
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import React, { lazy, useState } from "react";
import {
  Maybe,
  PlatformTypeForWhatsNew,
  WhatsNew,
  WhatsNewInput,
  useEditWhatsNewMutation,
  useGetListOfWhatsNewQuery,
} from "generated/graphql";
import WhatsNewTableRow from "./WhatsNewTableRow";
import WhatsNewForm, {
  OnSubmit,
  WhatsNewFormInitialValues,
} from "./WhatsNewForm";
import { Close } from "@mui/icons-material";
import { FormikErrors } from "formik";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { withAdminRoleRequired } from "config/auth";
import { uploadFiles } from "api/rest";
import { keyRoleMap } from "config/side-menu-items";

const AddWhatsNewModal = lazy(() => import("./AddWhatsNewModal"));

function validateVersion(version: string) {
  // Regular expression to match a version number with format x.y.z
  const versionPattern = /^\d+(\.\d{1,2}){2}$/;

  // Check if the version matches the pattern
  if (versionPattern.test(version)) {
    return true; // Valid version number
  } else {
    return false; // Invalid version number
  }
}

const WhatsNewComponent = () => {
  const [{ open, whatsNew }, setData] = useState<{
    open: boolean;
    whatsNew: WhatsNew | null;
  }>({ open: false, whatsNew: null });
  const [editWhatsNew] = useEditWhatsNewMutation();
  const { data } = useGetListOfWhatsNewQuery({
    variables: {
      page: 1,
      limit: 20,
    },
  });

  const handleOpenEditModal = (whatsNew: Maybe<WhatsNew>) => () => {
    setData({ open: true, whatsNew });
  };

  const handleCloseModal = () => {
    setData({ open: false, whatsNew: null });
  };

  const onSubmit: OnSubmit = async (values, formikHelpers) => {
    try {
      const errors: FormikErrors<WhatsNewFormInitialValues> = {};
      if (!values.versionNo) {
        errors.versionNo = "Version no is required";
      } else if (!validateVersion(values.versionNo)) {
        errors.versionNo = "Enter a version number like x.y.z";
      }

      if (!values.platformType.length) {
        errors.platformType = "Choose platform type";
      }
      // if (values.whatsNew.length) {
      //   errors.whatsNew = values.whatsNew.map((d) => {
      //     const error: FormikErrors<{ title?: string }> = {};
      //     if (!d.title) {
      //       error.title = "Title is required";
      //     }
      //     return error;
      //   });
      // }
      if (Object.keys(errors).length) {
        formikHelpers.setErrors(errors);
        return;
      }
      const { data } = await uploadFiles(
        values.whatsNew
          .filter((x) => x.image instanceof File)
          .map((x) => x.image as File)
      );
      let i = 0;
      const newWhatsNew = values.whatsNew.map<WhatsNewInput>((d) => {
        const { title, subText, image } = d;
        if (image instanceof File) {
          const uploadedImage = data[i].HighRes;
          i++;
          return { id: uuidv4(), title, subText, image: uploadedImage };
        }
        return { id: uuidv4(), title, subText, image };
      });
      await editWhatsNew({
        variables: {
          whatsNewId: whatsNew?.whatsNewId as string,
          versionNo: values.versionNo,
          platformType: values.platformType,
          isActive: values.isActive,
          whatsNew: newWhatsNew,
        },
      });
      toast.success("Successfuly Updated.");
      formikHelpers.resetForm();
      setData({ open: false, whatsNew: null });
    } catch (e) {
      toast.error("Something Went Wrong!");
      console.log(e);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Container>
      <Stack flexDirection="row">
        <AddWhatsNewModal />
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Version No.</TableCell>
              <TableCell>Platform</TableCell>
              <TableCell sx={{ width: 80 }}>Is active?</TableCell>
              <TableCell align="center" sx={{ width: 100 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.getListOfWhatsNew?.whatsNewList?.map((d) => (
              <WhatsNewTableRow
                key={d?.whatsNewId}
                handleOpenEditModal={handleOpenEditModal(d)}
                {...d}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={open && !!whatsNew} onClose={handleCloseModal}>
        <CustomDialogTitle>
          Whats New
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </CustomDialogTitle>
        {whatsNew && whatsNew?.whatsNew ? (
          <WhatsNewForm
            initialValues={{
              versionNo: whatsNew?.versionNo as string,
              platformType:
                whatsNew?.platformType as Array<PlatformTypeForWhatsNew>,
              isActive: whatsNew?.isActive as boolean,
              whatsNew: whatsNew?.whatsNew as any,
            }}
            onSubmit={onSubmit}
          />
        ) : null}
      </Modal>
    </Container>
  );
};

export default withAdminRoleRequired(WhatsNewComponent, {
  roles: keyRoleMap["Whats New"],
});

const Container = styled("div")({
  padding: 24,
});

const Modal = styled(Dialog)({});

const CustomDialogTitle = styled(DialogTitle)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 16,
});
