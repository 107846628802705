import React, { FC, Fragment } from "react";
import styled from "@emotion/styled";
import {Close} from "@mui/icons-material";
import { toast } from "react-hot-toast";
import {
  Button,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";

import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  FeedbackQuestions,
  useUpdateBadgeFeedbackQuestionAdminMutation,
} from "generated/graphql";

interface UpdateBadgeFeedbackProps extends DialogProps {
  question: FeedbackQuestions | null | undefined;
  handleCloseModal: () => void;
}

const UpdateBadgeFeedback: FC<UpdateBadgeFeedbackProps> = ({
  question,
  handleCloseModal,
  ...rest
}) => {
  return (
    <CustomDialog onClose={handleCloseModal} {...rest}>
      <UpdateBadgeFeedbackForm
        handleCloseModal={handleCloseModal}
        question={question}
      />
    </CustomDialog>
  );
};

export default UpdateBadgeFeedback;

interface UpdateBadgeFeedbackFormProps {
  handleCloseModal: () => void;
  question: FeedbackQuestions | null | undefined;
}

export interface ValuesOfFeedbackQuestion {
  questionId: string | undefined;
  questionText: string;
}

const UpdateBadgeFeedbackForm: FC<UpdateBadgeFeedbackFormProps> = ({
  handleCloseModal,
  question,
}) => {
  const [updateFeedbackQuestion] =
    useUpdateBadgeFeedbackQuestionAdminMutation();

  const initialValues: ValuesOfFeedbackQuestion = {
    questionId: question?.questionId as string,
    questionText: question?.questionText as string,
  };

  const onSubmit = async (
    values: ValuesOfFeedbackQuestion,
    formikHelpers: FormikHelpers<ValuesOfFeedbackQuestion>
  ) => {
    if (values.questionText) {
      const { ...rest } = values;

      const variable: any = { ...rest };

      try {
        await updateFeedbackQuestion({
          variables: {
            ...variable,
          },
        });

        toast.success("Successfully Updated!");
        handleCloseModal();
      } catch (e) {
        console.error(e);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    }
  };

  const UpdateValidationSchema = Yup.object().shape({
    questionText: Yup.string().required("Questiontext is required"),
  });
  return (
    <Fragment>
      <DialogTitle className="modalTitle">
        <h3>Update Question</h3>
        <IconButton onClick={handleCloseModal}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={UpdateValidationSchema}
      >
        {({
          values,
          handleBlur,
          handleChange,
          touched,
          isSubmitting,
          errors,
          submitForm,
        }) => (
          <>
            <DialogContent>
              <Fragment>
                {isSubmitting && <Loading />}
                <TextField
                  label="Question Text"
                  fullWidth
                  variant="outlined"
                  value={values.questionText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="questionText"
                  aria-readonlydertfh
                  error={Boolean(touched?.questionText && errors?.questionText)}
                  helperText={
                    touched?.questionText ? errors?.questionText : null
                  }
                />
              </Fragment>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={submitForm}>
                Submit
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Fragment>
  );
};

const Loading = () => (
  <Absolute>
    <CircularProgress />
  </Absolute>
);

const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
`;

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 480px;
  }
  .MuiTextField-root {
    margin-top: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 12px 8px;
    .MuiButton-root {
      max-width: 200px;
      width: 100%;
    }
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
`;
