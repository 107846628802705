import {
    DialogTitle,
    IconButton,
    IconButtonProps,
    Typography,
} from "@mui/material"; import React, { FC } from "react";
import { Close } from "@mui/icons-material";


interface ICustomDialogTitle extends IconButtonProps {
    title?: string;
}

const CustomDialogTitle: FC<ICustomDialogTitle> = ({ title, ...others }) => (
    <DialogTitle>
        <Typography variant="h6">{title}</Typography>
        <IconButton
            style={{
                position: "absolute",
                right: 8,
                top: 8
            }}
            {...others}
        >
            <Close />
        </IconButton>
    </DialogTitle>
)

export default CustomDialogTitle;