import {
  CircularProgress,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Link,
} from "@mui/material";
import React, { Fragment } from "react";
import styled from "@emotion/styled";
import {
  NpsReviewerCategory,
  useGetCountOfNpsReviewsQuery,
  useGetNpsDataQuery,
  useGetNpsScoreQuery,
} from "generated/graphql";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash-es";
import NoDataFound from "components/NoDataFound";
import dayjs, { Dayjs } from "dayjs";
import DateFilter, { OnSubmit } from "./components/DateFilter";

const dateFormat = (date: Dayjs | string) => dayjs(date).format("YYYY-MM-DD");

const toDay = dayjs();
const sevenDayPrevious = dayjs(toDay).subtract(7, "days");

const LIMIT = 10;
const NpsSummary = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    startDate: dateFormat(sevenDayPrevious),
    endDate: dateFormat(toDay),
    filter: NpsReviewerCategory.All,
  });
  const startDate = searchParams.get("startDate")
    ? dayjs(searchParams.get("startDate"))
    : sevenDayPrevious;
  const endDate = searchParams.get("endDate")
    ? dayjs(searchParams.get("endDate"))
    : toDay;

  const page = (Number(searchParams.get("page")) || 1) as number;

  const { data, loading } = useGetNpsDataQuery({
    variables: {
      startDate: dateFormat(startDate),
      endDate: dateFormat(endDate),
      page,
      limit: LIMIT,
      filter: searchParams.get("filter") as any,
    },
  });

  const { data: NpsScore, loading: NpsScoreLoading } = useGetNpsScoreQuery({
    variables: {
      startDate: searchParams.get("startDate") as any,
      endDate: searchParams.get("endDate") as any,
    },
  });

  const pages = Math.ceil(Number(data?.getNpsData?.total) / LIMIT);

  const onPageChange = (_: any, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onFilter: OnSubmit = (values) => {
    searchParams.set("startDate", dateFormat(values.startDate));
    searchParams.set("endDate", dateFormat(values.endDate));
    searchParams.set("filter", values.filter);
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const npsReviewCounts = useGetCountOfNpsReviewsQuery({
    variables: {
      startDate: dateFormat(startDate),
      endDate: dateFormat(endDate),
    },
  });
  return (
    <Fragment>
      <Container>
        <DateFilter
          initialValues={{
            startDate,
            endDate,
            filter: searchParams.get("filter") as any,
          }}
          onSubmit={onFilter}
        />

        <Box
          sx={{
            ml: "20px",
            mb: "20px",
            "@media (max-width:600px)": { marginLeft: "0px" },
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{
              maxWidth: "500px",
              width: "100%",
            }}
          >
            <Box sx={{ paddingRight: "8px" }}>
              <Typography
                variant="caption"
                sx={{
                  "@media (max-width:600px)": { fontSize: "0.7rem" },
                }}
              >
                Total :&nbsp;
                {npsReviewCounts?.data?.getCountOfNpsReviews?.total || 0}
              </Typography>
            </Box>
            <Box sx={{ paddingRight: "8px" }}>
              <Typography
                variant="caption"
                sx={{
                  "@media (max-width:600px)": { fontSize: "0.7rem" },
                }}
              >
                Promoters :&nbsp;
                {npsReviewCounts?.data?.getCountOfNpsReviews?.promoters || 0}
              </Typography>
            </Box>
            <Box sx={{ paddingRight: "8px" }}>
              <Typography
                variant="caption"
                sx={{
                  "@media (max-width:600px)": { fontSize: "0.7rem" },
                }}
              >
                Detractors :&nbsp;
                {npsReviewCounts?.data?.getCountOfNpsReviews?.detractors || 0}
              </Typography>
            </Box>
            <Box sx={{ paddingRight: "8px" }}>
              <Typography
                variant="caption"
                sx={{
                  "@media (max-width:600px)": { fontSize: "0.7rem" },
                }}
              >
                Passive :&nbsp;
                {npsReviewCounts?.data?.getCountOfNpsReviews?.passive || 0}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Typography sx={{ float: "right", mr: "42px", mb: "30px" }}>
          NPS Score :{" "}
          {NpsScoreLoading ? (
            <CircularProgress size={20} />
          ) : (
            NpsScore?.getNpsScore?.toFixed(2)
          )}
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 425 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: "100px" }}>
                  <strong>User</strong>
                </TableCell>
                <TableCell sx={{ minWidth: "100px" }}>
                  <strong>Username</strong>
                </TableCell>
                <TableCell sx={{ minWidth: "100px" }}>
                  <strong>Email</strong>
                </TableCell>
                <TableCell sx={{ minWidth: "100px" }}>
                  <strong>Rating</strong>
                </TableCell>
                <TableCell sx={{ minWidth: "100px" }}>
                  <strong>Reason</strong>
                </TableCell>
                <TableCell sx={{ minWidth: "120px" }}>
                  <strong>Created At</strong>
                </TableCell>
                <TableCell align="center" sx={{ minWidth: "100px" }}>
                  <strong>Platform</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getNpsData?.npsOutput?.map((log) => (
                <TableRow key={log?.npsId}>
                  <TableCell>
                    {log?.user?.firstName + " " + log?.user?.lastName}
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`${process.env.STOCKEDGE_SOCIAL_URL}/profile/${log?.user?.username}`}
                      target="__blank"
                    >
                      @{log?.user?.username}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap variant="caption">
                      {log?.user?.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="14px"
                      color={
                        (log?.rating as any) <= 8
                          ? "#f5bf5d"
                          : (log?.rating as any) <= 5
                          ? "#ff5a5a"
                          : "#57cd57"
                      }
                    >
                      <strong>{log?.rating}</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>{log?.reason}</TableCell>
                  <TableCell>
                    {dayjs(log?.createdAt).format("DD-MMM-YYYY\nHH:mm:ss")}
                  </TableCell>
                  <TableCell align="center">{log?.platform}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            {isEmpty(data?.getNpsData?.npsOutput) && <NoDataFound />}
            {pages > 1 && (
              <Pagination
                count={pages}
                disabled={loading}
                page={page}
                onChange={onPageChange}
              />
            )}
          </Stack>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NpsSummary;

const Container = styled.div`
  padding: 16px;
`;
